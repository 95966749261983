import _ from "lodash";
import autoBind from "react-autobind";
import React, { Component } from 'react'
import CreatableSelect from 'react-select/lib/Creatable';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import './MultiSelectTextInput.css'

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});


export default class MultiSelectTextInput extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            inputValue: '',
            value: [],
            popoverOpen: false
        };

    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    componentDidMount() {
        this.setState({
            inputValue: '',
            value: [...this.props.defaultValue],
            popoverOpen: false
        });
    }

    handleChange = (value: any, actionMeta: any) => {
        // console.group('handleChange');
        // console.log(value);
        // console.log(`action: ${actionMeta.action}`);
        // console.groupEnd();
        this.setState({ value });
        if (actionMeta.action === 'remove-value') {
            this.onChange(value)
        }
    };

    handleInputChange = (inputValue: string) => {
        // console.group('handleInputChange');
        // console.log(inputValue);
        // console.groupEnd();
        this.setState({ inputValue });
    };

    handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                // console.group('Value Added');
                // console.log(value);
                // console.groupEnd();
                this.setState({
                    inputValue: '',
                    value: [...value, createOption(inputValue)],
                });
                this.onChange([...value, createOption(inputValue)]);
                event.preventDefault();
                break;
            default:
                return;
        }
    };

    onChange(selectedOption) {
        if (Array.isArray(selectedOption)) {
            const resValue = _.map(selectedOption, (el) => el.value);
            this.props.input.onChange(resValue)
        } else {
            if (this.props.input.onChange && selectedOption != null) {
                // To be aligned with how redux-form publishes its CHANGE action payload.
                // The event received is an object with 2 keys: "value" and "label"
                this.props.input.onChange(selectedOption.value);
            } else {
                // Clear the input field
                this.props.input.onChange(null);
            }
        }
    }

    render() {
        let { inputValue, value } = this.state;
        if (!value || value.length === 0) {
            value = this.props.defaultValue;
        }

        if (!this.props.tooltipTitle && !this.props.tooltipBody) {
            return this.renderSimple(inputValue, value)
        }
        return this.renderWithTooltip(inputValue, value)
    }

    renderSimple(inputValue, value) {
        return (
            <div className="font-roboto">
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.defaultValue}
                    value={value}
                />
                {this.props.meta.touched && (
                    (this.props.meta.error &&
                        <span className="badge badge-danger">{this.props.meta.error}</span>
                    ) ||
                    (this.props.meta.warning &&
                        <span className="badge badge-warning">{this.props.meta.warning}</span>
                    )
                )}
            </div>
        );
    }

    renderWithTooltip(inputValue, value) {
        const tooltipId = _.get(this.props, 'tooltipId', 'MultiSelectTooltipId');
        return (
            <div className="container w-100 input-container">
                <div className="font-roboto row w-100 input-container">
                    <div className="input-group form-date-input-group mb-3">
                        <CreatableSelect
                            className={"form-control"}
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={this.handleChange}
                            onInputChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={this.props.placeholder}
                            defaultValue={this.props.defaultValue}
                            value={value}
                        />
                        <div className="input-group-append form-date-input-append" id={tooltipId} onClick={this.toggle}>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </div>
                        <Popover placement="left" isOpen={this.state.popoverOpen} target={tooltipId} toggle={this.toggle}>
                            <PopoverHeader>{this.props.tooltipTitle}</PopoverHeader>
                            <PopoverBody>{this.props.tooltipBody}</PopoverBody>
                        </Popover>
                    </div>
                </div>

                <div className="font-roboto row w-100 input-container">
                    {this.props.meta.touched && (
                        (this.props.meta.error &&
                            <span className="badge badge-danger">{this.props.meta.error}</span>
                        ) ||
                        (this.props.meta.warning &&
                            <span className="badge badge-warning">{this.props.meta.warning}</span>
                        )
                    )}
                </div>
            </div>
        );
    }
}
