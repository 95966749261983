// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import * as commonTypes from './actionTypes';


export function DismissAllChecks() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
    };
}

export function ShowInfo(message) {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_SHOW_INFO_ALERT, data: message});
    };
}

export function HideInfo() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_HIDE_INFO_ALERT});
    };
}

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
    };
}
