// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import _ from 'lodash';
import * as commonTypes from '../common/actionTypes';
import * as types from './actionTypes';
import * as selectors from './reducer';
import BackendServiceAdminUsers from '../../services/serverAdminUsers';
import {sortUsers} from '../../globals/functions'

export function Select(id) {
    return (dispatch, getState) => {
        dispatch({ type: types.ADMIN_USER_SELECTED, currentElementId: id });
    };
}

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
        dispatch({ type: types.ADMIN_USER_DISMISS_ALL_ERRORS});
    };
}

export function EnterCreateMode() {
    return (dispatch, getState) => {
        dispatch({ type: types.ADMIN_USER_MODE_CREATE, modes: {edit: false, create: true} });
    };
}

export function EnterEditMode() {
    return (dispatch, getState) => {
        dispatch({ type: types.ADMIN_USER_MODE_EDIT, modes: {edit: true, create: false} });
    };
}

export function LeaveCreateEditMode() {
    // закрываем модалочку
    return (dispatch, getState) => {
        dispatch({ type: types.ADMIN_USER_MODE_EDIT, modes: {edit: false, create: false} });
    };
}

export function BeginLoading() {
    return (dispatch, getState) => {
        dispatch({ type: types.ADMIN_USER_BEGIN_LOADING });
    };
}

export function Get() {
    return async(dispatch, getState) => {
        try {
            const rawData = await BackendServiceAdminUsers.getList();
            // console.time('sortListFront');
            const [elementsIds, elements] = sortUsers(_.keyBy(rawData, (element) => element.ID));
            // console.timeEnd('sortListFront');
            dispatch({ type: types.ADMIN_USER_GET_DONE, result: {elements, elementsIds} });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.ADMIN_USER_GET_ERROR, error});
            console.error('Get users error:', error);
        }
    };
}

export function Create(values) {
    return async(dispatch, getState) => {
        try {
            const res = await BackendServiceAdminUsers.create(values);
            dispatch({ type: types.ADMIN_USER_CREATE_DONE, result: res });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.ADMIN_USER_CREATE_ERROR, error});
            console.error('Create users error: ', error);
        }
    };
}

export function Edit(values) {
    return async(dispatch, getState) => {
        try {
            const curId = selectors.getCurrentElementId(getState());
            const res = await BackendServiceAdminUsers.edit(curId, values);
            dispatch({ type: types.ADMIN_USER_EDIT_DONE, result: { [curId]: res } });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.ADMIN_USER_EDIT_ERROR, error});
            console.error('Edit users error: ', error);
        }
    };
}

export function Delete() {
    return async(dispatch, getState) => {
        try {
            const curId = selectors.getCurrentElementId(getState());
            await BackendServiceAdminUsers.delete(curId);
            dispatch({ type: types.ADMIN_USER_DELETE_DONE, result: curId });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.ADMIN_USER_DELETE_ERROR, error});
            console.error('Delete users error: ', error);
        }
    };
}

export function ChangePage(pageNumber) {
  return({ type: types.ADMIN_USER_PAGE_CHANGED, pageNumber: pageNumber });
}

export function Filter(filters, sorted) {
    return async(dispatch, getState) => {
        // пока делаем здесь локально... но может в будущем переложим это дело на сервер
        try {
            dispatch({type: types.ADMIN_USER_FILTER_CHANGED, filters: filters, sorted: sorted});
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.ADMIN_USER_GET_ERROR, error});
            console.error('Filter users error: ', error);
        }
    };
}
