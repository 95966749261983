// strings should be unique across reducers so namespace them with the reducer name
export const STREAMS_PAGE_CHANGED = 'streams.PAGE_CHANGED';
export const STREAMS_SELECTED = 'streams.SELECTED';

export const STREAMS_BEGIN_LOADING_ALL = 'streams.STREAMS_BEGIN_LOADING_ALL';
export const STREAMS_BEGIN_LOADING_CAL = 'streams.STREAMS_BEGIN_LOADING_CAL';
export const STREAMS_GET_DONE_ALL = 'streams.GET_DONE_ALL';
export const STREAMS_GET_DONE_CAL = 'streams.GET_DONE_CAL';
export const STREAMS_GET_ERROR = 'streams.GET_ERROR';

export const STREAMS_FILTER_CHANGED = 'streams.FILTER_CHANGED';
export const STREAMS_DISMISS_ALL_ERRORS = 'streams.DISMISS_ALL_ERRORS';

export const STREAMS_SSE_OPENED = 'streams.SSE_OPENED';
export const STREAMS_SSE_ERROR = 'streams.SSE_ERROR';
export const STREAMS_SSE_BAD_RECORDS_RECEIVED = 'streams.SSE_BAD_RECORDS_RECEIVED';
export const STREAMS_SSE_BAD_STREAMS_RECEIVED = 'streams.SSE_BAD_STREAMS_RECEIVED';