//https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js
export const formFieldValidateRequire = (value) => {
    if (typeof value === 'number') {
        return value.toString() ? undefined : 'Обязательное поле';
    }
    return value ? undefined : 'Обязательное поле';
};

export const formRangeFieldValidateRequire = (value) => {
    if (!value) {return 'Обязательное поле'}
    let result = {};
    for (let prop in value) {
        if (!value.hasOwnProperty(prop)) {
            continue
        }
        if (typeof value[prop] === 'number') {
            if (!value[prop].toString()) {
                result[prop] = 'Обязательное поле';
            }
        }
        if (!value[prop]) {
            result[prop] = 'Обязательное поле';
        }
    }
    return Object.keys(result).length ? result : undefined;
};

export const formFieldValidateNumber = (value) =>
    !isNaN(parseFloat(value)) && isFinite(value) ?
        undefined : 'Поле должно быть числом';

export const formFieldValidateEmptyOrPositiveNumber = (value) => {
    if (formFieldValidateRequire(value)) {
        // если поле пустое - это допустимо
        return undefined;
    }
    return !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0 ?
        undefined : 'Поле должно быть положительным числом';
};

export const formFieldValidateEmptyOrCorrectKpp = (value) => {
    if (value) {
        return formFieldValidateKpp(value);
    }
    return undefined;
};

export const formFieldValidatePositiveNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0 ?
        undefined : 'Поле должно быть положительным числом';
};

export const formFieldValidateNumberGeater1 = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 1 ?
        undefined : 'Поле должно быть >= 1';
};

export const formFieldValidateEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Некорректный email' : undefined;

export const formFieldValidateEmailList = (value) => {
    if (!value) { return undefined; }
    for (let i = 0; i < value.length; i++) {
        if (formFieldValidateEmail(value[i])) {
            return 'Некорректный email';
        }
    }
    return undefined;
};

export const formFieldValidateBikOptional = (bik) => {
    if (!bik) {
        return undefined;
    }
    return formFieldValidateBik(bik);
};

export const formFieldValidateBik = (bik) => {
    if (typeof bik === 'number') {
        bik = bik.toString();
    } else if (typeof bik !== 'string') {
        bik = '';
    }
    if (!bik.length) {
        return 'БИК пуст';
    } else if (/[^0-9]/.test(bik)) {
        return 'БИК может состоять только из цифр';
    } else if (bik.length !== 9) {
        return 'БИК может состоять только из 9 цифр';
    } else {
        return undefined;
    }
};

export const formFieldValidateInn = (inn) => {
    let result = false;
    if (/\s+/.test(inn)) {
        inn = inn.replace(/\s/g, '');
    }
    if (typeof inn === 'number') {
        inn = inn.toString();
    } else if (typeof inn !== 'string') {
        inn = '';
    }
    if (!inn.length) {
        return 'ИНН пуст';
    } else if (/[^0-9]/.test(inn)) {
        return 'ИНН может состоять только из цифр';
    } else if ([10, 12].indexOf(inn.length) === -1) {
        return 'ИНН может состоять только из 10 или 12 цифр';
    } else {
        let checkDigit = function (value, coefficients) {
            let n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * value[i];
            }
            return parseInt(n % 11 % 10, 10);
        };

        if (inn.length === 10) {
            let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(inn[9], 10)) {
                result = true;
            }
        } else {
            let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if ((n11 === parseInt(inn[10], 10)) && (n12 === parseInt(inn[11], 10))) {
                result = true;
            }
        }
        if (!result) {
            return 'Неправильное контрольное число';
        }
    }
    return undefined;
};

export const formFieldValidateKpp = (kpp) => {
    if (typeof kpp === 'number') {
        kpp = kpp.toString();
    } else if (typeof kpp !== 'string') {
        kpp = '';
    }
    if (kpp.length !== 9) {
        return 'КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)';
    } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
        return 'Неправильный формат КПП';
    } else {
        return undefined;
    }
};


export const formFieldValidateOgrn = (ogrn) => {
    if (!ogrn) {
        return undefined;
    }
    if (typeof ogrn === 'number') {
        ogrn = ogrn.toString();
    } else if (typeof ogrn !== 'string') {
        ogrn = '';
    }
    if (!ogrn.length) {
        return 'ОГРН пуст';
    } else if (/[^0-9]/.test(ogrn)) {
        return 'ОГРН может состоять только из цифр';
    } else if (ogrn.length !== 13) {
        return 'ОГРН может состоять только из 13 цифр';
    } else {
        let n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);
        if (n13 === parseInt(ogrn[12], 10)) {
            return undefined;
        } else {
            return 'Неправильное контрольное число';
        }
    }
};
