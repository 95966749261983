// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import _ from 'lodash';
import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import {filterPpe, sortPpe} from '../../globals/functions'

const initialState = Immutable({
    elements: undefined,
    elementsIds: undefined,
    elementFetchError: undefined,
    avsHubUpdateError: undefined,
    avsHubUpdateInProgress: false,
    avsHubUpdateDone: false,
    elementModifyError: undefined,
    elementDeleteError: undefined,
    currentPageNumber: 0,
    currentElementId: undefined,
    editMode: false,
    createMode: false,
    elementsLoading: true,
    elementsFilters: undefined,
    elementsSorted: undefined,
});

const dismissAllErrorsState = {
    elementFetchError: false,
    avsHubUpdateError: false,
    elementModifyError: false,
    elementDeleteError: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.PPE_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.PPE_GET_DONE:
            return state.merge({
                elementsLoading: false,
                elements: action.result.elements,
                elementsIds: action.result.elementsIds,
                elementFetchError: undefined,
                currentElement: undefined,
                currentElementId: undefined,
            });
        case types.PPE_BEGIN_LOADING:
            return state.merge({
                elementsLoading: true,
            });
        case types.PPE_GET_ERROR:
            return state.merge({
                elementsLoading: false,
                elements: {},
                elementsIds: [],
                elementFetchError: action.error
            });
        case types.PPE_BEGIN_UPDATE_FROM_HUB:
            return state.merge({
                avsHubUpdateError: false,
                avsHubUpdateInProgress: true,
                avsHubUpdateDone: false,
            });
        case types.PPE_UPDATE_FROM_HUB_DONE:
            return state.merge({
                avsHubUpdateError: false,
                avsHubUpdateInProgress: false,
                avsHubUpdateDone: true,
            });
        case types.PPE_UPDATE_FROM_HUB_ERROR:
            return state.merge({
                avsHubUpdateError: true,
                avsHubUpdateInProgress: false,
                avsHubUpdateDone: false,
            });
        case types.PPE_SELECTED:
            return state.merge({
                currentElementId: action.currentElementId
            });
        case types.PPE_MODE_CREATE:
        case types.PPE_MODE_EDIT:
            return state.merge({
                createMode: action.modes.create,
                editMode: action.modes.edit,
                elementModifyError: undefined,
            });
        case types.PPE_CREATE_ERROR:
        case types.PPE_EDIT_ERROR:
            return state.merge({
                elementModifyError: action.error
            });
        case types.PPE_CREATE_DONE:
            let createdActive = Immutable.asMutable(state.elements);
            createdActive[action.result.ID] = action.result;
            const [createdIdsActive, createdElementsActive] = sortPpe(createdActive);
            return state.merge({
                elementsIds: createdIdsActive,
                elements: createdElementsActive,
                createMode: false,
                editMode: false
            });
        case types.PPE_EDIT_DONE:
            let editedActive = Immutable.merge(state.elements, action.result);
            const [editedIdsActive, editedElementsActive] = sortPpe(editedActive);

            return state.merge({
                elementsIds: editedIdsActive,
                elements: editedElementsActive,
                createMode: false,
                editMode: false
            });
        case types.PPE_DELETE_DONE:
            const newIdsActive = Immutable.flatMap(state.elementsIds, (value) => {
                if (value === action.result) {
                    return []
                } else {
                    return value
                }
            });
            return state.merge({
                elements: Immutable.without(state.elements, action.result),
                elementsIds: newIdsActive,
                currentElementId: undefined,
                elementDeleteError: undefined,
                createMode: false,
                editMode: false
            });
        case types.PPE_DELETE_ERROR:
            return state.merge({
                elementDeleteError: action.error
            });
        case types.PPE_PAGE_CHANGED:
            return state.merge({
                currentPageNumber: action.pageNumber,
                currentElementId: undefined,
            });
        case types.PPE_FILTER_CHANGED:
            return state.merge({
                elementsFilters: action.filters ? action.filters : undefined,
                elementsSorted: action.sorted ? action.sorted : undefined,
                currentPageNumber: 0,
            });
        default:
            return state;
    }
}

// selectors

export function isElementsLoading(state) {
    return state.ppe.elementsLoading;
}

export function getElementsForTable(state, limit) {
    let idsToFilter = state.ppe.elementsIds;
    let elsToFilter = state.ppe.elements;
    if (!idsToFilter) {
        return [[], 0];
    }
    const from = state.ppe.currentPageNumber * limit;
    const to = from + limit;

    let filteredElementsIds = [];
    let filteredElements = {};
    if (state.ppe.elementsFilters && state.ppe.elementsFilters.length > 0) {
        for (let i = 0; i < idsToFilter.length; i++) {
            const id = idsToFilter[i];
            if (filterPpe(state.ppe.elementsFilters, elsToFilter[id])) {
                filteredElementsIds.push(id);
                filteredElements[id] = elsToFilter[id];
            }
        }
    } else {
        filteredElementsIds = idsToFilter;
        filteredElements = elsToFilter;
    }
    let outIds, outEl;
    if (state.ppe.elementsSorted && state.ppe.elementsSorted.length > 0) {
        let filterDirections = [];
        for (let i = 0; i < state.ppe.elementsSorted.length; i++) {
            filterDirections.push(state.ppe.elementsSorted[i].desc ? 'desc' : 'asc')
        }
        [outIds, outEl] = sortPpe(_.keyBy(filteredElements, (element) => element.ID), state.ppe.elementsSorted, filterDirections);
    } else {
        [outIds, outEl] = sortPpe(_.keyBy(filteredElements, (element) => element.ID));
    }

    const elementsIds = outIds.slice(from, to);
    let res = [];
    for (let i = 0; i < elementsIds.length; i++) {
        res.push(_.get(outEl, elementsIds[i]));
    }
    return [res, filteredElementsIds.length];
}

export function getCurrentElementId(state) {
    return state.ppe.currentElementId;
}

export function getCurrentElement(state) {
    return _.get(state.ppe.elements, state.ppe.currentElementId);
}

export function getCurrentPageNumber(state) {
    return state.ppe.currentPageNumber;
}

export function getFetchError(state) {
    return state.ppe.elementFetchError;
}

export function getEditError(state) {
    return state.ppe.elementModifyError;
}

export function getAvsHubUpdateInProgress(state) {
    return state.ppe.avsHubUpdateInProgress;
}

export function getAvsHubUpdateDone(state) {
    return state.ppe.avsHubUpdateDone;
}

export function getAvsHubUpdateError(state) {
    return state.ppe.avsHubUpdateError;
}

export function getDeleteError(state) {
    return state.ppe.elementDeleteError;
}

export function isEditMode(state) {
    return state.ppe.editMode || state.ppe.createMode;
}

export function isCreateMode(state) {
    return state.ppe.createMode;
}

export function getCurrentFilters(state) {
    return state.ppe.elementsFilters;
}