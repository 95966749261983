// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import * as commonTypes from '../common/actionTypes';
import * as types from './actionTypes';
import BackendServicesCalendar from '../../services/serverCalendar';
import {sortCalendar} from "../../globals/functions";
import _ from "lodash";
import moment from "moment";

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
        dispatch({ type: types.CALENDAR_DISMISS_ALL_ERRORS});
    };
}

export function BeginLoading() {
    return (dispatch, getState) => {
        dispatch({ type: types.CALENDAR_BEGIN_LOADING });
    };
}

export function BeginImport() {
    return (dispatch, getState) => {
        dispatch({ type: types.CALENDAR_IMPORT_BEGIN });
    };
}

export function Get(dtFrom, dtTo) {
    return async(dispatch, getState) => {
        try {
            const [rawData, counts] = await BackendServicesCalendar.getList(moment(dtFrom), moment(dtTo));
            const [elementsIds, elements] = sortCalendar(_.keyBy(rawData, (element) => element.ID));
            dispatch({type: types.CALENDAR_SET_PERIOD, result: {'dtFrom': moment(dtFrom), 'dtTo': moment(dtTo)}});
            dispatch({ type: types.CALENDAR_GET_DONE, result: {elementsIds, elements, counts} });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.CALENDAR_GET_ERROR, error});
            console.error('Get streams data error:', error);
        }
    };
}

export function Filter(filters, sorted) {
    return async(dispatch, getState) => {
        // пока делаем здесь локально... но может в будущем переложим это дело на сервер
        try {
            dispatch({type: types.CALENDAR_FILTER_CHANGED, filters: filters, sorted: sorted});
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.CALENDAR_GET_ERROR, error});
            console.error('Filter streams data error: ', error);
        }
    };
}

export function ImportExamDayAudiences(values) {
    return async(dispatch, getState) => {
        try {
            const res = await BackendServicesCalendar.importExamDayAudiences(values);
            dispatch({ type: types.CALENDAR_CLOSE_DATE_CHOOSER});
            dispatch({ type: types.CALENDAR_IMPORT_DONE, result: { res } });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.CALENDAR_IMPORT_ERROR, error});
            console.error('Calendar Import error: ', error);
        }
    };
}

export function CloseInfo() {
    return({type: types.CALENDAR_INFO_CLOSE})
}

export function ShowDateChooseForm() {
    return({ type: types.CALENDAR_OPEN_DATE_CHOOSER});
}

export function CloseDateChooseForm() {
    return({ type: types.CALENDAR_CLOSE_DATE_CHOOSER});
}

export function DismissAllSysActions() {
    return (dispatch, getState) => {
        dispatch({ type: types.CALENDAR_DISMISS_ALL_SYS_ACTIONS });
    };
}
