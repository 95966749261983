import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Route, BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';
import "hover.css"
import "typeface-roboto-multilang/cyrillic-ext.css";

// стандартные бутстраповские стили
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap'
// material design бутстраповские стили
import 'bootstrap-material-design/dist/css/bootstrap-material-design.css'

import reducer from './store/reducers';
import AuthScreen from "./containers/AuthScreen";
import StreamsScreen from "./containers/StreamsScreen";
import AdminUsersScreen from "./containers/AdminUsersScreen";
import CalendarScreen from "./containers/CalendarScreen";
import PpeScreen from "./containers/PpeScreen";
import MonitoringScreen from "./containers/MonitoringScreen";

// registerServiceWorker - не работает без https (по крайней мере в хроме) - выключаем
// import registerServiceWorker from './registerServiceWorker';

const store = createStore(reducer, applyMiddleware(thunk));

const routes =
    <App>
        <Route path='/' component={MonitoringScreen} exact={true} />
        <Route path='/monitoring/' component={StreamsScreen} exact={true} />
        <Route path='/auth/' component={AuthScreen} />
        <Route path='/admin/users/' component={AdminUsersScreen} />
        <Route path='/calendar/' component={CalendarScreen} />
        <Route path='/ppe/' component={PpeScreen} />
    </App>;

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>{routes}</BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// registerServiceWorker - не работает без https (по крайней мере в хроме) - выключаем
// registerServiceWorker();
