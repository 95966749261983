// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import _ from 'lodash';
import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import './AdminUsersScreen.css';
import * as actions from '../store/adminUsers/actions';
import * as selectors from '../store/adminUsers/reducer';
import EditView from "../components/EditView";
import {FormUserInitState} from "../globals/initialize";
import ToolBarView from "../components/ToolBarView";
import ReactTableView from "../components/ReactTableView";
import * as configs from "../globals/config";
import PagingView from "../components/PagingView";
import {faEdit, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {
    calcAvailability,
} from "../globals/functions";
import {formFieldValidateRequire} from "../globals/validators";
import {GeneralContainer, mapGeneralStateToProps} from "./GeneralContainer";

class AdminUsersScreen extends GeneralContainer {

    constructor(props) {
        super(props);
        autoBind(this);
        this.sectionName = configs.SECTIONS_NAMES.admin;
        this.filterTimer = 0;
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get());
    }

    renderEditView() {
        if (this.props.editMode) {
            return (
                <EditView
                    // class="modal-body-scrollable"
                    sectionName={this.sectionName}
                    titleEntity="пользователя"
                    initialValues={FormUserInitState(this.props.createMode, this.props.currentElement)}
                    show={this.props.editMode}
                    fields={this.getEditFields()}
                    createMode={this.props.createMode || !this.props.currentElement}
                    editError={this.props.elementModifyError}
                    dismissErrorAlert={this.dismissErrorAlert}
                    loadingData={false}
                    onCancel={this.onEditCancel}
                    onSubmit={this.onEditSave}
                    disableFieldsInCreateMode={false}
                    enabledFieldsInCreateMode={[]}
                />
            );
        }
    }

    render() {
        return (
            <div className="w-100 h-100 font-roboto">
                {this.renderEditView()}
                {this.renderNavigationBarView(100500, null)}
                <ToolBarView
                    groups={this.getToolbarGroups()}
                />
                <ReactTableView
                    columns={AdminUsersScreen.getTableColumns()}
                    defaultFilter={[]}
                    colForDetermineDisable={'OffTime'}
                    fetchError={this.props.elementsFetchError}
                    data={this.props.elementsForTable}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    loading={this.props.elementsLoading}
                    onFetchData={this.onReactTableFetch}
                    onRowClick={this.onTableRowClick}
                    onRowDoubleClick={this.onTableRowDoubleClick}
                    curProdId={this.props.currentElementId}
                    dismissErrorAlert={this.dismissErrorAlert}
                    onContextMenu = {this.onTableRowClick}
                    contextMenuItems={
                        [
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onAddElementClick,
                                disabled: (this.props.viewMode === configs.VIEW_MODES.ARCHIVE) ? true : !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.CREATE),
                                icon: faPlusCircle,
                                color: 'normal',
                                title: 'Добавить',
                            },
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onEditElementClick,
                                disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EDIT, this.props.currentElementId),
                                icon: faEdit,
                                color: 'normal',
                                title: 'Изменить',
                            },
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onDeleteElementClick,
                                disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.DELETE, this.props.currentElementId),
                                icon: faTrash,
                                color: 'danger',
                                title: 'Удалить',
                            },
                        ]
                    }
                />
                {this.props.elementDeleteError ?
                    <Alert color="danger" isOpen={true} toggle={this.dismissErrorAlert}>
                        Ошибка удаления: {this.props.elementDeleteError.message}!
                    </Alert> : ''
                }
                <div className="mb-2"/>
                <PagingView
                    totalCount={this.props.elementsTotalCount}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    curPageNumber={this.props.currentPageNumber}
                    onPageChange={this.onPageChange}
                    onSettingsClick={this.onSettingsClick}
                    hideSettings={true}
                />
            </div>
        );
    }

    onAddElementClick() {
        this.props.dispatch(actions.EnterCreateMode());
    }

    onEditElementClick() {
        this.props.dispatch(actions.EnterEditMode());
    }

    onPageChange(pageNumber) {
        this.props.dispatch(actions.ChangePage(pageNumber))
    }

    onEditCancel() {
        this.props.dispatch(actions.LeaveCreateEditMode());
    }

    onDeleteElementClick() {
        this.confirmAction('Вы действительно хотите удалить пользователя?', this.DeleteClick);
    }

    DeleteClick() {
        this.props.dispatch(actions.Delete());
    }

    onEditSave = (values) => {
        if (this.props.createMode) {
            this.props.dispatch(actions.Create(values));
        } else {
            this.props.dispatch(actions.Edit(values));
        }
    };

    onReactTableFetch(state, instance) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(()=>{
            this.props.dispatch(actions.Filter(state.filtered, state.sorted))
        }, configs.FILTER_TIMEOUT);
    }

    onTableRowClick(id) {
        this.props.dispatch(actions.Select(id));
    }

    onTableRowDoubleClick(id) {
        this.props.dispatch(actions.EnterEditMode());
    }

    dismissErrorAlert() {
        this.props.dispatch(actions.DismissErrorAlert())
    }

    getToolbarGroups() {
        return [
            {
                key: 1,
                className: 'col-12',
                elements: [
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: this.onAddElementClick,
                        text: 'Добавить',
                        icon: faPlusCircle,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.CREATE, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: this.onEditElementClick,
                        text: 'Изменить',
                        icon: faEdit,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EDIT, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'danger',
                        onClick: this.onDeleteElementClick,
                        text: 'Удалить',
                        icon: faTrash,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.DELETE, this.props.currentElementId),
                    },
                ]
            },
        ];
    }

    getEditFields() {
        return [{
            name: 'login',
            disabled: false,
            placeholder: 'Логин',
            type: configs.FORM_ELEMENT_TYPES.INPUT,
            inputType: configs.FORM_INPUT_TYPES.TEXT,
            validate: [
                formFieldValidateRequire,
            ],
            warn: [],
        }, {
            name: 'password',
            disabled: false,
            placeholder: 'Пароль',
            type: configs.FORM_ELEMENT_TYPES.INPUT,
            inputType: configs.FORM_INPUT_TYPES.TEXT,
            // inputType: configs.FORM_INPUT_TYPES.PASSWORD,
            validate: this.props.createMode ? [formFieldValidateRequire] : [],
            warn: [],
        }, {
            name: 'role',
            label: 'Роль',
            placeholder: '',
            noRowMargin: true,
            type: configs.FORM_ELEMENT_TYPES.RADIO_GROUP,
            items: _.values(configs.USER_ROLES),
            validate: [],
            warn: [],
        }, {
            name: 'name',
            placeholder: 'ФИО',
            type: configs.FORM_ELEMENT_TYPES.INPUT,
            inputType: configs.FORM_INPUT_TYPES.TEXT,
            validate: [
                formFieldValidateRequire,
            ],
            warn: [],
        }, {
            name: 'comment',
            placeholder: 'Комментарий',
            type: configs.FORM_ELEMENT_TYPES.INPUT,
            inputType: configs.FORM_INPUT_TYPES.TEXT,
            validate: [
                formFieldValidateRequire,
            ],
            warn: [],
        },
        ];
    }

    static getTableColumns() {
        return [{
            Header: 'Логин',
            accessor: 'Login',
            width: 150,
            style : {textAlign: 'left'},
        }, {
            Header: 'Роль',
            accessor: 'RoleForTable',
            width: 150,
            style : {textAlign: 'center'},
        },{
            Header: 'ФИО',
            accessor: 'Name',
            // width: 100,
            style : {textAlign: 'left'},
        }, {
            Header: 'Комментарий',
            accessor: 'Comment',
            // width: 150,
            style : {textAlign: 'left'},
        },
        ];
    }
}

// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    let [elementsForTable, elementsTotalCount] = selectors.getElementsForTable(state, GeneralContainer.getTableRowCountLimit(configs.SECTIONS_NAMES.admin));
    return {...mapGeneralStateToProps(state), ...{
            editMode: selectors.isEditMode(state),
            createMode: selectors.isCreateMode(state),
            currentElementId: selectors.getCurrentElementId(state),
            currentElement: selectors.getCurrentElement(state),
            elementsFetchError: selectors.getFetchError(state),
            elementModifyError: selectors.getEditError(state),
            elementsForTable: elementsForTable,
            elementsTotalCount: elementsTotalCount,
            elementsLoading: selectors.isElementsLoading(state),
            elementDeleteError: selectors.getDeleteError(state),
            currentPageNumber: selectors.getCurrentPageNumber(state),
        }
    };
}

export default connect(mapStateToProps)(AdminUsersScreen);
