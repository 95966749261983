// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import React, {Component} from 'react';
import autoBind from 'react-autobind';
import ReactTable, {ReactTableDefaults} from "react-table";

import "react-table/react-table.css";
import './ReactTableView.css';
import {Alert} from 'reactstrap';
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import './contextMenu.css'
import Clipboard from 'react-clipboard.js';
import {CONTEXT_MENU_EL_TYPES} from '../globals/config'

const columnDefaults = {...ReactTableDefaults.column, headerClassName: 'wordwrap'};

export default class ReactTableView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.useContextMenu = this.props.contextMenuItems && this.props.contextMenuItems.length > 0;
    }

    renderSubComponent = (row) => {
        if (!this.props.SubComponent) {
            return undefined;
        }
        return (
            <div style={{ padding: "20px" }}>
                <ReactTable
                    data={this.props.subData(row)}
                    columns={this.props.subColumns}
                    sortable={false}
                    filterable={false}
                    defaultPageSize={this.props.subPageSize(row)}
                    showPagination={false}
                    // getTrProps={this.getTrProps}
                    getTheadTrProps={(state, rowInfo) => (
                        {
                            style: {
                                background: '#0090e6',
                                color: 'white'
                            }
                        }
                    )}
                />
            </div>
        );
    };

    TrGroupComponentDef = ReactTableDefaults.TrGroupComponent;
    TrGroupComponentContextMenu = (_ref) => {
        return (
            <ContextMenuTrigger id="TableContextMenu">
                {ReactTableDefaults.TrGroupComponent(_ref)}
            </ContextMenuTrigger>
        );
    };

    ReactTrGroupComponent() {
        if (!this.useContextMenu) {
            return this.TrGroupComponentDef;
        }
        return this.TrGroupComponentContextMenu;
    };

    static renderContextMenuItem(item) {
        const renderBtn = () => {
            return (
                <MenuItem onClick={item.onClick} disabled={item.disabled} key={item.title}>
                    <button
                        className={`btn btn-link-${item.color} ${disabled} no-padding no-margin context-menu-button`}
                        disabled={item.disabled}
                    >
                        <FontAwesomeIcon className={item.disabled ? 'fa-disabled' : ''} icon={item.icon}/> {item.title}
                    </button>
                </MenuItem>
            );
        };
        const renderCopyBtn = () => {
            return (
                <MenuItem disabled={item.disabled} key={item.title}>
                    <Clipboard
                        option-text={item.getTextToCopy}
                        onSuccess={item.onCopySuccess}
                        className={`btn btn-link-${item.color} ${disabled} no-padding no-margin context-menu-button`}
                        disabled={item.disabled}
                    >
                        <div className="no-padding">
                            <FontAwesomeIcon className={item.disabled ? 'fa-disabled' : ''} icon={item.icon}/> {item.title}
                        </div>
                    </Clipboard>
                </MenuItem>
            );
        };
        const disabled = item.disabled ? 'disabled' : '';
        switch (item.type) {
            case CONTEXT_MENU_EL_TYPES.DIVIDER:
                return (<MenuItem key={'divider-'} divider/>);
            case CONTEXT_MENU_EL_TYPES.BUTTON:
                return renderBtn();
            case CONTEXT_MENU_EL_TYPES.COPY_BUTTON:
                if (item.disabled) {
                    return renderBtn();
                }
                return renderCopyBtn();
            default:
                return '';
        }
    }

    renderContextMenu() {
        if (!this.useContextMenu) {
            return '';
        }
        return (
            <ContextMenu id="TableContextMenu">
                {_.map(this.props.contextMenuItems, ReactTableView.renderContextMenuItem)}
            </ContextMenu>
        );
    }

    render() {
        if (this.props.fetchError) {
            return (
                <Alert color="danger" isOpen={true} toggle={this.props.dismissErrorAlert}>
                    Ошибка загрузки списка продуктов: {this.props.fetchError.message}!
                </Alert>
            );
        }
        let defSort = [];
        if ('defaultSort' in this.props) {
            defSort = this.props.defaultSort
        }
        const filterable = ('filterable' in this.props) ? this.props.filterable : true;
        let style = this.props.style ? this.props.style : {"height": "calc(100% - 170px)"};
        return (
            <div style={style} className="rt-table">
                <ReactTable
                    TrGroupComponent={this.ReactTrGroupComponent()}
                    data={this.props.data}
                    columns={this.props.columns}
                    column={columnDefaults}
                    noDataText={this.props.noDataText || "Нет данных для отображения!"}
                    loadingText="Загрузка..."
                    style={{'height': "100%"}}
                    loading={this.props.loading}
                    defaultPageSize={this.props.pageSize}
                    className="-striped -highlight"
                    showPagination={false}
                    sortable={'sortable' in this.props ? this.props.sortable : true}
                    filterable={filterable}
                    defaultFiltered={this.props.defaultFilter}
                    defaultSorted={defSort}
                    manual
                    onFetchData={this.props.onFetchData}
                    getTrProps={this.getTrProps}
                    getTheadTrProps={(state, rowInfo) => (
                        {
                            style: {
                                background: '#0090e6',
                                color: 'white'
                            }
                        }
                    )}
                    getTheadFilterThProps={(state, rowInfo) => (
                        {
                            style: {
                                // TODO: пока для всех разрешаем оверфлоу, если будут ошибки - сделать управляемым
                                overflow: 'visible',
                                // overflowX: 'hidden'
                            }
                        }
                    )}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onContextMenu: (e, handleOriginal) => {
                                if (!rowInfo) {
                                    this.props.onRowClick(undefined);
                                }
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            },
                            onClick: (e, handleOriginal) => {
                                if (!rowInfo) {
                                    this.props.onRowClick(undefined);
                                }
                                if (this.props.hideFilters) {
                                    if (typeof this.props.hideFilters === 'function') {
                                        this.props.hideFilters();
                                    }
                                }
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }}
                    SubComponent={ this.props.SubComponent ? (row) => (this.renderSubComponent(row)) : undefined }
                    expanded={ this.props.SubComponent ? this.props.subExpanded() : undefined}
                />

                { this.renderContextMenu() }
            </div>
        );
    }

    getTrProps(state, rowInfo) {
        if (!rowInfo) {
            return {}
        }
        let fontColor;
        if (this.props.colForDetermineDisable && rowInfo.original[this.props.colForDetermineDisable]) {
            fontColor = Number(rowInfo.original.ID) === Number(this.props.curProdId) ? '#ebebeb' : '#969ca1'
        } else {
            fontColor = Number(rowInfo.original.ID) === Number(this.props.curProdId) ? 'white' : 'black';
        }
        let background;
        if (Number(rowInfo.original.ID) === Number(this.props.curProdId)) {
            background = '#00aaff';
        } else {
            if ('Color' in rowInfo.original && rowInfo.original['Color']) {
                background = rowInfo.original['Color'];
            }
            else if ('FirstShipment' in rowInfo.original && rowInfo.original['FirstShipment'] && !rowInfo.original['Color']) {
                background = '#ffe0b2'
            } else {
                // background = Number(rowInfo.index) % 2 === 0 ? 'white' : '#F7F7F7'
                background = Number(rowInfo.index) % 2 === 0 ? 'white' : '#e8eff2'
            }
        }
        return {
            onClick: (e) => {
                this.props.onRowClick(rowInfo.original.ID);
            },
            onDoubleClick: (e) => {
                this.props.onRowDoubleClick(rowInfo.original.ID)
            },
            onContextMenu: (e) => {
                this.props.onRowClick(rowInfo.original.ID);
            },
            style: {
                // 'textAlign': 'center',
                fontSize: '11pt',
                background: background,
                color: fontColor
            }
        }
    }
}
