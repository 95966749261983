// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash'
import React, {Component} from 'react';
import autoBind from 'react-autobind';
import {reduxForm} from 'redux-form';
import {DATE_FILTER_ELEMENT_TYPES} from '../globals/config'
import './DateFilterView.css';
import DatePickerRangeInput from "./DatePickerRangeInput";
import DatePickerInput from "./DatePickerInput";

class DateFilterView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const classAdd = this.props.left ? 'left-position' : '';
        return (
            <div
                className={`dropdown-menu front-filter top-element show ${classAdd}`}
                aria-labelledby={this.props.dropDownId}
                // onClick={(e) => {
                    // e.stopPropagation();
                // }}
            >
                {_.map(this.props.elements, this.renderElement)}
            </div>
        );
    }

    renderElement(element) {
        switch (element.type) {
            case DATE_FILTER_ELEMENT_TYPES.BUTTON:
                return DateFilterView.renderBtnElement(element);
            case DATE_FILTER_ELEMENT_TYPES.DATE:
                return DateFilterView.renderDateElement(element);
            case DATE_FILTER_ELEMENT_TYPES.DATE_RANGE:
                return DateFilterView.renderDateRangeElement(element);
            default:
                return;
        }
    }

    static renderBtnElement(element) {
        const className = `btn dropdown-item dropdown-item-tiny font-roboto  btn-link-${element.color}`;
        return (
            <button
                key={element.text}
                type="button"
                className={className}
                onClick={element.onClick}
                disabled={element.disabled}
            >{element.text}</button>
        );
    }

    static renderDateElement(element) {
        return (
            <div key={element.text} className={"front-filter-element"}>
                <DatePickerInput
                    name={"precise-date"}
                    placeholder={"точная дата"}
                    showIcon={false}
                    onChange={(e) => {
                        const arr = [
                            e[0], e[1], e[2], e[3], e[4],
                            e[5], e[6], e[7], e[8], e[9]
                        ];
                        element.onChange(arr.join(''));
                    }}
                />
            </div>
        );
    }

    static renderDateRangeElement(element) {
        return (
            <div key={element.text} className={"front-filter-element"}>
                <DatePickerRangeInput
                    name={"date-range"}
                    placeholder={element.placeholder}
                    showIcon={false}
                    onChange={(e) => {
                        const from = e.start ? e.start.split(' ')[0] : undefined;
                        const to = e.end ? e.end.split(' ')[0] : from;
                        if (e.start && e.end) {
                            element.onChange(from, to);
                        }
                    }}
                />
            </div>
        );
    }
}


DateFilterView = reduxForm({
    form: 'toolbarForm',        // имя формы в state (state.form.toolbarForm)
})(DateFilterView);

export default DateFilterView;
