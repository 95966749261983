// strings should be unique across reducers so namespace them with the reducer name
export const CALENDAR_BEGIN_LOADING = 'calendar.BEGIN_LOADING';
export const CALENDAR_GET_DONE = 'calendar.GET_DONE';
export const CALENDAR_GET_ERROR = 'calendar.GET_ERROR';
export const CALENDAR_SET_PERIOD = 'calendar.SET_PERIOD';
export const CALENDAR_OPEN_DATE_CHOOSER = 'calendar.OPEN_DATE_CHOOSER';
export const CALENDAR_CLOSE_DATE_CHOOSER = 'calendar.CLOSE_DATE_CHOOSER';
export const CALENDAR_IMPORT_BEGIN = 'calendar.IMPORT_BEGIN';
export const CALENDAR_IMPORT_DONE = 'calendar.IMPORT_DONE';
export const CALENDAR_IMPORT_ERROR = 'calendar.IMPORT_ERROR';
export const CALENDAR_INFO_CLOSE = 'calendar.INFO_CLOSE';
export const CALENDAR_FILTER_CHANGED = 'calendar.FILTER_CHANGED';
export const CALENDAR_DISMISS_ALL_ERRORS = 'calendar.DISMISS_ALL_ERRORS';
export const CALENDAR_DISMISS_ALL_SYS_ACTIONS = 'calendar.DISMISS_ALL_SYS_ACTIONS';