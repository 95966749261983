// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash'
import React, {Component} from 'react';
import autoBind from 'react-autobind';
import {reduxForm} from 'redux-form';
import {NUMERIC_FILTER_ELEMENT_TYPES} from '../globals/config'
import './NumericFilterView.css';

class NumericFilterView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state={
            inputValue: '',
        };
    }

    componentDidMount() {
        this.setState({
            inputValue: '',
        });
    }

    handleInputChange = (inputValue: string) => {
        // console.group('handleInputChange');
        // console.log(inputValue);
        // console.groupEnd();
        this.setState({ inputValue });
    };

    handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>, element) => {
        const { inputValue } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
                element.onChange(inputValue);
                this.setState({
                    inputValue: '',
                });
                event.preventDefault();
                break;
            default:
                return;
        }
    };

    render() {
        const classAdd = this.props.left ? 'left-position' : '';
        return (
            <div
                className={`dropdown-menu front-filter top-element show ${classAdd}`}
                aria-labelledby={this.props.dropDownId}
            >
                {_.map(this.props.elements, this.renderElement)}
            </div>
        );
    }

    renderElement(element) {
        switch (element.type) {
            case NUMERIC_FILTER_ELEMENT_TYPES.BUTTON:
                return NumericFilterView.renderBtnElement(element);
            case NUMERIC_FILTER_ELEMENT_TYPES.INPUT:
                return this.renderNumericElement(element);
            case NUMERIC_FILTER_ELEMENT_TYPES.INPUT_RANGE:
                return NumericFilterView.renderNumericRangeElement(element);
            default:
                return;
        }
    }

    static renderBtnElement(element) {
        const className = `btn dropdown-item dropdown-item-tiny font-roboto  btn-link-${element.color}`;
        return (
            <button
                key={element.text}
                type="button"
                className={className}
                onClick={element.onClick}
                disabled={element.disabled}
            >{element.text}</button>
        );
    }

    renderNumericElement(element) {
        return (
            <div key={element.text} className={"front-filter-element"}>
                <h6 className="dropdown-header left-header">
                    {element.text}
                </h6>
                <input
                   name={"precise-date"}
                   placeholder={""}
                   type={"number"}
                   min={0}
                   // max={options.inputMax}
                   step={0.01}
                   className="form-control form-control-sm  font-roboto form-input"
                   onChange={(e)=>{
                       this.handleInputChange(e.target.value);
                   }}
                   onKeyDown={(event: SyntheticKeyboardEvent<HTMLElement>)=>{
                       this.handleKeyDown(event, element)
                   }}
                />
            </div>
        );
    }

    static renderNumericRangeElement(element) {
        //todo: реализация диапазона
        return (
            <div key={element.text} className={"front-filter-element"}>
                <h6 className="dropdown-header left-header">
                    {element.text}
                </h6>
                <div>
                </div>
            </div>
        );
    }
}


NumericFilterView = reduxForm({
    form: 'toolbarForm',        // имя формы в state (state.form.toolbarForm)
})(NumericFilterView);

export default NumericFilterView;
