// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import * as types from './actionTypes';
import BackendServiceCommon from '../../services/serverCommon';

export function clearAuth() {
    return (dispatch, getState) => {
        dispatch({ type: types.LOGIN_CLEAR});
    };
}

export function login(authData) {
    return async(dispatch, getState) => {
        try {
            await BackendServiceCommon.login(authData.login, authData.password);
            dispatch({ type: types.LOGIN_SUCCESS, result: true });
        } catch (error) {
            console.error('Login error', error);
            dispatch({ type: types.LOGIN_FAILED, result: false });
        }
    };
}

// export function logout() {
//     return async(dispatch, getState) => {
//         try {
//             await BackendServiceCommon.logout();
//             dispatch({ type: types.LOGOUT_SUCCESS, error: undefined });
//         } catch (error) {
//             console.error('Logout error:', error);
//             dispatch({ type: types.LOGIN_FAILED, error: error });
//         }
//     };
// }

export async function logout() {
    try {
        return await BackendServiceCommon.logout();
    } catch (error) {
        return false
    }
}

export function hasToken() {
    return BackendServiceCommon.hasToken()
}

export async function isAuthenticated() {
    try {
        return await BackendServiceCommon.checkAuth();
    } catch (error) {
        return false
    }
}
