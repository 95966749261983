// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash';
import React, {Component} from 'react';
import autoBind from 'react-autobind';
import {faCogs} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class PagingView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.dotsBefore = false;
        this.dotsAfter = false;
    }

    renderSettingsButton() {
        if (_.get(this.props, 'hideSettings', false)) {
            return '';
        }

        return (
            <button
                key='pagingCfgBtn'
                type="button"
                className="btn font-roboto  btn-link-normal hvr-underline-from-center-normal"
                onClick={this.props.onSettingsClick}
                disabled={false}
            ><FontAwesomeIcon icon={faCogs} /> настройки</button>
        );
    }

    renderOnlyTotal() {
        return (
            <nav aria-label="Навигация">
                <div className="row">
                    <div className="col-12 text-right main-text paging-summary">
                        всего: {this.props.totalCount}
                    </div>
                </div>
            </nav>
        );
    }

    render() {
        if (_.get(this.props, 'onlyTotal', false)) {
            return this.renderOnlyTotal();
        }

        const pageCount = Math.ceil(this.props.totalCount / this.props.pageSize);
        let pages = [];
        for (let i = 0; i < pageCount; i++) {
            pages.push(i)
        }
        const isFirst = (this.props.curPageNumber === 0);
        const isLast = (this.props.curPageNumber === (pageCount - 1));
        this.dotsBefore = false;
        this.dotsAfter = false;

        return (
            <nav aria-label="Навигация">
                <div className="row">
                    <div className="col-3">
                        {this.renderSettingsButton()}
                    </div>
                    <div className="col-6 justify-content-center">
                        <ul className="pagination pagination-sm justify-content-center">
                            {this.renderFirstBtn(isFirst)}
                            {_.map(pages, this.renderCurPageBtn)}
                            {this.renderLastBtn(isLast, pageCount)}
                        </ul>
                    </div>
                    <div className="col-3 text-right main-text paging-summary">
                        всего записей: {this.props.totalCount}
                    </div>
                </div>
            </nav>
        );
    }

    renderFirstBtn(isFirst) {
        if (isFirst) {
            return (
                <li className="page-item disabled">
                    <span className="page-link">&laquo;</span>
                </li>
            );
        } else {
            return (
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={() => { this.props.onPageChange(0) }}
                    >&laquo;</button>
                </li>
            );
        }
    }

    renderLastBtn(isLast, pageCount) {
        if (isLast) {
            return (
                <li className="page-item disabled">
                    <span className="page-link">&raquo;</span>
                </li>
            );
        } else {
            return (
                <li className="page-item">
                    <button
                        className="page-link"
                        onClick={() => { this.props.onPageChange(pageCount - 1) }}
                    >&raquo;</button>
                </li>
            );
        }
    }

    renderDotsBtn(index) {
        if (index < this.props.curPageNumber) {
            if (!this.dotsBefore) {
                const pageNum = Math.ceil(this.props.curPageNumber / 2);
                this.dotsBefore = true;
                return (
                    <li className="page-item" key={index}>
                        <button
                            className="page-link"
                            onClick={() => { this.props.onPageChange(pageNum) }}
                        >...</button>
                    </li>
                );
            }
        } else {
            if (!this.dotsAfter) {
                const pageCount = Math.ceil(this.props.totalCount / this.props.pageSize);
                const pageNum = Math.ceil((index + pageCount - 1) / 2);
                this.dotsAfter = true;
                return (
                    <li className="page-item" key={index}>
                        <button
                            className="page-link"
                            onClick={() => { this.props.onPageChange(pageNum) }}
                        >...</button>
                    </li>
                );
            }
        }
    }

    renderCurPageBtn(index) {
        const pageNum = index + 1;
        if (Math.abs(index - this.props.curPageNumber) > 2) {
            return this.renderDotsBtn(index);
        } else {
            if (index === this.props.curPageNumber) {
                return (
                    <li className="page-item active" key={index}>
                        <span className="page-link">{pageNum}</span>
                    </li>
                );
            } else {
                return (
                    <li className="page-item" key={index}>
                        <button
                            className="page-link"
                            onClick={() => { this.props.onPageChange(index) }}
                        >{pageNum}</button>
                    </li>
                );
            }
        }
    }
}
