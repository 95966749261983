// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import * as config from '../globals/config';
import BackendSession from '../globals/session';

export class BackendServiceCommon {
    // ********* Служебные
    constructor() {
        this.backEndPoint = config.DEBUG_BACK_ENDPOINT ? config.DEBUG_BACK_ENDPOINT : BackendServiceCommon.backendUrl();
        BackendSession.token = this.getToken();
        this.eventSource = undefined;
        BackendServiceCommon.loadUser();
    }

    hasToken() {
        return !!BackendSession.token;
    }

    getToken() {
        return localStorage.getItem('token');
    }

    setToken(token) {
        BackendSession.token = token;
        localStorage.setItem('token', token);
    }

    clearToken() {
        BackendSession.token = undefined;
        localStorage.removeItem("token");
    }

    static setUser(id, login, name, role, rolePrivileges) {
        BackendSession.userId = id;
        BackendSession.userLogin = login;
        BackendSession.userName = name;
        BackendSession.role = role;
        BackendSession.rolePrivileges = rolePrivileges;
        localStorage.setItem('userId', id);
        localStorage.setItem('userLogin', login);
        localStorage.setItem('userName', name);
        localStorage.setItem('role', role);
        localStorage.setItem('rolePrivileges', JSON.stringify(rolePrivileges));
    }

    static loadUser() {
        BackendSession.userId = localStorage.getItem('userId');
        BackendSession.userLogin = localStorage.getItem('userLogin');
        BackendSession.userName = localStorage.getItem('userName');
        BackendSession.role = localStorage.getItem('role');
        BackendSession.rolePrivileges = JSON.parse(localStorage.getItem('rolePrivileges'));
    }

    static clearUser() {
        BackendSession.userId = undefined;
        BackendSession.userLogin = undefined;
        BackendSession.userName = undefined;
        BackendSession.role = undefined;
        BackendSession.rolePrivileges = undefined;
        localStorage.removeItem('userId');
        localStorage.removeItem('userLogin');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        localStorage.removeItem('rolePrivileges');
    }

    static backendUrl() {
        //https://stackoverflow.com/questions/6941533/get-protocol-domain-and-port-from-url
        const url = window.location.href;
        const arr = url.split("/");
        return arr[0] + "//" + arr[2] + "/api/";
    }

    // ********* Аутентификация
    async checkAuth() {
        const url = `${this.backEndPoint}auth/authenticated?token=${BackendSession.token}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
            mode: 'cors'
        });
        if (!response.ok) {
            this.clearToken();
            BackendServiceCommon.clearUser();
            return false;
        }
        const res = await response.json();
        if (!res) {
            this.clearToken();
            BackendServiceCommon.clearUser();
            return false;
        }
        BackendServiceCommon.setUser(res.ID, res.Login, res.Name, res.Role, res.RolePrivilege);
        return true;
    }

    async login(login, password) {
        //async post request: https://stackoverflow.com/questions/29775797/fetch-post-json-data
        const url = `${this.backEndPoint}auth/login`;
        let formData = new FormData();
        formData.append('login', login);
        formData.append('password', password);
        const rawResponse = await fetch(url, {
            method: 'POST',
            body: formData,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            this.clearToken();
            BackendServiceCommon.clearUser();
            throw new Error(`Ошибка авторизации, HTTP status ${rawResponse.status}`);
        }
        const res = await rawResponse.json();
        this.setToken(res.Token);
        BackendServiceCommon.setUser(res.ID, res.Login, res.Name, res.Role, res.RolePrivilege);

        return true;
    }

    async logout() {
        const url = `${this.backEndPoint}auth/logout?token=${BackendSession.token}`;
        const rawResponse = await fetch(url, {
            method: 'POST',
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка завершения авторизованной сессии, HTTP status ${rawResponse.status}`);
        }
        this.clearToken();
        BackendServiceCommon.clearUser();
    }
}
export default new BackendServiceCommon();
