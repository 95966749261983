// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import _ from 'lodash';
import BackendSession from '../globals/session';
import {BackendServiceCommon} from "./serverCommon"
import * as configs from "../globals/config";

const SUB_PATH = 'streams';
const LIST_NAME = 'потоков';

class BackendServiceStreams extends BackendServiceCommon {

    closeSSE() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    initSSE(onOpen, onError, onBadRecords, onBadStreams) {
        // this.eventSource = new EventSource(`${this.backEndPoint}events`, {withCredentials: true});
        this.eventSource = new EventSource(`${this.backEndPoint}events`);
        this.eventSource.onopen = (e) => {
            // console.log("Соединение открыто");
            if (onOpen && typeof onOpen === 'function') {
                onOpen(e);
            }
        };

        this.eventSource.onerror = (e) => {
            if (this.eventSource.readyState === EventSource.CONNECTING) {
                console.log("Соединение порвалось, пересоединяемся...");
            } else {
                console.log("Ошибка, состояние: " + e.readyState);
            }
            if (onError && typeof onError === 'function') {
                onError(e);
            }
        };
        this.eventSource.addEventListener('bad-records', (event) => {
            // console.log("Пришли данные bad-records: " + event.data);
            try {
                if (onBadRecords && typeof onBadRecords === 'function') {
                    const data = _.map(JSON.parse(event.data), (generalData) => {
                        return BackendServiceStreams.getProblemWithRecordsObjectFromResponseData(generalData);
                    });
                    onBadRecords(data);
                }
            } catch (e) {
                console.warn(e.message);
            }
        });
        this.eventSource.addEventListener('bad-streams', (event) => {
            // console.log("Пришли данные bad-streams: " + event.data);
            try {
                if (onBadStreams && typeof onBadStreams === 'function') {
                    const data = _.map(JSON.parse(event.data), (generalData) => {
                        return BackendServiceStreams.getProblemWithRecordsObjectFromResponseData(generalData);
                    });
                    onBadStreams(data);
                }
            } catch (e) {
                console.warn(e.message);
            }
        });
        // this.eventSource.onmessage = (e) => {
        //     console.log("Пришли данные: " + e.data);
        // }
    }

    exportUrl(byCal, filters, fields) {
        let filtersParams = [];
        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                filtersParams.push({field_name: filters[i].id, field_value: filters[i].value})
            }
        }
        const filtersRequestParam = JSON.stringify(filtersParams);
        const fieldsRequestParam = JSON.stringify(fields);
        if (byCal) {
            return `${this.backEndPoint}export/${SUB_PATH}/calendar?filters=${filtersRequestParam}&fields=${fieldsRequestParam}&token=${BackendSession.token}`;
        }
        return `${this.backEndPoint}export/${SUB_PATH}?filters=${filtersRequestParam}&fields=${fieldsRequestParam}&token=${BackendSession.token}`;
    }

    static getProblemWithRecordsObjectFromResponseData(respData) {
        const ppeNumber = _.get(respData, 'ppe');
        const ppeVpn = _.get(respData, 'vpn');
        return {
            ID: _.get(respData, 'id'),
            Ppe: {number: ppeNumber, link: `http://${ppeVpn}/auth/?megaadmin=aki`},
            [`Ppe${configs.FILTER_DATE_FIELD_SUFFIX}`]: Number(ppeNumber),
            Vpn: _.get(respData, 'vpn'),
            Reason: _.get(respData, 'reason'),
        }
    }
    static getMainObjectFromResponseData(respData) {
        const ppeDistrict = _.get(respData, 'district');
        const ppeName = _.get(respData, 'name');
        const ppeNumber = _.get(respData, 'ppe');
        const ppeOnline = _.get(respData, 'online');
        const ppeSsh = _.get(respData, 'ssh');
        const ppeVpn = _.get(respData, 'vpn');
        const ppeIp = _.get(respData, 'ip');
        return {
            ID: _.get(respData, 'id'),
            Ppe: {number: ppeNumber, link: `http://${ppeVpn}/auth/?megaadmin=aki`},
            [`Ppe${configs.FILTER_DATE_FIELD_SUFFIX}`]: Number(ppeNumber),
            District: ppeDistrict,
            Name: ppeName,
            NameForTable: {district: ppeDistrict, name: ppeName},
            Online: ppeOnline,
            OnlineForTable: {online: ppeOnline, lastSeen: _.get(respData, 'last_seen_date_str')},
            AccessDetails: {vpn: ppeVpn, ip: ppeIp},
            Ssh: ppeSsh,
            AvsHasInternet: ppeOnline ? _.get(respData, 'avs_has_internet') : false,
            AvsPushPortsAvailable: ppeOnline ? _.get(respData, 'avs_push_ports_available') : false,
            SshForTable: ppeOnline && ppeSsh,
            Avs: {installed: _.get(respData, 'avs_installed'), version: _.get(respData, 'avs_version')},
            CamsTotalCount: _.get(respData, 'cams_total_count'),
            CamsBadStreams: _.get(respData, 'cams_bad_streams'),
            SysFreeSpace: _.get(respData, 'sys_free_space'),
        }
    }

    static getRTMonitoringObjectFromResponseData(respData) {
        const ppeDistrict = _.get(respData, 'district');
        const ppeName = _.get(respData, 'name');
        const ppeNumber = _.get(respData, 'ppe');
        const ppeOnline = _.get(respData, 'online');
        const AvsHasInternet = ppeOnline ? _.get(respData, 'avs_has_internet') : false;
        const AvsPushPortsAvailable = ppeOnline ? _.get(respData, 'avs_push_ports_available') : false;

        return {
            ID: _.get(respData, 'id'),
            Ppe: ppeNumber,
            [`Ppe${configs.FILTER_DATE_FIELD_SUFFIX}`]: Number(ppeNumber),
            District: ppeDistrict,
            Name: ppeName,
            Online: ppeOnline,
            CanRelay: AvsHasInternet && AvsPushPortsAvailable,
        }
    }

    async getList(calendar = false, searchQuery = undefined, forRtMonitoring=false) {
        // console.time('getListFromBack');
        const searchParam = searchQuery ? `&search=${searchQuery}` : '';
        const archiveParam = calendar ? '/calendar' : '';
        const url = `${this.backEndPoint}${SUB_PATH}${archiveParam}?token=${BackendSession.token}${searchParam}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
            mode: 'cors'
        });
        if (!response.ok) {
            throw new Error(`Ошибка получения списка ${LIST_NAME}, HTTP status ${response.status}`);
        }
        const data = await response.json();
        if (forRtMonitoring) {
            return _.map(data, (generalData) => {
                // в общем случае мы должны абстрагироваться от специфики структуры даннхы, которых мы получили,
                // здесь формируем объект, с которым нам потом будет удобно работать
                return BackendServiceStreams.getRTMonitoringObjectFromResponseData(generalData);
            });

        }
        return _.map(data, (generalData) => {
            // в общем случае мы должны абстрагироваться от специфики структуры даннхы, которых мы получили,
            // здесь формируем объект, с которым нам потом будет удобно работать
            return BackendServiceStreams.getMainObjectFromResponseData(generalData);
        });
    }
}

export default new BackendServiceStreams();
