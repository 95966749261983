import _ from "lodash";
import {Component} from "react";
import autoBind from "react-autobind";
import EditView from '../components/EditView'
import React from "react";
import * as configs from "../globals/config";
import * as commonSelectors from '../store/common/reducer';
import * as commonActions from '../store/common/actions';
import {
    formFieldValidateRequire
} from "../globals/validators";
import * as authActions from "../store/auth/actions";
import {formatCurrency, rootUrl} from "../globals/functions";
import DateFilterView from "../components/DateFilterView";
import NumericFilterView from "../components/NumericFilterView";
import moment from "moment";
import {EDIT_VIEW_MODE} from "../globals/config";
import NavigationBarView from "../components/NavigationBarView";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {confirmAlert} from 'react-confirm-alert'; // Import
import '../components/ConfirmAlertView.css'
import {Alert} from 'reactstrap';
// import connect from "react-redux/es/connect/connect";

const rowLimitKey = "tblRowsAmount";

export class GeneralContainer extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.tableSettingsShowView = false;
        this.tableSettingsTitle = "настроек таблицы";
        this.tableSettingsColumnLabels = [];
        this.tableSettingsColumnNames = [];
        this.allColumns = [];
        this.state = {};
    }

    hideAllFilters(immediately = false) {
        // console.log('hideAllFilters-init::', this.state);
        const reset = () => {
            // console.log('hideAllFilters-reset');
            for (let property in this.state) {
                if (this.state.hasOwnProperty(property)) {
                    this.setState({[property]: false});
                }
            }
        };
        if (immediately) {
            return reset();
        }
        setTimeout(reset, 333);
    }

    componentDidMount() {
        // проверим не протухла ли наша авторизация
        authActions.isAuthenticated().then(res => {
            if (!res) {
                this.props.history.push('/auth/');
            }
        });
    }

    renderDateFilterBtn(id, text) {
        let btnText = text;
        if (btnText !== configs.FILTER_PLACEHOLDER) {
            const btnTextParts = btnText.toString().split(' - ');
            if (btnTextParts.length === 1) {
                btnText = `${moment.unix(btnTextParts[0]).format(configs.DATE_FORMATS.DATE)}`;
            } else {
                const from = moment.unix(btnTextParts[0]).format(configs.DATE_FORMATS.DATE);
                const to = moment.unix(btnTextParts[1]).format(configs.DATE_FORMATS.DATE);
                if (from === to) {
                    btnText = from;
                } else {
                    btnText = `${from} - ${to}`;
                }
            }
        }
        return (
            <button
                id={id}
                className={`btn font-roboto  btn-link-normal filter-btn`}
                type="button"
                onClick={(e) => {
                    // e.stopPropagation();
                    // сохраним текущее значение
                    const curVisibility = this.state[id];
                    // сбросим все значения
                    this.hideAllFilters(true);
                    // а текущее - инвертируем
                    this.setState({[id]: !curVisibility})
                }}
            >
                {btnText}
            </button>
        );
    }

    renderDateFilterElement(id, onChangeCallback, filter, left = false) {
        if (!this.state[id]) {
            return;
        }
        return (
            <DateFilterView
                left={left}
                dropDownId={id}
                elements={[
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: () => {
                            // const now = moment().format(DATE_FORMATS.DATE);
                            const from = Math.round(moment().startOf('day').toDate().getTime() / 1000);
                            const to = Math.round(moment().endOf('day').toDate().getTime() / 1000) - 1;
                            onChangeCallback(`${from} - ${to}`);
                            this.hideAllFilters()
                        },
                        text: 'Сегодня',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: () => {
                            // const from = moment().startOf('month').format(DATE_FORMATS.DATE);
                            // const to = moment().endOf('month').format(DATE_FORMATS.DATE);
                            const from = Math.round(moment().startOf('month').toDate().getTime() / 1000);
                            const to = Math.round(moment().endOf('month').toDate().getTime() / 1000) - 1;
                            // const from = moment().startOf('month').unix();
                            // const to = moment().endOf('month').unix();
                            onChangeCallback(`${from} - ${to}`);
                            this.hideAllFilters()
                        },
                        text: 'Текущий месяц',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: () => {
                            // const from = moment().startOf('quarter').format(DATE_FORMATS.DATE);
                            // const to = moment().endOf('quarter').format(DATE_FORMATS.DATE);
                            const from = Math.round(moment().startOf('quarter').toDate().getTime() / 1000);
                            const to = Math.round(moment().endOf('quarter').toDate().getTime() / 1000) - 1;
                            onChangeCallback(`${from} - ${to}`);
                            this.hideAllFilters()
                        },
                        text: 'Текущий квартал',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: () => {
                            // const from = moment().startOf('year').format(DATE_FORMATS.DATE);
                            // const to = moment().endOf('year').format(DATE_FORMATS.DATE);
                            const from = Math.round(moment().startOf('year').toDate().getTime() / 1000);
                            const to = Math.round(moment().endOf('year').toDate().getTime() / 1000) - 1;
                            onChangeCallback(`${from} - ${to}`);
                            this.hideAllFilters()
                        },
                        text: 'Текущий год',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.DATE,
                        onChange: (value) => {
                            const from = Math.round(moment(`${value} 00:00:00`, configs.DATE_FORMATS.DATE_TIME).toDate().getTime() / 1000);
                            const to = Math.round(moment(`${value} 23:59:59`, configs.DATE_FORMATS.DATE_TIME).toDate().getTime() / 1000);
                            onChangeCallback(`${from} - ${to}`);
                            this.hideAllFilters()
                        },
                        text: 'Дата',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.DATE_RANGE,
                        onChange: (from, to) => {
                            const fromTS = Math.round(moment(`${from} 00:00:00`, configs.DATE_FORMATS.DATE_TIME).toDate().getTime() / 1000);
                            const toTS = Math.round(moment(`${to} 23:59:59`, configs.DATE_FORMATS.DATE_TIME).toDate().getTime() / 1000);
                            const value = `${fromTS} - ${toTS}`;
                            onChangeCallback(value);
                            this.hideAllFilters()
                        },
                        text: 'Интервал',
                    },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'danger',
                        onClick: () => {
                            onChangeCallback('');
                            this.hideAllFilters()
                        },
                        text: 'Очистить',
                    },
                ]}
            />
        );
    }

    renderNumericFilterBtn(id, text) {
        let btnText = text;
        if (btnText !== configs.FILTER_PLACEHOLDER) {
            const btnTextParts = btnText.toString().split(' - ');
            if (btnTextParts.length === 1) {
                // todo: форматировать?
                btnText = btnTextParts[0];
            } else {
                // todo: форматировать?
                const from = btnTextParts[0];
                const to = btnTextParts[1];
                if (from === to) {
                    btnText = from;
                } else {
                    btnText = `${from} - ${to}`;
                }
            }
        }
        return (
            <button
                id={id}
                className={`btn font-roboto  btn-link-normal filter-btn`}
                type="button"
                onClick={(e) => {
                    // сохраним текущее значение
                    const curVisibility = this.state[id];
                    // сбросим все значения
                    this.hideAllFilters(true);
                    // а текущее - инвертируем
                    this.setState({[id]: !curVisibility})
                }}
            >
                {btnText}
            </button>
        );
    }

    renderNumericFilterElement(id, onChangeCallback, filter, left = false) {
        if (!this.state[id]) {
            return;
        }
        return (
            <NumericFilterView
                left={left}
                dropDownId={id}
                elements={[
                    {
                        type: configs.NUMERIC_FILTER_ELEMENT_TYPES.INPUT,
                        onChange: (value) => {
                            onChangeCallback(value);
                            this.hideAllFilters()
                        },
                        text: 'Точное значение',
                    },
                    {
                        type: configs.NUMERIC_FILTER_ELEMENT_TYPES.INPUT,
                        onChange: (value) => {
                            onChangeCallback(`0 - ${value}`);
                            this.hideAllFilters()
                        },
                        text: 'Меньше или равно',
                    },
                    {
                        type: configs.NUMERIC_FILTER_ELEMENT_TYPES.INPUT,
                        onChange: (value) => {
                            // todo: корректное максимальное значение
                            onChangeCallback(`${value} - ${Number.MAX_SAFE_INTEGER}`);
                            this.hideAllFilters()
                        },
                        text: 'Больше или равно',
                    },
                    // {
                    //     type: configs.NUMERIC_FILTER_ELEMENT_TYPES.INPUT_RANGE,
                    //     onChange: (from, to) => {
                    //         console.log('INPUT', from, to);
                    //         // onChangeCallback(value);
                    //         this.hideAllFilters()
                    //     },
                    //     text: 'Интервал',
                    // },
                    {
                        type: configs.DATE_FILTER_ELEMENT_TYPES.BUTTON,
                        color: 'danger',
                        onClick: () => {
                            onChangeCallback('');
                            this.hideAllFilters()
                        },
                        text: 'Очистить',
                    },
                ]}
            />
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // // Typical usage (don't forget to compare props):
        // if (this.props.userID !== prevProps.userID) {
        //     this.fetchData(this.props.userID);
        // }
        if (!authActions.hasToken()) {
            this.props.history.push('/auth/');
            return;
        }
        if (this.props.needToCheckAuth) {
            // если надо проверить - проверим (как правило в случае ошибок ответа сервера)
            authActions.isAuthenticated().then(res => {
                if (!res) {
                    this.props.history.push('/auth/');
                } else {
                    // если все пучком - скинем флаг необходимости проверки
                    this.props.dispatch(commonActions.DismissAllChecks());
                }
            });
        }
    }

    confirmAction = (message, onSubmit, title='Внимание') => {
        confirmAlert({
            title: title,
            message: message,
            customUI: ({title, message, onClose}) => {
                return (
                    <div className='confirmDialog'>
                        <h1>{title}</h1>
                        <p>{message}</p>
                        <button className="confirmDialog-btn-cancel" onClick={onClose}>НЕТ</button>
                        <button className="confirmDialog-btn-ok" onClick={() => {
                            onSubmit();
                            onClose();
                        }}>ДА</button>
                    </div>
                )
            },
        })
    };

    initLabelsAndNames = () => {
        for (let i = 0; i < this.allColumns.length; i++) {
            this.tableSettingsColumnLabels.push(this.allColumns[i].Header);
            this.tableSettingsColumnNames.push(this.allColumns[i].accessor);
        }
    };

    tableSettings = () => {
        const key = `${this.sectionName}_tbl_settings`;
        let res = JSON.parse(localStorage.getItem(key));
        if (!res) {
            res = {}
        }
        for (let i = 0; i < this.tableSettingsColumnNames.length; i++) {
            if (this.tableSettingsColumnNames[i] in res) {
                continue
            }
            res[this.tableSettingsColumnNames[i]] = true;
        }
        if (!_.get(res, rowLimitKey, 0)) {
            res[rowLimitKey] = configs.TABLES_PAGE_SIZE;
        }
        return res;
    };

    static renderFilesCell(rowValue) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    // backgroundColor: '#dadada',
                    // borderRadius: '2px'
                }}
            >
                {(rowValue != null) ? _.map(rowValue, GeneralContainer.renderFile) : "Нет"}
            </div>
        );
    }

    static renderFile(file) {
        let img = undefined;
        return (
            <a key={file['Id']} href={rootUrl() + file['Url']} target="_blank" className={"filesContainer"} rel="noopener noreferrer">
                <img src={img} width="27" height="32" alt="" title={file['Name']}/>
            </a>
        );
    }

    static renderCurrencyCell(rowValue) {
        return (<div>{formatCurrency(rowValue)}</div>);
    }

    static renderCellWithPopupOnHover(rowValue) {
        return (
            <div>
                <span title={rowValue}>
                    {rowValue}
                </span>
            </div>
        )
    }

    static renderNumberCell(rowValue) {
        const formatter = new Intl.NumberFormat("ru-RU");
        return (<div>{formatter.format(rowValue)}</div>);
    }

    getFields() {
        let fields = [];
        for (let i = 0; i < this.getTableColumns().length; i++) {
            fields.push(this.getTableColumns()[i].accessor)
        }
        return fields
    }

    static getTableRowCountLimit = (sectionName) => {
        const key = `${sectionName}_tbl_settings`;
        let res = JSON.parse(localStorage.getItem(key));
        if (!res) {
            res = {}
        }
        return Number(_.get(res, rowLimitKey, configs.TABLES_PAGE_SIZE))
    };

    getTableColumns = () => {
        let res = [];
        const curSettings = this.tableSettings();
        for (let i = 0; i < this.allColumns.length; i++) {
            const accessor = this.allColumns[i].accessor;
            if (accessor in curSettings && !curSettings[accessor]) {
                continue
            }
            res.push(this.allColumns[i]);
        }
        return res;
    };

    tableSettingsFields = () => {
        let res = [
            {
                name: rowLimitKey,
                label: 'Количество отображаемых строк',
                placeholder: '',
                type: configs.FORM_ELEMENT_TYPES.INPUT,
                inputType: configs.FORM_INPUT_TYPES.NUMBER,
                inputMin: 0,
                validate: [
                    formFieldValidateRequire,
                ],
                warn: [],
            },
        ];
        for (let i = 0; i < this.tableSettingsColumnLabels.length; i += 3) {
            let fields = [
                {
                    label: this.tableSettingsColumnLabels[i],
                    name: this.tableSettingsColumnNames[i],
                    validate: [],
                    warn: [],
                    disabled: false
                },
            ];
            if (this.tableSettingsColumnLabels.length > i + 1) {
                fields.push({
                    label: this.tableSettingsColumnLabels[i + 1],
                    name: this.tableSettingsColumnNames[i + 1],
                    validate: [],
                    warn: [],
                    disabled: false
                })
            }
            if (this.tableSettingsColumnLabels.length > i + 2) {
                fields.push({
                    label: this.tableSettingsColumnLabels[i + 2],
                    name: this.tableSettingsColumnNames[i + 2],
                    validate: [],
                    warn: [],
                    disabled: false
                })
            }
            res.push({
                label: i === 0 ? "Видимость столбцов" : "",
                name: `tblSettingsColVisibilityGroup_${i}`,
                noRowMargin: true,
                type: configs.FORM_ELEMENT_TYPES.CHECKBOX_GROUP,
                fields: fields,
            })
        }
        return res
    };

    onSettingsClick = () => {
        this.tableSettingsShowView = true;
        this.forceUpdate();
    };

    onSettingsCancel = () => {
        this.tableSettingsShowView = false;
        this.forceUpdate();
    };

    onSettingsSave = (values) => {
        const key = `${this.sectionName}_tbl_settings`;
        localStorage.setItem(key, JSON.stringify(values));
        this.tableSettingsShowView = false;
        this.forceUpdate();
    };

    renderSettings = () => {
        if (!this.tableSettingsShowView) {
            return '';
        }
        return (
            <EditView
                // class="modal-body-scrollable"
                mode={EDIT_VIEW_MODE.Local}
                size="lg"
                sectionName={this.sectionName}
                titleEntity={this.tableSettingsTitle}
                initialValues={this.tableSettings()}
                show={true}
                fields={this.tableSettingsFields()}
                createMode={false}
                editError={this.props.elementModifyError}
                dismissErrorAlert={this.dismissErrorAlert}
                loadingData={false}
                click={this.onSettingsClick}
                onCancel={this.onSettingsCancel}
                onSubmit={this.onSettingsSave}
                disableFieldsInCreateMode={false}
                enabledFieldsInCreateMode={[]}
            />
        );
    };

    renderNavigationBarView = (index, subIndex=undefined) => {
        return (
            <NavigationBarView
                onLogout={()=>{this.onLogoutClick()}}
                onMailingClick={()=>{this.onMailingClick()}}
                activeIndex={index}
                subActivIndex={subIndex}
            />
        );
    };

    showInfoView = (message) => {
        this.props.dispatch(commonActions.ShowInfo(message));
    };

    hideInfoView = () => {
        this.props.dispatch(commonActions.HideInfo());
    };

    renderContextInfoView = () => {
        if (!this.props.infoMessage) {
            return
        }
        // всегда автоматом скрываемся
        setTimeout(this.hideInfoView, 2500);
        return (
            <Alert color="success" isOpen={true} toggle={this.hideInfoView}>
                {this.props.infoMessage}
            </Alert>
        );
    };

    dismissErrorAlert() {
        this.props.dispatch(commonActions.DismissErrorAlert())
    }

    onLogoutClick() {
        authActions.logout().then(res => {
            this.props.history.push('/auth/');
        });
    }
}

export function mapGeneralStateToProps(state) {
    return {
        needToCheckAuth: commonSelectors.getNeedAuthCheck(state),
        infoMessage: commonSelectors.getInfoMessage(state),
    }
}

// export default connect(mapGeneralStateToProps)(GeneralContainer);


