// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    needToCheckAuth: undefined,
    infoMessage: false,
});

const dismissAllChecksState = {
    needToCheckAuth: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.COMMON_NEED_TO_CHECK_AUTH:
            return state.merge({
                needToCheckAuth: true,
            });
        case types.COMMON_SHOW_INFO_ALERT:
            return state.merge({
                infoMessage: action.data,
            });
        case types.COMMON_HIDE_INFO_ALERT:
            return state.merge({
                infoMessage: undefined,
            });
        case types.COMMON_DISMISS_ALL_CHECKS:
            return state.merge(dismissAllChecksState);
        default:
            return state;
    }
}

// selectors

export function getNeedAuthCheck(state) {
    return state.common.needToCheckAuth;
}

export function getInfoMessage(state) {
    return state.common.infoMessage;
}

