// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import _ from 'lodash';
import * as commonTypes from '../common/actionTypes';
import * as types from './actionTypes';
import BackendServiceStreams from '../../services/serverStreams';
import {sortMonitoringRt} from '../../globals/functions'

export function Select(id) {
    return (dispatch, getState) => {
        dispatch({ type: types.MONITORING_RT_SELECTED, currentElementId: id });
    };
}

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
        dispatch({ type: types.MONITORING_RT_DISMISS_ALL_ERRORS});
    };
}

export function BeginLoading() {
    return (dispatch, getState) => {
        dispatch({ type: types.MONITORING_RT_BEGIN_LOADING });
    };
}

export function Get() {
    return async(dispatch, getState) => {
        try {
            const rawData = await BackendServiceStreams.getList(true, undefined, true);
            // console.time('sortListFront');
            const [elementsIds, elements] = sortMonitoringRt(_.keyBy(rawData, (element) => element.ID));
            // console.timeEnd('sortListFront');
            dispatch({ type: types.MONITORING_RT_GET_DONE, result: {elements, elementsIds} });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.MONITORING_RT_GET_ERROR, error});
            console.error('Get streams error:', error);
        }
    };
}

export function ChangePage(pageNumber) {
    return({ type: types.MONITORING_RT_PAGE_CHANGED, pageNumber: pageNumber });
}

export function Filter(filters, sorted) {
    return async(dispatch, getState) => {
        // пока делаем здесь локально... но может в будущем переложим это дело на сервер
        try {
            dispatch({type: types.MONITORING_RT_FILTER_CHANGED, filters, sorted});
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.MONITORING_RT_GET_ERROR, error});
            console.error('Filter streams error: ', error);
        }
    };
}