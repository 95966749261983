import React, { Component } from 'react'
import autoBind from "react-autobind";
import Dropzone from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faUpload} from "@fortawesome/free-solid-svg-icons";
import './DropzoneInput.css'

export default class DropzoneInput extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     files: []
        // };
        autoBind(this);
    }

    renderNewFiles() {
        const files = this.props.input.value;
        if (files && Array.isArray(files) && files.length) {
            return (
                <div className="w-100 font-roboto">
                    <h6 className="list-group-files-title">Новые файлы:</h6>
                    <ul className="list-group list-group-files-ul">
                        { files.map((file, i) =>
                            <li className="list-group-item list-group-files-li" key={i}>
                                {file.name}
                                <a
                                    href="/"
                                    className="font-roboto btn btn-link-danger list-group-files-a-del"
                                    onClick={ (e) => {
                                        e.preventDefault();
                                        this.props.input.value.splice(i, 1);
                                        this.forceUpdate();
                                    } }
                                ><FontAwesomeIcon icon={faTrashAlt} /></a>
                            </li>
                        )}
                    </ul>
                </div>
            );
        }
    }

    render() {
        // const files = this.state.files.map(file => (
        //     <li key={file.name}>
        //         {file.name} - {file.size} bytes
        //     </li>
        // ));

        return (
            <div className="w-100 font-roboto">
                <Dropzone
                    disabled={this.props.disabled}
                    disabledStyle={{color: '#999999'}}
                    name={this.props.name}
                    onDrop={( filesToUpload) => {
                        // this.setState({files: filesToUpload});
                        this.props.input.onChange(filesToUpload);
                    }}
                    style={{
                        position: "relative",
                        width: "100%",
                        // height: "100%",
                        height: "50px",
                        borderWidth: '2px',
                        borderColor: 'rgb(102, 102, 102)',
                        borderStyle: 'dashed',
                        borderRadius: '5px',
                    }}
                >
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className="font-roboto row">
                                    <div className="col-2 icon-container">
                                        <FontAwesomeIcon icon={faUpload} size="2x"/>
                                    </div>
                                    <div className="col-10">
                                        <p className="dropzone-placeholder">Перетащите сюда файл или кликните для выбора</p>
                                    </div>
                                </div>
                            </div>
                            {/*<aside>*/}
                            {/*    <h4>Files</h4>*/}
                            {/*    <ul>{files}</ul>*/}
                            {/*</aside>*/}
                        </section>
                    )}

                    {/*<div className="font-roboto row">*/}
                    {/*    <div className="col-2 icon-container">*/}
                    {/*        <FontAwesomeIcon icon={faUpload} size="2x"/>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-10">*/}
                    {/*        <p className="dropzone-placeholder">Перетащите сюда файлы или кликните для выбора файлов</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </Dropzone>
                {this.props.meta.touched && this.props.meta.error && <span className="badge badge-danger">{this.props.meta.error}</span>}
                {this.props.meta.touched && this.props.meta.warning && <span className="badge badge-warning">{this.props.meta.warning}</span>}
                {this.renderNewFiles()}
            </div>
        );
    }
}
