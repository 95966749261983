import _ from 'lodash'
import {DATE_FORMATS, SELECT_ELEMENT_LIMIT} from './config'
import * as configs from "./config";
import BackendSession from "./session";
import moment from 'moment'

function sortCollection(elements, sortFields, reversed=false) {
    const dtRegex = /^\d{2}.\d{2}.\d{4}$/;
    let sorted = _.sortBy(_.values(elements), sortFields.map(column => {
        return row => {
            let value = row[column];
            const tsKey = `${column}${configs.FILTER_DATE_FIELD_SUFFIX}`;
            if (tsKey in row) {
                const pVal = _.get(row, tsKey);
                return pVal != null ? pVal : 2947205206
            }
            if (Array.isArray(value)) {
                return value.length.toString()
            }
            if (typeof value === "object" || value === "undefined" ){
                return 0
            }
            if (typeof value === 'number') {
                return value
            }
            if (typeof value === 'string' && value.match(dtRegex)) {
                return moment(value, DATE_FORMATS.DATE_RUS);
            }

            return value != null ? value.toString().toLowerCase() : 'яяя'
        }
    }));
    if (reversed) {
        sorted = sorted.reverse();
    }
    return sorted;
}

function sortGeneral(elements, sortFields, reversed=false) {
    const sorted = sortCollection(elements, sortFields, reversed);
    const resIds = _.map(sorted, (product) => product.ID);
    const resById = _.keyBy(sorted, (product) => product.ID);
    return [resIds, resById]
}

function orderGeneral(elements, sortFields, sortFieldsDirection) {
    const dtRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    let sorted = _.orderBy(_.values(elements), sortFields.map(column => {
        return row => {
            let value = row[column.id];
            const tsKey = `${column.id}${configs.FILTER_DATE_FIELD_SUFFIX}`;
            if (tsKey in row) {
                // FILTER_DATE_FIELD_SUFFIX - могут быть даты, по крайней мере пока что - поэтому так
                const pVal = _.get(row, tsKey);
                // 2947205206 - 2063 год примерно
                if (pVal === null) {
                    // console.log('col:', column, ' -> value:', value, ' =>', column.desc ? 0 : 2947205206);
                    return column.desc ? 0 : 2947205206;
                }
                // console.log('col:', column, ' -> value:', value, ' =>', pVal);
                return pVal;
            }
            if (Array.isArray(value)) {
                // console.log('col:', column, ' -> value:', value, ' =>', value.length);
                return value.length.toString();
            }
            if (typeof value === 'number') {
                // console.log('col:', column, ' -> value:', value, ' =>', value);
                return value
            }
            if (typeof value === 'string' && value.match(dtRegex)) {
                // console.log('col:', column, ' -> value:', value, ' =>', moment(value, DATE_FORMATS.DATE_RUS));
                return moment(value, DATE_FORMATS.DATE_RUS);
            }
            if (value === null) {
                // console.log('col:', column, ' -> value:', value, ' =>', column.desc ? '000' : 'яяя');
                return column.desc ? '000' : 'яяя';
            }
            // console.log('col:', column, ' -> value:', value, ' =>', value.toString().toLowerCase());
            return value.toString().toLowerCase()
        }
    }), sortFieldsDirection);

    const resIds = _.map(sorted, (element) => element.ID);
    // const resById = _.keyBy(sorted, (product) => product.ID);
    // return [resIds, resById]
    return [resIds, elements]
}

function filterGeneral(element, filters, strFields, numFields, boolFields, rangeFields, rageOptions, dateFields, fieldsBeginStr, objectFields=undefined) {
    for (let fIndex = 0; fIndex < filters.length; fIndex++) {
        const fId = filters[fIndex].id;
        let fVal = filters[fIndex].value;
        if (strFields.includes(fId)) {
            fVal = fVal.toLowerCase();
            if (!filterByStr(fId, fVal, element)) {
                return false;
            }
        } else if (numFields.includes(fId)) {
            if (!filterByNum(fId, fVal, element)) {
                return false;
            }
        } else if (boolFields.includes(fId)) {
            if (!filterByBool(fId, fVal, element)) {
                return false;
            }
        } else if (rangeFields.includes(fId)) {
            const fIdFrom = _.get(rageOptions, fId).from;
            const fIdTo = _.get(rageOptions, fId).to;
            if (!filterByRange(fIdFrom, fIdTo, fVal, element)) {
                return false;
            }
        } else if (dateFields.includes(fId)) {
            if (!filterByDate(fId, fVal, element)) {
                return false;
            }
        } else if (fieldsBeginStr.includes(fId)) {
            if (!filterByBeginStr(fId, fVal, element)) {
                return false;
            }
        } else if (objectFields && Object.keys(objectFields).includes(fId)) {
            if (!filterByObject(fId, fVal, element, objectFields[fId])) {
                return false;
            }
        }
    }
    return true;
}

export function filterCollection(ids, items, filter) {
    if (!filter) {
        return [ids, items];
    }
    let filteredElementsIds = [];
    let filteredElements = {};
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        if (filter.value === _.get(items[id], filter.id, undefined)) {
            filteredElementsIds.push(id);
            filteredElements[id] = items[id];
        }
    }
    return [filteredElementsIds, filteredElements];
}

export function multiFilterCollection(ids, items, filters) {
    if (!filters || filters.length <= 0) {
        return [ids, items];
    }
    let filteredElementsIds = [];
    let filteredElements = {};
    for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        let add = true;
        for (let j = 0; j < filters.length; j++) {
            if (!filters[j]) {
                continue;
            }
            if (filters[j].value !== _.get(items[id], filters[j].id, undefined)) {
                // если любой из фильтров не подходит - значит всё, абзац, расходимся
                add = false;
                break;
            }
        }
        if (add) {
            filteredElementsIds.push(id);
            filteredElements[id] = items[id];
        }
    }
    return [filteredElementsIds, filteredElements];
}

export function sortMonitoringAstral(elements, filters=undefined, directions=undefined) {
    if (filters) {
        return orderGeneral(elements, filters, directions)
    }
    return sortGeneral(elements, ["Ppe"], false);
}

export function sortCalendar(elements, filters=undefined, directions=undefined) {
    if (filters) {
        return orderGeneral(elements, filters, directions)
    }
    return sortGeneral(elements, ["Ppe"], false);
}

export function sortMonitoringRt(elements, filters=undefined, directions=undefined) {
    if (filters) {
        return orderGeneral(elements, filters, directions)
    }
    return sortGeneral(elements, ["Ppe"], false);
}

export function filterMonitoringAstral(filters, elements) {
    const strFields = [];
    const objectFields = {
        'Avs': {type: configs.FILTER_TYPES.Str, accessors: ['version']},
        'Ppe': {type: configs.FILTER_TYPES.Str, accessors: ['number']},
        'NameForTable': {type: configs.FILTER_TYPES.Str, accessors: ['district', 'name']},
        'OnlineForTable': {type: configs.FILTER_TYPES.Bool, accessors: ['online']},
    };
    const boolFields = ['SshForTable', 'CamsBadStreams', 'AvsPushPortsAvailable', 'AvsHasInternet'];
    return filterGeneral(elements, filters, strFields, [], boolFields, [], {}, [], [], objectFields);
}

export function filterCalendar(filters, elements) {
    return filterGeneral(elements, filters, [], ['Ppe'], [], [], {}, [], [], {});
}

export function filterMonitoringRt(filters, elements) {
    const strFields = ['Ppe', 'District', 'Name', 'RoleName', 'role_name'];
    const boolFields = ['Online', 'CanRelay'];
    return filterGeneral(elements, filters, strFields, [], boolFields, [], {}, [], [], {});
}

export function sortUsers(users, filters=undefined, directions=undefined) {
    if (filters) {
        return orderGeneral(users, filters, directions)
    }
    return sortGeneral(users, ["CreationTime"], true);
}

export function sortExamAudience(elements) {
    return sortCollection(elements, ["startTs"], false);
}

export function filterUser(filters, user) {
    const strFields = ['Name', 'Login', 'EmailsForTable', 'RoleName', 'role_name'];
    const dateFields = ['CreationDate', 'OffTime'];
    return filterGeneral(user, filters, strFields, [], [], [], {}, dateFields, []);
}

export function sortPpe(elements, filters=undefined, directions=undefined) {
    if (filters) {
        return orderGeneral(elements, filters, directions)
    }
    return sortGeneral(elements, ['CustomerName'], false);
}

export function filterPpe(filters, debit) {
    const strFields = ['Ppe', 'District', 'Name', 'Address'];
    const fieldsBeginStr = [];
    const numFields = [];
    const dateFields = [];
    return filterGeneral(debit, filters, strFields, numFields, [], [], {}, dateFields, fieldsBeginStr);
}

export function getSelectOptionByValue(options, value, allowEmpty=false) {
    // возвращает объект для React-Select
    let res = options.filter(i =>
        i.value.toString() === value.toString()
    ).slice(0, 1)[0];
    if (allowEmpty) {
        if (!res) {
            return {value: '' ,label: ''};
        }
    }
    return {value: res.value.toString() ,label: res.label}
}

export function getSelectOptionByLabel(options, value) {
    // возвращает объект для React-Select
    let res = options.filter(i =>
        i.label.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 1)[0];
    if (!res) {
        return '';
    }
    return {value: res.value.toString() ,label: res.label}
}

export function getSelectOptionByCustomField(options, field, value) {
    // возвращает объект для React-Select
    let res = options.filter(i =>
        i[field].toString() === value.toString()
    ).slice(0, 1)[0];
    if (!res) {
        return '';
    }
    return res
}

export function getSelectOptions(options, filterValue=undefined) {
    // возвращаем всегда не больше 500 элементов
    let res = options.slice(0, SELECT_ELEMENT_LIMIT);
    if (filterValue) {
        res = options.filter(i =>
            i.label.toLowerCase().includes(filterValue.toLowerCase())
        ).slice(0, SELECT_ELEMENT_LIMIT);
    }
    return _.map(res, (generalData) => {
        return {
            value: generalData.value.toString(),
            label: generalData.label,
        }
    });
}

function filterByStr(fId, fVal, element) {
    const pVal = _.get(element, fId);
    if (!pVal) return false;
    return pVal.toLowerCase().includes(fVal.toLowerCase());
}
function filterByBeginStr(fId, fVal, element) {
    const pVal = _.get(element, fId);
    if (!pVal) return false;
    return pVal.toLowerCase().startsWith(fVal.toLowerCase());
}

function filterByBool(fId, fVal, element) {
    const pVal = Boolean(_.get(element, fId));
    if (fVal === 'да') {
        return pVal === true;
    }
    return pVal === false;
}

function filterByObject(fId, fVal, element, filterDetails) {
    const pVal = _.get(element, fId);
    if (!pVal) return false;
    let filterFnc = undefined;
    if (filterDetails.type === configs.FILTER_TYPES.Str) {
        filterFnc = filterByStr;
    } else if (filterDetails.type === configs.FILTER_TYPES.Bool) {
        filterFnc = filterByBool;
    }

    for (let i = 0; i < filterDetails.accessors.length; i++) {
        const val = _.get(pVal, filterDetails.accessors[i], undefined);
        if (val === undefined) continue;
        if (filterFnc && filterFnc('id1', fVal, {'id1': val})) {
            return true;
        }
    }
    return false;
}

function filterByDate(fId, fVal, element) {
    if (!fVal) {
        return true;
    }
    const pVal = _.get(element, `${fId}${configs.FILTER_DATE_FIELD_SUFFIX}`);
    if (!pVal) return false;
    let dates = fVal;
    if (typeof fVal === 'string') {
        dates = fVal.split(' - ');
    }
    if (!Array.isArray(dates)) {
        if (!Array.isArray(pVal)) {
            return pVal === dates;
        }
        for (let i = 0; i < pVal.length; i++) {
            if (pVal[i] === dates) {
                return true;
            }
        }
    } else {
        if (!Array.isArray(pVal)) {
            return (pVal >= dates[0] && pVal <= dates[1]);
        }
        for (let i = 0; i < pVal.length; i++) {
            if (pVal[i] >= dates[0] && pVal[i] <= dates[1]) {
                return true;
            }
        }
    }
    return false
}

function filterByNum(fId, fVal, element) {
    if (fVal) {
        fVal = fVal.replace(',', '.');
    }
    const pVal = Number(_.get(element, fId));
    let nums = fVal;
    if (typeof fVal === 'string') {
        nums = fVal.split(' - ');
    }
    if (nums.length === 1) {
        if (!isNumber(nums[0])) {
            return true;
        }
        return pVal === Number(nums[0]);
    } else {
        if (!isNumber(nums[0]) || !isNumber(nums[1])) {
            return true;
        }
        return (pVal >= Number(nums[0]) && pVal <= Number(nums[1]));
    }
}

function filterByRange(fIdFrom, fIdTo, fVal, element) {
    if (!isNumber(fVal)) {
        return true;
    }
    const pValFrom = Number(_.get(element, fIdFrom));
    const pValTo = Number(_.get(element, fIdTo));

    return (pValFrom <= Number(fVal) && pValTo >= Number(fVal));
}

export function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function calcAvailabilityLocal(action, curElId=undefined) {
    switch (action) {
        case configs.TOOLBAR_ACTIONS.COPY_TO_CLIPBOARD:
            return curElId !== undefined;
        default:
            // в любой непонятной ситуации возвращаем false
            return false;
    }
}

export function calcAvailability (sectionName, action, curElId=undefined) {
    switch (action) {
        case configs.TOOLBAR_ACTIONS.READ:
            return BackendSession.authorizeRead(sectionName);
        case configs.TOOLBAR_ACTIONS.CREATE:
        case configs.TOOLBAR_ACTIONS.IMPORT:
            return BackendSession.authorizeWrite(sectionName);
        case configs.TOOLBAR_ACTIONS.EDIT:
        case configs.TOOLBAR_ACTIONS.DELETE:
            return BackendSession.authorizeWrite(sectionName) && curElId !== undefined;
        case configs.TOOLBAR_ACTIONS.EXPORT:
            return BackendSession.authorizeExport(sectionName);
        default:
            // в любой непонятной ситуации возвращаем false
            return false;
    }
}

export function calcAvailabilityByRoles(roles) {
    return roles.includes(BackendSession.userRole);
}

export function rootUrl() {
    //https://stackoverflow.com/questions/6941533/get-protocol-domain-and-port-from-url
    if (configs.BACKEND_ROOT !== '') {
        return configs.BACKEND_ROOT;
    }
    const url = window.location.href;
    const arr = url.split("/");
    return arr[0] + "//" + arr[2] + '/';
}

export function formatCurrency(value) {
    const format = {
        style: 'currency',
        currency: 'RUB'
    };
    const formatter = new Intl.NumberFormat('ru-RU', format);
    return formatter.format(value)
}

export function asyncExecByTrigger(triggerFnc, targetFnc) {
    let done = false;
    const timerId = setInterval(() => {
        if (triggerFnc()) {
            done = true;
            clearInterval(timerId);
            targetFnc();
        }
    }, 200);

    // через 5 сек остановить повторы ибо нефиг
    setTimeout(() => {
        if (!done) {
            console.warn('asyncExecByTrigger - timeout')
        }
        clearInterval(timerId);
    }, 5000);
}

function checkListsEqual(first, second, idField, valField) {
    if (!first && !second) {
        return true;
    }
    if (!first && second) {
        return false;
    }
    if (first && !second) {
        return false;
    }
    if (first.length !== second.length) {
        return false;
    }

    for (let i = 0; i < first.length; i++) {
        if (first[i][idField] !== second[i][idField] || first[i][valField] !== second[i][valField]) {
            return false;
        }
    }
    return true;
}
export function checkFiltersEqual(first, second) {
    return checkListsEqual(first, second, 'id', 'value');
}

export function checkSortsEqual(first, second) {
    return checkListsEqual(first, second, 'id', 'desc');
}

export function humanFileSize(bytes, si) {
    const thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}
