// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import _ from 'lodash';
import * as commonTypes from '../common/actionTypes';
import * as types from './actionTypes';
import BackendServiceStreams from '../../services/serverStreams';
import {sortMonitoringAstral} from '../../globals/functions'

export function Select(id) {
    return (dispatch, getState) => {
        dispatch({ type: types.STREAMS_SELECTED, currentElementId: id });
    };
}

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
        dispatch({ type: types.STREAMS_DISMISS_ALL_ERRORS});
    };
}

export function BeginLoadingAll() {
    return (dispatch, getState) => {
        dispatch({ type: types.STREAMS_BEGIN_LOADING_ALL });
    };
}

export function BeginLoadingCalendar() {
    return (dispatch, getState) => {
        dispatch({ type: types.STREAMS_BEGIN_LOADING_CAL });
    };
}

export function Get(calendar=false) {
    return async(dispatch, getState) => {
        try {
            const rawData = await BackendServiceStreams.getList(calendar);
            // console.time('sortListFront');
            const [elementsIds, elements] = sortMonitoringAstral(_.keyBy(rawData, (element) => element.ID));
            // console.timeEnd('sortListFront');
            const type = calendar ? types.STREAMS_GET_DONE_CAL : types.STREAMS_GET_DONE_ALL;
            dispatch({ type: type, result: {elements, elementsIds} });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.STREAMS_GET_ERROR, error});
            console.error('Get streams error:', error);
        }
    };
}

export function ChangePage(pageNumber) {
    return({ type: types.STREAMS_PAGE_CHANGED, pageNumber: pageNumber });
}

export function Filter(filters, sorted) {
    return async(dispatch, getState) => {
        // пока делаем здесь локально... но может в будущем переложим это дело на сервер
        try {
            dispatch({type: types.STREAMS_FILTER_CHANGED, filters, sorted});
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.STREAMS_GET_ERROR, error});
            console.error('Filter streams error: ', error);
        }
    };
}

export function exportUrl(byCal=false, filters, filterByCustomerId=undefined, filterByManagerId=undefined, fields=undefined) {
    return BackendServiceStreams.exportUrl(byCal, filters, filterByCustomerId, filterByManagerId, fields)
}

export function CloseSse() {
    BackendServiceStreams.closeSSE()
}

export function StartSse() {
    return (dispatch, getState) => {
        BackendServiceStreams.initSSE(
            (e) => {
                dispatch({ type: types.STREAMS_SSE_OPENED });
            },
            (e) => {
                dispatch({ type: types.STREAMS_SSE_ERROR });
            },
            (rawData) => {
                const [elementsIds, elements] = sortMonitoringAstral(_.keyBy(rawData, (element) => element.ID));
                dispatch({ type: types.STREAMS_SSE_BAD_RECORDS_RECEIVED, result: {elements, elementsIds} });
            },
            (rawData) => {
                const [elementsIds, elements] = sortMonitoringAstral(_.keyBy(rawData, (element) => element.ID));
                dispatch({ type: types.STREAMS_SSE_BAD_STREAMS_RECEIVED, result: {elements, elementsIds} });
            }
        );
    };
}