// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import React, {Component} from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import './AuthScreen.css';
import * as authActions from '../store/auth/actions';
import * as authSelectors from '../store/auth/reducer';
import AuthForm from '../components/AuthForm';

class AuthScreen extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.logged === true) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        authActions.isAuthenticated().then(res => {
            if (res) {
                this.props.history.push('/');
            }
        });
    }

    render() {
        return (
            <div className="d-flex h-100 justify-content-center align-items-center">
                <AuthForm
                    onSubmit={this.handleSubmit}
                    loginError={this.props.loginError}
                />
            </div>
        );
    }

    handleSubmit = (values) => {
        this.props.dispatch(authActions.clearAuth());
        this.props.dispatch(authActions.login(values));
    };
}

// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    return {
        logged: authSelectors.isLoggedIn(state),
        loginError: authSelectors.loginError(state),
    };
}

export default connect(mapStateToProps)(AuthScreen);
