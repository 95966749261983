// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import _ from 'lodash';
import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import {filterUser, sortUsers} from '../../globals/functions'

const initialState = Immutable({
    elements: undefined,
    elementsIds: undefined,
    elementFetchError: undefined,
    elementModifyError: undefined,
    elementDeleteError: undefined,
    currentPageNumber: 0,
    currentElementId: undefined,
    editMode: false,
    createMode: false,
    elementsLoading: true,
    elementsFilters: undefined,
    elementsSorted: undefined,
});

const dismissAllErrorsState = {
    elementFetchError: false,
    elementModifyError: false,
    elementDeleteError: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.ADMIN_USER_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.ADMIN_USER_BEGIN_LOADING:
            return state.merge({
                elementsLoading: true,
            });
        case types.ADMIN_USER_GET_DONE:
            return state.merge({
                elementsLoading: false,
                elements: action.result.elements,
                elementsIds: action.result.elementsIds,
                elementFetchError: undefined,
            });
        case types.ADMIN_USER_GET_ERROR:
            return state.merge({
                elementsLoading: false,
                elements: {},
                elementsIds: [],
                elementFetchError: action.error
            });
        case types.ADMIN_USER_SELECTED:
            return state.merge({
                currentElementId: action.currentElementId
            });
        case types.ADMIN_USER_MODE_CREATE:
        case types.ADMIN_USER_MODE_EDIT:
            return state.merge({
                createMode: action.modes.create,
                editMode: action.modes.edit,
                elementModifyError: undefined,
            });
        case types.ADMIN_USER_CREATE_ERROR:
        case types.ADMIN_USER_EDIT_ERROR:
            return state.merge({
                elementModifyError: action.error
            });
        case types.ADMIN_USER_CREATE_DONE:
            let created = Immutable.asMutable(state.elements);
            created[action.result.ID] = action.result;
            const [createdIds, createdElements] = sortUsers(created);
            return state.merge({
                elementsIds: createdIds,
                elements: createdElements,
                createMode: false,
                editMode: false
            });
        case types.ADMIN_USER_EDIT_DONE:
            let edited = Immutable.merge(state.elements, action.result);
            const [editedIds, editedElements] = sortUsers(edited);
            return state.merge({
                elementsIds: editedIds,
                elements: editedElements,
                createMode: false,
                editMode: false
            });
        case types.ADMIN_USER_DELETE_DONE:
            const newIds = Immutable.flatMap(state.elementsIds, (value) => {
                if (value === action.result) {
                    return []
                } else {
                    return value
                }
            });
            return state.merge({
                elements: Immutable.without(state.elements, action.result),
                elementsIds: newIds,
                currentElementId: undefined,
                elementDeleteError: undefined,
                createMode: false,
                editMode: false
            });
        case types.ADMIN_USER_DELETE_ERROR:
            return state.merge({
                elementDeleteError: action.error
            });
        case types.ADMIN_USER_PAGE_CHANGED:
            return state.merge({
                currentPageNumber: action.pageNumber,
                currentElementId: undefined,
            });
        case types.ADMIN_USER_FILTER_CHANGED:
            return state.merge({
                elementsFilters: action.filters ? action.filters : undefined,
                elementsSorted: action.sorted ? action.sorted : undefined,
                currentPageNumber: 0,
            });
        default:
            return state;
    }
}

// selectors

export function isElementsLoading(state) {
    return state.adminUsers.elementsLoading;
}

export function getElementsForTable(state, limit) {
    let srcElIds = state.adminUsers.elementsIds;
    let srcEls = state.adminUsers.elements;
    if (!srcElIds) {
        return [[], 0];
    }
    const from = state.adminUsers.currentPageNumber * limit;
    const to = from + limit;

    let filteredElementsIds = [];
    let filteredElements = {};
    if (state.adminUsers.elementsFilters && state.adminUsers.elementsFilters.length > 0) {
        for (let i = 0; i < srcElIds.length; i++) {
            const id = srcElIds[i];
            if (filterUser(state.adminUsers.elementsFilters, srcEls[id])) {
                filteredElementsIds.push(id);
                filteredElements[id] = srcEls[id];
            }
        }
    } else {
        filteredElementsIds = srcElIds;
        filteredElements = srcEls;
    }
    let outIds, outEl;
    if (state.adminUsers.elementsSorted && state.adminUsers.elementsSorted.length > 0) {
        let filterDirections = [];
        for (let i = 0; i < state.adminUsers.elementsSorted.length; i++) {
            filterDirections.push(state.adminUsers.elementsSorted[i].desc ? 'desc' : 'asc')
        }
        [outIds, outEl] = sortUsers(_.keyBy(filteredElements, (element) => element.ID), state.adminUsers.elementsSorted, filterDirections);
    } else {
        [outIds, outEl] = sortUsers(_.keyBy(filteredElements, (element) => element.ID));
    }

    const elementsIds = outIds.slice(from, to);
    let res = [];
    for (let i = 0; i < elementsIds.length; i++) {
        res.push(_.get(outEl, elementsIds[i]));
    }
    return [res, filteredElementsIds.length];
}

export function getCurrentElementId(state) {
    return state.adminUsers.currentElementId;
}

export function getCurrentElement(state) {
    return _.get(state.adminUsers.elements, state.adminUsers.currentElementId);
}
export function getCurrentPageNumber(state) {
    return state.adminUsers.currentPageNumber;
}

export function getFetchError(state) {
    return state.adminUsers.elementFetchError;
}

export function getEditError(state) {
    return state.adminUsers.elementModifyError;
}

export function getDeleteError(state) {
    return state.adminUsers.elementDeleteError;
}

export function isEditMode(state) {
    return state.adminUsers.editMode || state.adminUsers.createMode;
}

export function isCreateMode(state) {
    return state.adminUsers.createMode;
}
