import React, { Component } from 'react'

import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

import autoBind from "react-autobind";
import _ from "lodash";


export default class SelectInputAsync extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    // getOptions(inputValue) {
    //     return new Promise(resolve =>  resolve(this.props.getOptions(inputValue)));
    //     // return new Promise(resolve =>  resolve(this.filterColors(inputValue)));
    // }

    getOptions(inputValue, callback) {
        callback(this.props.getOptions(inputValue));
    }

    onChange(selectedOption) {
        if (Array.isArray(selectedOption)) {
            const resValue = _.map(selectedOption, (el) => el.value);
            this.props.input.onChange(resValue)
        } else {
            if (this.props.input.onChange && selectedOption != null) {
                // To be aligned with how redux-form publishes its CHANGE action payload.
                // The event received is an object with 2 keys: "value" and "label"
                this.props.input.onChange(selectedOption.value);
            } else {
                // Clear the input field
                this.props.input.onChange(null);
            }
        }
    }

    renderCreatable() {
        return (
            <div className="font-roboto">
                <AsyncCreatableSelect
                    // value={this.props.input.value || ''}
                    {...this.props }
                    name={ this.props.input.name }
                    cacheOptions
                    defaultOptions
                    loadOptions={ this.getOptions }
                    isClearable={this.props.clearable}
                    isMulti={this.props.multiSelect}
                    isDisabled={this.props.disabled}
                    defaultValue={this.props.defaultValue}
                    onBlur={() => { this.props.input.onBlur(this.props.input.value) }}
                    onChange={this.onChange}
                    formatCreateLabelfunction={()=>'Создать:'}
                    onBlurResetsInput={false}
                />
                {this.props.meta.touched && (
                    (this.props.meta.error &&
                        <span className="badge badge-danger">{this.props.meta.error}</span>
                    ) ||
                    (this.props.meta.warning &&
                        <span className="badge badge-warning">{this.props.meta.warning}</span>
                    )
                )}
            </div>
        );
    }

    renderNonCreatable() {
        return (
            <div className="font-roboto">
                <AsyncSelect
                    {...this.props }
                    name={ this.props.input.name }
                    // cacheOptions
                    // value={this.props.input.value || ''}
                    // defaultValue={this.props.input.value || ''}
                    isClearable={this.props.clearable}
                    isMulti={this.props.multiSelect}
                    isDisabled={this.props.disabled}
                    defaultValue={this.props.defaultValue}
                    onBlur={() => this.props.input.onBlur(this.props.input.value)}
                    onChange={this.onChange}
                    defaultOptions={ this.props.defaultOptions }
                    // onInputChange={ SelectInputAsync.handleInputChange }
                    loadOptions={ this.getOptions }
                    onBlurResetsInput={false}
                />
                {this.props.meta.touched && (
                    (this.props.meta.error &&
                        <span className="badge badge-danger">{this.props.meta.error}</span>
                    ) ||
                    (this.props.meta.warning &&
                        <span className="badge badge-warning">{this.props.meta.warning}</span>
                    )
                )}
            </div>
        );
    }

    render() {
        return this.props.creatable ? this.renderCreatable() : this.renderNonCreatable()
    }
}
