import _ from "lodash";
import React, {Component} from 'react';
import autoBind from "react-autobind";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DATE_FORMATS} from '../globals/config'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './DatePickerInput.css';
import './DatePickerRangeInput.css';
import moment from 'moment';
import 'moment/locale/ru';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// const currentYear = new Date().getFullYear();
// const currentMonth = new Date();
// const fromMonth = new Date(currentYear - 10, 0);
// const toMonth = new Date(currentYear + 1, 11);

class DatePickerRangeInputComponent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="input-group form-date-input-group mb-3">
                <div className="input-group-prepend form-date-input-prepend">{this.props.placeholder}:&nbsp;</div>
                <input
                    {...this.props}
                    readOnly={true}
                    className="form-control form-control-sm font-roboto form-transparent-input"
                />
                <div className="input-group-append form-date-input-append">
                    <FontAwesomeIcon icon={faCalendarAlt}/>
                </div>
            </div>
        );
    }
}


class PureDatePickerRangeInputComponent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="input-group form-date-input-group mb-3">
                <input
                    {...this.props}
                    readOnly={true}
                    className="form-control form-control-sm font-roboto form-transparent-input"
                />
            </div>
        );
    }
}

export default class DatePickerRangeInput extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.ShowIcon = _.get(this.props, 'showIcon', true);
        this.formMode = 'input' in this.props;
        this.state = {
            start: undefined,
            end: undefined,
        };
    }

    showFromMonth() {
        if (!this.state.from) {
            return;
        }
        const from = moment(this.state.start, DATE_FORMATS.DATE_TIME);
        const to = moment(this.state.end, DATE_FORMATS.DATE_TIME);
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    onFromChange(date) {
        const value = this.formMode ? this.props.input.value : this.state;
        const end = _.get(value, 'end', undefined);
        let start = moment(date).set({'hour': 0, 'minute': 0, 'second': 0});
        const newValue = {start: start.format(DATE_FORMATS.DATE_TIME), 'end': end};
        if (this.formMode) {
            this.props.input.onChange(newValue);
        } else {
            this.setState({ 'start': start.format(DATE_FORMATS.DATE_TIME) });
            this.props.onChange(newValue);
        }
    }

    onToChange(date) {
        const value = this.formMode ? this.props.input.value : this.state;
        const start = _.get(value, 'start', undefined);
        let end = moment(date).set({'hour': 23, 'minute': 59, 'second': 59});
        const newValue = {'start': start, 'end': end.format(DATE_FORMATS.DATE_TIME)};
        if (this.formMode) {
            this.props.input.onChange(newValue);
        } else {
            this.setState({ 'end': end.format(DATE_FORMATS.DATE_TIME) });
            // this.setState({ to: end.format(DATE_FORMATS.DATE_TIME) }, this.showFromMonth);
            this.props.onChange(newValue);
        }
    }

    render() {
        const value = this.formMode ? this.props.input.value : this.state;
        const rawStart = _.get(value, 'start', undefined);
        const rawEnd = _.get(value, 'end', undefined);
        const initDateStart = rawStart ? moment(rawStart, DATE_FORMATS.DATE_TIME).toDate() : undefined;
        const initDateEnd = rawEnd ? moment(rawEnd, DATE_FORMATS.DATE_TIME).toDate() : undefined;
        const modifiers = {start: initDateStart, end: initDateEnd};

        const component = this.ShowIcon ? DatePickerRangeInputComponent : PureDatePickerRangeInputComponent;
        const mainClassName = this.ShowIcon ? "font-roboto row input-group" : "font-roboto row pure-input-group";
        const leftElClassName = this.ShowIcon ? "col-sm-6 input-group mb-3" : "col-sm-6 pure-input-element-left";
        const rightElClassName = this.ShowIcon ? "col-sm-6 input-group mb-3" : "col-sm-6 pure-input-element-right";
        return (
            <div className={mainClassName}>
                <div className={leftElClassName}>
                    <DayPickerInput
                        {...this.props}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        value={initDateStart ? `${formatDate(initDateStart, 'LL', 'ru')}` : ''}
                        placeholder="от"
                        dayPickerProps={{
                            selectedDays: [initDateStart, {from: initDateStart, to: initDateEnd}],
                            disabledDays: {after: initDateEnd},
                            toMonth: initDateEnd,
                            modifiers,
                            locale: 'ru',
                            localeUtils: MomentLocaleUtils,
                            numberOfMonths: 2,
                            todayButton: "Сегодня",
                            onDayClick: () => {
                                this.to.showDayPicker();
                                // todo: надо выбрать второй инпут!
                            },
                        }}
                        onDayChange={this.onFromChange}
                        component={component}
                    />
                    {this.renderError('start')}
                    {this.renderWarning('start')}
                </div>

                <div className={rightElClassName}>
                    <DayPickerInput
                        {...this.props}
                        ref={el => (this.to = el)}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        value={initDateEnd ? `${formatDate(initDateEnd, 'LL', 'ru')}` : ''}
                        placeholder="до"
                        dayPickerProps={{
                            selectedDays: [initDateStart, {from: initDateStart, to: initDateEnd}],
                            disabledDays: {before: initDateStart},
                            modifiers,
                            month: initDateStart,
                            fromMonth: initDateStart,
                            numberOfMonths: 2,
                            locale: 'ru',
                            localeUtils: MomentLocaleUtils,
                            todayButton: "Сегодня",
                        }}
                        onDayChange={this.onToChange}
                        component={component}
                    />
                    {this.renderError('end')}
                    {this.renderWarning('end')}
                </div>
            </div>
        );
    }

    renderError(key) {
        if (!this.props.meta || !this.props.meta.touched) {
            return
        }
        if (this.props.meta.error) {
            if (typeof this.props.meta.error === 'string') {
                return (<span className="badge badge-danger">{this.props.meta.error}</span>)
            }
            const error = _.get(this.props.meta.error, key, undefined);
            if (error) {
                return (<span className="badge badge-danger">{error}</span>)
            }
        }
    }

    renderWarning(key) {
        if (!this.props.meta || !this.props.meta.touched) {
            return
        }
        if (this.props.meta.warning) {
            if (typeof this.props.meta.warning === 'string') {
                return (<span className="badge badge-warning">{this.props.meta.warning}</span>)
            }
            const warning = _.get(this.props.meta.warning, key, undefined);
            if (warning) {
                return (<span className="badge badge-warning">{warning}</span>)
            }
        }
    }
}
