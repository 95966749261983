import React, { Component } from 'react'
import autoBind from "react-autobind";
import {faCalendarAlt, faClock} from "@fortawesome/free-solid-svg-icons";
import {DATE_FORMATS} from '../globals/config'

import MomentUtils from '@date-io/moment';

// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import {TimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
// import TimePicker from 'material-ui-pickers/TimePicker';
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import blue from '@material-ui/core/colors/blue';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './DatePickerInput.css'
import './DateTimePickerInput.css'
import moment from 'moment'
import 'moment/locale/ru'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const currentYear = new Date().getFullYear();
const currentMonth = new Date();
const fromMonth = new Date(currentYear - 10, 0);
const toMonth = new Date(currentYear + 1, 11);


const theme = createMuiTheme({
    palette: {
        primary: blue
    }
});

class DateTimePickerInputComponent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="input-group form-date-input-group mb-3">
                <input
                    {...this.props}
                    readOnly={true}
                    className="form-control form-control-sm font-roboto form-transparent-input"
                />
                <div className="input-group-append form-date-input-append">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
            </div>
        );
    }
}

export default class DateTimePickerInput extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.DateAccessors = ['year', 'month', 'date'];
        this.TimeAccessors = ['hour', 'minute', 'second'];
        this.state = {
            month: currentMonth,
        };
    }

    handleYearMonthChange(month) {
        this.setState({ month });
    }

    YearMonthForm({ date, localeUtils, onChange }) {
        const months = localeUtils.getMonths('LL');

        const years = [];
        for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
            years.push(i);
        }

        const handleChange = function handleChange(e) {
            const { year, month } = e.target.form;
            onChange(new Date(year.value, month.value));
        };

        return (
            <div className="DayPicker-Caption form-inline">
                <div className="row">
                    <div className="form-group form-group-month">
                        <select name="month" className="form-control form-control-select-month" onChange={handleChange} value={date.getMonth()}>
                            {months.map((month, i) => (
                                <option key={month} value={i}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group form-group-year">
                        <select name="year" className="form-control form-control-select-year" onChange={handleChange} value={date.getFullYear()}>
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        );
    }


    onChangeTime(time) {
        let curDate = this.props.input.value ?
            moment(this.props.input.value, DATE_FORMATS.DATE_TIME) :
            moment().set({'hour': 12, 'minute': 0, 'second': 0});
        const newDate = moment(time);
        for (let i = 0; i < this.TimeAccessors.length; i++) {
            if (this.TimeAccessors[i] === 'second') {
                curDate.set(this.TimeAccessors[i], 0);
            } else {
                curDate.set(this.TimeAccessors[i], newDate.get(this.TimeAccessors[i]));
            }
        }
        this.props.input.onChange(curDate.format(DATE_FORMATS.DATE_TIME));
    }

    onChangeDate(date) {
        let curDate = this.props.input.value ?
            moment(this.props.input.value, DATE_FORMATS.DATE_TIME) :
            moment().set({'hour': 12, 'minute': 0, 'second': 0});
        const newDate = moment(date);
        for (let i = 0; i < this.DateAccessors.length; i++) {
            curDate.set(this.DateAccessors[i], newDate.get(this.DateAccessors[i]))
        }
        this.props.input.onChange(curDate.format(DATE_FORMATS.DATE_TIME));
    }

    render() {
        const initDateTime = this.props.input.value ?
            moment(this.props.input.value, DATE_FORMATS.DATE_TIME) :
            moment().set({'hour': 12, 'minute': 0, 'second': 0});
        const initDate = this.props.input.value ?
            moment(this.props.input.value, DATE_FORMATS.DATE) :
            undefined;
        return (
            <div className="form-date-time-input-group font-roboto row input-group">
                <div className="col-sm-6 form-date-input-group input-group mb-3">
                    <DayPickerInput
                        {...this.props }
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        value={initDate ? `${formatDate(initDate, 'LL', 'ru')}`: ''}
                        placeholder={this.props.placeholder}
                        // placeholder={`${formatDate(new Date(), 'LL', 'ru')}`}
                        onDayChange={this.onChangeDate}
                        dayPickerProps={{
                            selected: initDate,
                            locale: 'ru',
                            localeUtils: MomentLocaleUtils,
                            month: this.state.month,
                            fromMonth: fromMonth,
                            toMonth: toMonth,
                            captionElement: ({ date, localeUtils }) => (
                                <this.YearMonthForm
                                    date={date}
                                    localeUtils={localeUtils}
                                    onChange={this.handleYearMonthChange}
                                />
                            ),
                            todayButton: "Сегодня",
                        }}
                        component={DateTimePickerInputComponent}
                    />
                </div>

                <div className="col-sm-6 form-time-input-group input-group mb-3">
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <TimePicker
                                // clearable
                                ampm={false}
                                label="время"
                                value={initDateTime}
                                className="form-control form-control-sm font-roboto"
                                onChange={this.onChangeTime}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                    <div className="input-group-append form-date-input-append">
                        <FontAwesomeIcon icon={faClock} />
                    </div>
                </div>

                {this.props.meta.touched && this.props.meta.error && <span className="badge badge-danger">{this.props.meta.error}</span>}
                {this.props.meta.touched && this.props.meta.warning && <span className="badge badge-warning">{this.props.meta.warning}</span>}
            </div>
        );
    }
}
