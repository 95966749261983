// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash'
import './EditView.css'
import React, {Component} from 'react';
import autoBind from 'react-autobind';
import {Button, Modal} from 'reactstrap'
import {Field, reduxForm} from 'redux-form';
import {Alert} from 'reactstrap';
import {ClipLoader} from 'react-spinners';
import DatePickerInput from './DatePickerInput';
import DateTimePickerInput from './DateTimePickerInput';
import DatePickerRangeInput from './DatePickerRangeInput';
import SelectInputAsync from './SelectInputAsync';
import MultiSelectTextInput from './MultiSelectTextInput';
import DropzoneInput from './DropzoneInput';
import ColorPickerInput from './ColorPickerInput';
import PresentFilesInput from './PresentFilesInput';
import {EDIT_VIEW_MODE, FORM_ELEMENT_TYPES} from '../globals/config'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {calcAvailability} from "../globals/functions";
import * as configs from "../globals/config";


class EditView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.mode = _.get(this.props, 'mode', EDIT_VIEW_MODE.Remote);
        this.references = {};
        this.state = this.initializeState();
    }

    initializeState() {
        let result = {};
        const keys = ['availability', 'visibility'];
        if (!this.props['linkedFields']) {
            return result
        }
        for (let mainIndex = 0; mainIndex < keys.length; mainIndex++) {
            let curKey = keys[mainIndex];
            for (let prop in this.props['linkedFields'][curKey]) {
                if (!this.props['linkedFields'][curKey]) {
                    continue
                }
                if (!this.props['linkedFields'][curKey].hasOwnProperty(prop)) {
                    continue
                }
                if (prop === 'all_dependent_fields') {
                    continue
                }

                let value = this.props.initialValues[prop];
                if (this.props['linkedFields'][curKey][prop].type === 'bool') {
                    value = !value ? 'true' : 'false';
                }
                let partialState = this.setStateForElement(prop, value, true);
                result = {...result, ...partialState};
            }
        }
        return result;
    }

    render() {
        const {handleSubmit} = this.props;
        const className = this.props.class ? `modal-body edit-view ${this.props.class}` : `modal-body edit-view`;
        const size = this.props.size ? this.props.size : "lg";
        return (
            <Modal isOpen={this.props.show} size={size} className="font-roboto edit-view">
                <form className="form-signin font-roboto edit-view" onSubmit={handleSubmit}>
                    {this.renderHeader()}
                    <div className={className}>
                        {_.map(this.props.fields, this.renderFromElement)}
                    </div>
                    {this.renderFooter()}
                </form>
            </Modal>
        );
    }

    renderFromElement(element) {
        if (this.isElementHidden(element)) {
            return ''
        }
        switch (element.type) {
            case FORM_ELEMENT_TYPES.REACT_SELECT:
                return this.renderSelect(element);
            case FORM_ELEMENT_TYPES.REACT_MULTI_SELECT_TEXT:
                return this.renderMultiSelectText(element);
            case FORM_ELEMENT_TYPES.INPUT:
                return this.renderInput(element);
            case FORM_ELEMENT_TYPES.TEXT_AREA:
                return this.renderTextArea(element);
            case FORM_ELEMENT_TYPES.DATE_PICKER:
                return this.renderDatePicker(element);
            case FORM_ELEMENT_TYPES.DATE_TIME_PICKER:
                return this.renderDateTimePicker(element);
            case FORM_ELEMENT_TYPES.DATE_PICKER_RANGE:
                return this.renderDatePickerRange(element);
            case FORM_ELEMENT_TYPES.CHECKBOX:
                return this.renderCheckbox(element);
            case FORM_ELEMENT_TYPES.CHECKBOX_LINK:
                return this.renderCheckboxLink(element);
            case FORM_ELEMENT_TYPES.CHECKBOX_GROUP:
                return this.renderCheckboxGroup(element);
            case FORM_ELEMENT_TYPES.FILE_DROPZONE:
                return this.renderDropzoneFile(element);
            case FORM_ELEMENT_TYPES.COLOR_PICKER:
                return this.renderColorPicker(element);
            case FORM_ELEMENT_TYPES.RADIO_GROUP:
                return this.renderRadioGroup(element);
            default:
                return '';
        }
    }

    renderHeader() {
        if (this.props.CustomTitle) {
            return (
                <div className="modal-header font-roboto edit-view w-100">
                    <div className="row w-100 form-group-compact">
                        <div className="col-10">
                            <h5>{this.props.CustomTitle}</h5>
                        </div>
                        <div className="col-2 align-content-end">
                            <ClipLoader size={25} color={'#123abc'} loading={this.props.loadingData}/>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.props.createMode) {
            return (
                <div className="modal-header font-roboto edit-view w-100">
                    <div className="row w-100 form-group-compact">
                        <div className="col-10">
                            <h5>{`Добавление ${this.props.titleEntity}`}</h5>
                        </div>
                        <div className="col-2 align-content-end">
                            <ClipLoader size={25} color={'#123abc'} loading={this.props.loadingData}/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="modal-header font-roboto edit-view w-100">
                <div className="row w-100">
                    <div className="col-10">
                        <h5>{`Изменение ${this.props.titleEntity}`}</h5>
                    </div>
                    <div className="col-2 align-content-end">
                        <ClipLoader size={25} color={'#123abc'} loading={this.props.loadingData}/>
                    </div>
                </div>
            </div>
        );
    }

    renderFooter() {
        const {submitting} = this.props;
        const calcIfBtnDisabled = () => {
            if (this.mode === EDIT_VIEW_MODE.NoCheck) {
                return submitting;
            }
            if (this.mode === EDIT_VIEW_MODE.Local) {
                return !calcAvailability(this.props.sectionName, configs.TOOLBAR_ACTIONS.READ, 100500) || submitting;
            }
            return !calcAvailability(this.props.sectionName, configs.TOOLBAR_ACTIONS.CREATE, 100500) || submitting;
        };
        const renderExtButtons = (btn) => {
            if ('hidden' in btn) {
                if (!btn['hidden']) {
                    return;
                }
            }
            if (this.props.createMode && !btn.visibleInCreateMode) {
                return;
            }
            if (!this.props.createMode && !btn.visibleInEditMode) {
                return;
            }
            if (btn.visibleFnc) {
                if (typeof btn.visibleFnc === 'function') {
                    if (!btn.visibleFnc()) {
                        return;
                    }
                }
            }
            return (
                <Button key={btn.key} color={btn.color} onClick={btn.onClick}>{btn.text}</Button>
            )
        };
        const extButtons = _.get(this.props, 'extButtons', []);
        return (
            <div className="modal-footer edit-footer edit-view">
                <div className="container w-100">
                    <div className="row edit-footer-row">
                        <div className="col-6">
                            {_.map(extButtons, renderExtButtons)}
                        </div>
                        <div className="col-6 justify-content-end d-flex">
                            <button className="btn save-btn"
                                    type="submit"
                                    disabled={calcIfBtnDisabled()}
                            >Сохранить
                            </button>
                            <Button color="secondary" onClick={this.props.onCancel}>Отмена</Button>
                        </div>
                    </div>
                    {this.renderError()}
                </div>
            </div>
        );
    }

    renderError() {
        let errTitle = "Ошибка изменения";
        if (this.props.createMode) {
            errTitle = "Ошибка создания";
        }
        if (this.props.editError) {
            return (
                <div className="row w-100 col-12 modal-error-container">
                    <Alert className="w-100 row" color="danger" isOpen={true} toggle={this.props.dismissErrorAlert}>
                        {errTitle}: {this.props.editError.message}!
                    </Alert>
                </div>
            );
        }
    }

    static renderCheckboxComponent = (options) => {
        return (
            <div className="col-sm-9 font-roboto">
                <div className="form-input-group">
                    <input
                        {...options.input}
                        disabled={options.disabled}
                        type={options.type}
                        className="form-check-input form-checkbox"
                        style={{width: 200}}
                    />
                    <div className="form-checkbox-label">
                        {options.label}
                    </div>
                </div>
                {options.meta.touched && options.meta.error &&
                <span className="badge badge-danger">{options.meta.error}</span>}
                {options.meta.touched && options.meta.warning &&
                <span className="badge badge-warning">{options.meta.warning}</span>}
            </div>
        );
    };

    renderCheckboxLinkComponent = (options) => {
        return (
            <div className="col-sm-9 font-roboto">
                <div className="form-input-group">
                    <input
                        {...options.input}
                        disabled={options.disabled}
                        type={options.type}
                        className="form-check-input form-checkbox"
                        style={{width: 200}}
                    />
                    {/*<label className="form-check-label font-roboto" htmlFor="exampleCheck1">{options.label}</label>*/}
                    <div className="form-checkbox-label">
                        {options.label}&nbsp;
                        <a href={options.rawElement.href}
                           key={options.rawElement.text}
                           download
                           className={options.rawElement.className}
                           role="button"
                           onClick={(e) => {
                               let params = [];
                               for (let i = 0; i < options.rawElement.dependentFields.length; i++) {
                                   const val = this.references[options.rawElement.dependentFields[i]].value;
                                   params.push(val)
                               }
                               options.rawElement.onClick(e, ...params);
                           }}
                        >{options.rawElement.linkText}</a>
                    </div>
                </div>
                {options.meta.touched && options.meta.error &&
                <span className="badge badge-danger">{options.meta.error}</span>}
                {options.meta.touched && options.meta.warning &&
                <span className="badge badge-warning">{options.meta.warning}</span>}
            </div>
        );
    };

    static renderCheckboxGroupElementComponent = (options) => {
        // todo: захардкодим пока класс 3 (то есть группа расчитана строго на 3 элемента)
        return (
            <div className="col-sm-3 font-roboto">
                <div className="form-input-group form-checkbox-group-element">
                    <input
                        {...options.input}
                        disabled={options.disabled}
                        type={options.type}
                        className="form-check-input form-checkbox"
                        // style={ {width: 200} }
                    />
                    <div className="form-checkbox-label">
                        {options.label}
                    </div>
                </div>
                {options.meta.touched && options.meta.error &&
                <span className="badge badge-danger">{options.meta.error}</span>}
                {options.meta.touched && options.meta.warning &&
                <span className="badge badge-warning">{options.meta.warning}</span>}
            </div>
        );
    };

    static renderInputComponent = (options) => {
        return (
            <div className="col-sm-9 font-roboto">
                <div className="input-group form-input-group mb-3">
                    {options.appendLeft ?
                        <div className="input-group-prepend">
                            <button
                                type="button"
                                className="btn font-roboto"
                                onClick={() => {
                                    options.appendLeftAction(options.input.value)
                                }}
                            ><FontAwesomeIcon icon={options.appendLeft}/></button>
                        </div> :
                        ''
                    }

                    <input {...options.input}
                           disabled={options.disabled}
                           placeholder={options.placeholder}
                           type={options.type}
                           min={options.inputMin}
                           max={options.inputMax}
                           step={options.inputStep}
                           className="form-control form-control-sm  font-roboto form-input"
                    />

                    {options.appendRight ?
                        <div className="input-group-append">
                            <button
                                type="button"
                                className="btn font-roboto"
                                onClick={() => {
                                    options.appendRightAction(options.input.value)
                                }}
                            ><FontAwesomeIcon icon={options.appendRight}/></button>
                        </div> :
                        ''
                    }
                </div>
                {options.meta.touched && options.meta.error &&
                <span className="badge badge-danger">{options.meta.error}</span>}
                {options.meta.touched && options.meta.warning &&
                <span className="badge badge-warning">{options.meta.warning}</span>}
            </div>
        );
    };

    static renderRadioGroupComponent = (options) => {
        return (
            <input {...options.input}
                   disabled={options.disabled}
                   placeholder={options.placeholder}
                   type={options.type}
                   className="form-control form-control-sm font-roboto form-input radio-input"
            />
        );
    };

    static renderTextAreaComponent = (options) => {
        return (
            <div className="col-sm-9 font-roboto">
                <textarea {...options.input}
                          rows={options.rowsCount}
                          placeholder={options.placeholder}
                          className="form-control form-control-sm font-roboto form-input-textarea"
                          disabled={options.disabled}
                />
                {options.meta.touched && options.meta.error &&
                <span className="badge badge-danger">{options.meta.error}</span>}
                {options.meta.touched && options.meta.warning &&
                <span className="badge badge-warning">{options.meta.warning}</span>}
            </div>
        );
    };

    renderSelect(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        component={SelectInputAsync}
                        name={element.name}
                        classNamePrefix="select"
                        placeholder={element.placeholder}
                        isSearchable={true}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{
                            disabled: this.isElementDisabled(element),
                        }}
                        // disabled={element.disabled}
                        getOptions={element.getOptions}
                        multiSelect={element.multiSelect}
                        creatable={element.creatable}
                        clearable={element.clearable}
                        defaultOptions={element.defaultOptions}
                        defaultValue={element.defaultValue}
                        validate={element.validate}
                        warn={element.warn}
                    />
                </div>
            </div>
        );
    }

    renderMultiSelectText(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        component={MultiSelectTextInput}
                        name={element.name}
                        classNamePrefix="select"
                        placeholder={element.placeholder}
                        isSearchable={true}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{disabled: this.isElementDisabled(element)}}
                        tooltipId={`${element.name}_tooltip`}
                        tooltipTitle={_.get(element, 'tooltipTitle', undefined)}
                        tooltipBody={_.get(element, 'tooltipBody', undefined)}
                        // disabled={element.disabled}
                        getOptions={element.getOptions}
                        multiSelect={element.multiSelect}
                        creatable={element.creatable}
                        clearable={element.clearable}
                        defaultOptions={element.defaultOptions}
                        defaultValue={element.defaultValue}
                        validate={element.validate}
                        warn={element.warn}
                    />
                </div>
            </div>
        );
    }

    renderDatePicker(element) {
        let anchorKey = `${element.dayShifterAnchorField}_value`;
        let anchorDefVal = element.dayShifterAnchorDefault;
        let dayShiftPlaceholder = _.get(element, 'dayShifterPlaceholder', "");
        if ('dayShifterAnchorFieldConditions' in element) {
            const conditionField = element.dayShifterAnchorFieldConditions.conditionField;
            const conditionValue = element.dayShifterAnchorFieldConditions.conditionValue;
            const curValue = this.state[`${conditionField}_value`] ? this.state[`${conditionField}_value`] : element.dayShifterAnchorFieldConditions.conditionFieldDefault;
            if (Number(conditionValue) === Number(curValue)) {
                anchorKey = `${element.dayShifterAnchorFieldConditions.trueField}_value`;
                anchorDefVal = element.dayShifterAnchorFieldConditions.trueDefault;
                dayShiftPlaceholder = _.get(element.dayShifterAnchorFieldConditions, 'truePlaceholder', "");
            } else {
                anchorKey = `${element.dayShifterAnchorFieldConditions.falseField}_value`;
                anchorDefVal = element.dayShifterAnchorFieldConditions.falseDefault;
                dayShiftPlaceholder = _.get(element.dayShifterAnchorFieldConditions, 'falsePlaceholder', "");
            }
        }
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        name={element.name}
                        placeholder={element.placeholder}
                        component={DatePickerInput}
                        validate={element.validate}
                        warn={element.warn}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{
                            disabled: this.isElementDisabled(element),
                            use_day_shifter: _.get(element, 'useDayShifter', undefined),
                            day_shifter_placeholder: dayShiftPlaceholder,
                            anchor_value: this.state[anchorKey] ? this.state[anchorKey] : anchorDefVal,
                        }}
                    />
                </div>
            </div>
        );
    }

    renderColorPicker(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        name={element.name}
                        placeholder={element.placeholder}
                        component={ColorPickerInput}
                        validate={element.validate}
                        warn={element.warn}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{disabled: this.isElementDisabled(element)}}
                    />
                </div>
            </div>
        );
    }

    renderDateTimePicker(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        name={element.name}
                        placeholder={element.placeholder}
                        component={DateTimePickerInput}
                        validate={element.validate}
                        warn={element.warn}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{disabled: this.isElementDisabled(element)}}
                    />
                </div>
            </div>
        );
    }

    renderDatePickerRange(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        name={element.name}
                        placeholder={element.placeholder}
                        component={DatePickerRangeInput}
                        validate={element.validate}
                        warn={element.warn}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{disabled: this.isElementDisabled(element)}}
                    />
                </div>
            </div>
        );
    }

    renderDropzoneFileHidden(element) {
        return (
            <Field
                ref={el => {
                    this.references[element.name_hidden] = el
                }}
                name={element.name_hidden}
                component={PresentFilesInput}
                onChange={(e) => {
                    this.onChangeElement(element, e);
                }}
                props={{
                    disabled: this.isElementDisabled(element),
                    readOnly: _.get(element, 'readOnly', false)
                }}
            />
        );
    }

    renderDropzoneFile(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9 font-roboto">
                    <Field
                        ref={el => {
                            this.references[element.name] = el
                        }}
                        name={element.name}
                        component={DropzoneInput}
                        onChange={(e) => {
                            this.onChangeElement(element, e);
                        }}
                        props={{disabled: this.isElementDisabled(element)}}
                        // disabled={element.disabled}
                    />
                    {this.renderDropzoneFileHidden(element)}
                </div>
            </div>
        );
    }

    renderRadioGroup(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <div className="col-sm-9 font-roboto">
                    {element.items.map((item) => (
                        <div className="row form-radio-group-row" key={`${element.name}-${item.value}`}>
                            <Field
                                name={element.name}
                                component={EditView.renderRadioGroupComponent}
                                type="radio"
                                value={item.value}
                                onChange={(e) => {
                                    this.onChangeElement(element, e);
                                }}
                            />
                            <label className="col-form-label col-sm-3">{item.label}</label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderCheckbox(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <div className="col-form-label col-sm-3"/>
                <Field
                    ref={el => {
                        this.references[element.name] = el
                    }}
                    name={element.name}
                    component={EditView.renderCheckboxComponent}
                    label={element.label || element.placeholder}
                    type={"checkbox"}
                    className="form-control form-control-sm"
                    validate={element.validate}
                    warn={element.warn}
                    onChange={(e) => {
                        this.onChangeElement(element, e);
                    }}
                    props={{disabled: this.isElementDisabled(element)}}
                />
            </div>
        );
    }

    renderCheckboxLink(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <div className="col-form-label col-sm-3"/>
                <Field
                    ref={el => {
                        this.references[element.name] = el
                    }}
                    name={element.name}
                    component={this.renderCheckboxLinkComponent}
                    rawElement={element}
                    label={element.label || element.placeholder}
                    type={"checkbox"}
                    className="form-control form-control-sm"
                    validate={element.validate}
                    warn={element.warn}
                    onChange={(e) => {
                        this.onChangeElement(element, e);
                    }}
                    props={{disabled: this.isElementDisabled(element)}}
                />
            </div>
        );
    }

    renderCheckboxGroup(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label}
                </label>
                {_.map(element.fields, this.renderCheckboxGroupElement)}
            </div>
        );
    }

    renderCheckboxGroupElement(element) {
        return (
            <Field
                ref={el => {
                    this.references[element.name] = el
                }}
                name={element.name}
                key={`${element.name}_grpEl`}
                component={EditView.renderCheckboxGroupElementComponent}
                label={element.label || element.placeholder}
                type={"checkbox"}
                className="form-control form-control-sm"
                validate={element.validate}
                warn={element.warn}
                onChange={(e) => {
                    this.onChangeElement(element, e);
                }}
                props={{disabled: this.isElementDisabled(element)}}
            />
        );
    }

    renderInput(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <Field
                    ref={el => {
                        this.references[element.name] = el
                    }}
                    // ref={el => {this.fileInput = el}}
                    // value={this.getElementValue(element)}
                    name={element.name}
                    component={EditView.renderInputComponent}
                    inputMin={element.inputMin}
                    inputMax={element.inputMax}
                    inputStep={element.inputStep ? element.inputStep : "1"}
                    type={element.inputType || "text"}
                    className="form-control form-control-sm"
                    placeholder={element.placeholder}
                    validate={element.validate}
                    warn={element.warn}
                    appendLeft={element.appendLeft}
                    appendLeftAction={element.appendLeftAction}
                    appendRight={element.appendRight}
                    appendRightAction={element.appendRightAction}
                    onChange={(e) => {
                        if (this.props.createMode) {
                            if (element.loadExtDataOnCreateMode) {
                                element.loadExtDataOnCreateMode(e.target.value);
                            }
                        }
                        this.onChangeElement(element, e);
                    }}
                    props={{disabled: this.isElementDisabled(element)}}
                />
            </div>
        );
    }

    renderTextArea(element) {
        return (
            <div className={EditView.formRowClasses(element)} key={element.name}>
                <label htmlFor={element.name} className="col-form-label col-sm-3 form-field-label">
                    {element.label || element.placeholder}
                </label>
                <Field
                    ref={el => {
                        this.references[element.name] = el
                    }}
                    name={element.name}
                    component={EditView.renderTextAreaComponent}
                    // component={"textarea"}
                    rowsCount={element.rowCount ? element.rowCount : 3}
                    className={"form-control form-control-sm"}
                    placeholder={element.placeholder}
                    validate={element.validate}
                    warn={element.warn}
                    onChange={(e) => {
                        this.onChangeElement(element, e);
                    }}
                    props={{disabled: this.isElementDisabled(element)}}
                />
            </div>
        );
    }

    static formRowClasses(element) {
        if ('noRowMargin' in element && element.noRowMargin)
            return "row form-group-tiny";

        return "row form-group-compact";
    }

    onChangeElement(element, eventSource) {
        // обобщенный обработчик изменения инпутов - будет делаться только для связанных полей
        // обработка доступности и видимости
        let value;
        let arr_values = [];
        const key = `${element.name}_value`;
        switch (element.type) {
            case FORM_ELEMENT_TYPES.REACT_SELECT:
                // todo: должен же быть какой-то более цивилизованный способ сделать это?
                for (let k in eventSource) {
                    // добавим поля в данные - кроме файловых полей
                    if (!eventSource.hasOwnProperty(k)) {
                        continue;
                    }
                    if (typeof eventSource[k] !== "string" && typeof eventSource[k] !== "number") {
                        continue;
                    }
                    arr_values.push(eventSource[k]);
                }
                value = arr_values.join('');
                this.setState({[key]: value});
                break;
            case FORM_ELEMENT_TYPES.CHECKBOX:
            case FORM_ELEMENT_TYPES.INPUT:
            case FORM_ELEMENT_TYPES.TEXT_AREA:
                value = eventSource.target.value;
                break;
            case FORM_ELEMENT_TYPES.DATE_PICKER:
                const arr = [
                    eventSource[0], eventSource[1], eventSource[2], eventSource[3], eventSource[4],
                    eventSource[5], eventSource[6], eventSource[7], eventSource[8], eventSource[9]
                ];
                value = arr.join('');
                this.setState({[key]: value});
                break;
            // todo: для этих типов пока не реализовано получение значения
            case FORM_ELEMENT_TYPES.FILE_DROPZONE:
            case FORM_ELEMENT_TYPES.DATE_PICKER_RANGE:
            case FORM_ELEMENT_TYPES.REACT_MULTI_SELECT_TEXT:
                value = '';
                break;
            default:
                value = '';
        }
        this.setStateForElement(element.name, value);
        this.aggregateElementValue(element.name, value);
        this.extAggregateElementValue(element.name, value);
        this.setValueOnChange(element.name, value);
        this.callExtFunctionOnElement(element, value);
    }

    setValueOnChange(elementName, value) {
        if (!value || !this.props['linkedFields'] || !this.props['linkedFields']['setValue']) {
            return
        }
        if (elementName !== this.props['linkedFields']['setValue']['onChangeField']) {
            return
        }
        let valToSet = undefined;
        if (this.props['linkedFields']['setValue']['type'] === 'ext_fnc') {
            valToSet = this.props['linkedFields']['setValue']['valueFnc'](value);
            // console.log('setValueOnChange:');
            // console.log('value:', value);
            // console.log('valToSet:', valToSet);
        } else {
            // let params = [];
            // for (let i = 0; i < this.props['linkedFields']['setValue']['valueParams'].length; i++) {
            //     params.push(this.props['linkedFields']['setValue']['valueParams'][i]())
            // }
            // valToSet = this.props['linkedFields']['setValue']['valueFnc'](...params);
            // console.log('params:', params);
            // console.log('valToSet:', valToSet);
            // console.log(this.props['linkedFields']['setValue']['field']);
            // console.log(this.references[this.props['linkedFields']['setValue']['field']]);
        }
        this.props.change(this.props['linkedFields']['setValue']['field'], valToSet);
        // this.references[this.props['linkedFields']['setValue']['field']].props.value = params[1];
    }

    callExtFunctionOnElement(element, value) {
        if (!element.loadExtData) {
            return
        }
        if (!this.props['linkedFields'] || !this.props['linkedFields']['externalAggregate']) {
            return
        }
        const names = this.props['linkedFields']['externalAggregate']['fields'];
        const values = this.getValuesByNames(names, element.name, value);
        element.loadExtData(...values)
    }

    aggregateElementValue(elementName, value) {
        const mainKeys = ['multiplication'];
        for (let kIndex = 0; kIndex < mainKeys.length; kIndex++) {
            if (!this.props['linkedFields'] || !this.props['linkedFields'][mainKeys[kIndex]]) {
                continue
            }
            for (let prop in this.props['linkedFields'][mainKeys[kIndex]]) {
                if (!this.props['linkedFields'][mainKeys[kIndex]].hasOwnProperty(prop)) {
                    continue
                }
                if (!this.props['linkedFields'][mainKeys[kIndex]][prop].includes(elementName)) {
                    continue
                }
                const names = this.props['linkedFields'][mainKeys[kIndex]][prop];
                switch (mainKeys[kIndex]) {
                    case "multiplication":
                        this.props.change(prop, this.calcMultiplication(names, elementName, value));
                        break;
                    default:
                        break;
                }
            }
        }
    }

    extAggregateElementValue(elementName, value) {
        const mainKey = 'externalCalc';
        if (!this.props['linkedFields'] || !this.props['linkedFields'][mainKey]) {
            return
        }
        const input_names = this.props['linkedFields'][mainKey]['input_values'];
        if (!input_names.includes(elementName)) {
            return
        }
        const values = this.getValuesByNames(input_names, elementName, value);
        const resName = this.props['linkedFields'][mainKey]['res_value'];
        const fnc = this.props['linkedFields'][mainKey]['fnc'];
        const resValue = fnc(...values);
        if (resValue !== undefined) {
            this.props.change(resName, resValue);
        }
    }

    calcMultiplication(elementNames, changedName, changedValue) {
        if (elementNames.length === 0) {
            return undefined
        }
        let res = Number(changedValue);
        for (let i = 0; i < elementNames.length; i++) {
            if (elementNames[i] === changedName) {
                continue
            }
            res *= Number(this.references[elementNames[i]].value)
        }
        if (isNaN(res))
            res = '';
        res = Math.round(res * 100) / 100;
        return res;
    }

    getValuesByNames(elementNames, changedName, changedValue) {
        if (elementNames.length === 0) {
            return []
        }
        let res = [];
        for (let i = 0; i < elementNames.length; i++) {
            if (elementNames[i] === changedName) {
                res.push(changedValue)
            } else {
                res.push(this.references[elementNames[i]] ? this.references[elementNames[i]].value : undefined)
            }
        }
        return res;
    }

    setStateForElement(elementName, elementValue, returnValue = false) {
        const mainKeys = ['availability', 'visibility'];
        const mainKeySuffixes = ['_disabled', '_hidden'];
        let result = {};
        for (let kIndex = 0; kIndex < mainKeys.length; kIndex++) {
            if (!this.props['linkedFields'] || !this.props['linkedFields'][mainKeys[kIndex]]) {
                continue
            }
            for (let prop in this.props['linkedFields'][mainKeys[kIndex]]) {
                if (!this.props['linkedFields'][mainKeys[kIndex]].hasOwnProperty(prop)) {
                    continue
                }
                if (prop !== elementName) {
                    continue
                }

                const obj = this.props['linkedFields'][mainKeys[kIndex]][prop];
                switch (obj.type) {
                    case 'bool':
                        for (let i = 0; i < obj.values.length; i++) {
                            const key = `${obj.values[i]}${mainKeySuffixes[kIndex]}`;
                            if (returnValue) {
                                result[key] = elementValue === 'true'
                            } else {
                                this.setState({
                                    // сюда прилетает текущее значение которое еще не изменилось - поэтому так
                                    [key]: elementValue === 'true',
                                });
                            }
                        }
                        break;
                    case 'trigger':
                        // по умолчанию покажем все
                        let defValue = false;
                        if (obj.reverse) {
                            defValue = true;
                        }
                        for (let i = 0; i < obj.all_value.length; i++) {
                            const key = `${obj.all_value[i]}${mainKeySuffixes[kIndex]}`;
                            if (returnValue) {
                                result[key] = defValue
                            } else {
                                this.setState({
                                    [key]: defValue,
                                });
                            }
                        }
                        // далее если триггер сработал (выбрано не пустое значение) - скроем все что указано
                        if (!elementValue) {
                            break
                        }
                        for (let i = 0; i < obj.all_value.length; i++) {
                            const key = `${obj.all_value[i]}${mainKeySuffixes[kIndex]}`;
                            if (returnValue) {
                                result[key] = !defValue
                            } else {
                                this.setState({
                                    [key]: !defValue,
                                });
                            }
                        }
                        break;
                    case 'ext-function':
                        // todo: пока только доступность обрабатываем этого типа
                        if (mainKeys[kIndex] !== 'availability') {
                            break
                        }
                        for (let i = 0; i < obj.values.length; i++) {
                            const key = `${obj.values[i]}${mainKeySuffixes[kIndex]}`;
                            const value = obj.fnc(elementValue);
                            if (returnValue) {
                                result[key] = value
                            } else {
                                this.setState({
                                    [key]: value,
                                });
                            }
                        }
                        break;
                    case 'int':
                        // todo: пока только видимость обрабатываем этого типа
                        if (mainKeys[kIndex] !== 'visibility') {
                            break
                        }

                        // по умолчанию скроем все
                        for (let i = 0; i < obj.all_value.length; i++) {
                            const key = `${obj.all_value[i]}${mainKeySuffixes[kIndex]}`;
                            if (returnValue) {
                                result[key] = true
                            } else {
                                this.setState({
                                    [key]: true,
                                });
                            }
                        }
                        // далее в зависимости от того, что пришло - уже подумаем что показывать
                        let values = _.get(obj.values, Number(elementValue), []);
                        for (let i = 0; i < values.length; i++) {
                            const key = `${values[i]}${mainKeySuffixes[kIndex]}`;
                            if (returnValue) {
                                result[key] = false
                            } else {
                                this.setState({
                                    [key]: false,
                                });
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        return result;
    }

    isElementDisabled(element) {
        // если задано явно - возвращаем, что задали
        if ('disabled' in element) {
            return element.disabled;
        }
        // если задано недостопность в режиме создания - хорошо, так тому и быть
        if (this.props.createMode) {
            if (this.props['disableFieldsInCreateMode']) {
                if (!this.props['enabledFieldsInCreateMode'].includes(element.name)) {
                    return true
                }
            }
        }
        // для остальных - проверяем по стейту
        // если заданы связанные - проверяем по значениям связанных элементам
        if (this.props['linkedFields'] &&
            this.props['linkedFields']['availability'] &&
            this.props['linkedFields']['availability']['all_dependent_fields']) {
            if (this.props['linkedFields']['availability']['all_dependent_fields'].includes(element.name)) {
                const key = `${element.name}_disabled`;
                return this.state[key];
            }
        }
        return false;
    }

    isElementHidden(element) {
        // если задано явно - возвращаем, что задали
        if ('hidden' in element) {
            return element.hidden;
        }
        // для остальных - проверяем по стейту
        // если заданы связанные - проверяем по значениям связанных элементам
        if (this.props['linkedFields'] &&
            this.props['linkedFields']['visibility'] &&
            this.props['linkedFields']['visibility']['all_dependent_fields']) {
            if (this.props['linkedFields']['visibility']['all_dependent_fields'].includes(element.name)) {
                const key = `${element.name}_hidden`;
                return this.state[key];
            }
        }
        return false;
    }
}


EditView = reduxForm({
    form: 'productEdit',        // имя формы в state (state.form.productEdit)
    enableReinitialize: true,   // this is needed!! (без этого не будет работать установка начального состояния)
})(EditView);

export default EditView;
