import React, { Component } from 'react'
import autoBind from "react-autobind";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {rootUrl} from "../globals/functions";


const MaxLinkLen = 37;

export default class PresentFilesInput extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    renderFiles() {
        const files = this.props.input.value;
        let classNameTrash = "font-roboto btn btn-link-danger list-group-files-a-del";
        let classNameMain = "font-roboto btn btn-link-normal list-group-files-a";
        if (this.props.disabled) {
            classNameTrash += ' disabled';
            classNameMain += ' disabled';
        }
        if (this.props.readOnly) {
            classNameTrash += ' disabled';
        }

        if (files && Array.isArray(files) && files.length) {
            return (
                <div className="w-100 font-roboto">
                    <h6 className="list-group-files-title">Существующие файлы:</h6>
                    <ul className="list-group list-group-files-ul">
                        { files.map((file, i) =>
                            <li className="list-group-item list-group-files-li" key={i}>
                                <a
                                    href={rootUrl() + file['Url']}
                                    className={classNameMain}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >{file['Name'].length > MaxLinkLen ?
                                    file['Name'].substring(0, MaxLinkLen)+'...' :
                                    file['Name']
                                }
                                </a>
                                <a
                                    href="/"
                                    className={classNameTrash}
                                    onClick={ (e) => {
                                        e.preventDefault();
                                        this.props.input.value.splice(i, 1);
                                        this.forceUpdate();
                                    } }
                                ><FontAwesomeIcon icon={faTrashAlt} /></a>
                            </li>
                        )}
                    </ul>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="w-100 font-roboto">
                <input name={this.props.input.name} hidden={true}/>
                {this.renderFiles()}
            </div>
        );
    }
}
