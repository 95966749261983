import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'

import auth from './auth/reducer';
import streams from './streams/reducer';
import monitoringRT from './monitoringRT/reducer'
import ppe from './ppe/reducer';
import calendar from './calendar/reducer';
import common from './common/reducer';
import adminUsers from './adminUsers/reducer';

export default combineReducers({
    auth,
    streams,
    monitoringRT,
    ppe,
    calendar,
    adminUsers,
    common,
    form: formReducer
});
