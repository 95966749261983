import _ from "lodash";
import React, {Component} from 'react'
import autoBind from "react-autobind";
import {faCalendarAlt, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {DATE_FORMATS, HOLIDAYS_CALENDAR} from '../globals/config'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './DatePickerInput.css'
// import moment from 'moment'
import moment from 'moment-business-days'
import 'moment/locale/ru'
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const currentYear = new Date().getFullYear();
const currentMonth = new Date();
const fromMonth = new Date(currentYear - 10, 0);
const toMonth = new Date(currentYear + 1, 11);


class DatePickerInputComponent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="input-group form-date-input-group mb-3">
                <input
                    {...this.props}
                    readOnly={true}
                    className="form-control form-control-sm font-roboto form-transparent-input"
                />
                <div className="input-group-append form-date-input-append">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
            </div>
        );
    }
}


class PureDatePickerInputComponent extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="input-group form-date-input-group mb-3">
                <input
                    {...this.props}
                    readOnly={true}
                    className="form-control form-control-sm font-roboto form-transparent-input"
                />
            </div>
        );
    }
}

export default class DatePickerInput extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.ShowIcon = _.get(this.props, 'showIcon', true);
        this.formMode = 'input' in this.props;
        this.state = {
            month: currentMonth,
            anchorValue: _.get(this.props, 'anchor_value', undefined),
            curValue: undefined,
            delta: undefined,
            popoverOpen: false,
            useBusinessDay: false,
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const newAnchorValue = _.get(this.props, 'anchor_value', undefined);
        if (this.state.anchorValue === newAnchorValue) {
            return;
        }
        if (!newAnchorValue) {
            console.warn('Warning: field does not provide anchor value');
            return;
        }
        if (!this.state.delta) {
            this.setState({
                anchorValue: newAnchorValue,
            });
            return;
        }
        // todo: правильный перересчет с учетом раб/календ дней
        // const newVal = moment(newAnchorValue, DATE_FORMATS.DATE).add(this.state.delta, 'days');
        const newVal = DatePickerInput.calcDate(newAnchorValue, this.state.delta, this.state.useBusinessDay);
        this.setState({
            'curValue': newVal.format(DATE_FORMATS.DATE),
            anchorValue: newAnchorValue,
        });
        this.onChange(newVal);
    }

    handleYearMonthChange(month) {
        this.setState({ month });
    }

    YearMonthForm({ date, localeUtils, onChange }) {
        const months = localeUtils.getMonths('LL');

        const years = [];
        for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
            years.push(i);
        }

        const handleChange = function handleChange(e) {
            const { year, month } = e.target.form;
            onChange(new Date(year.value, month.value));
        };

        const renderElements = () => {
            return (
                <div className="row">
                    <div className="form-group form-group-month">
                        <select name="month" className="form-control form-control-select-month header-month-year-select" onChange={handleChange} value={date.getMonth()}>
                            {months.map((month, i) => (
                                <option key={month} value={i}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group form-group-year">
                        <select name="year" className="form-control form-control-select-year header-month-year-select" onChange={handleChange} value={date.getFullYear()}>
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            );
        };

        if (this.formMode) {
            return (
                <div className="DayPicker-Caption form-inline">
                    {renderElements()}
                </div>
            );
        } else {
            return (
                <form className="DayPicker-Caption form-inline">
                    {renderElements()}
                </form>
            );
        }
    }

    onChange(date) {
        this.setState({'curValue': undefined});
        if (this.formMode) {
            this.props.input.onChange(moment(date).format(DATE_FORMATS.DATE));
        } else {
            this.props.onChange(moment(date).format(DATE_FORMATS.DATE));
        }
    }

    render() {
        let initDate = undefined;
        if (this.state.curValue) {
            initDate = moment(this.state.curValue, DATE_FORMATS.DATE);
        } else {
            if (this.formMode) {
                initDate = this.props.input.value ? moment(this.props.input.value, DATE_FORMATS.DATE) : undefined;
            } else {
                initDate = this.props.value ? moment(this.props.value, DATE_FORMATS.DATE) : undefined;
            }
        }
        if (this.props.disabled) {
            return (
                <div className="font-roboto row align-items-center form-date-disabled-input-group">
                    <input
                        className="form-control font-roboto form-transparent-input"
                        {...this.props }
                        disabled={true}
                        value={initDate ? `${formatDate(initDate, 'LL', 'ru')}`: ''}
                    />
                </div>
            );
        }

        if (!_.get(this.props, "use_day_shifter", false)) {
            return this.renderSimple(initDate)
        }
        return this.renderWithDayShifter(initDate, this.props.tooltip_key)
    }

    renderSimple(initDate) {
        const component = this.ShowIcon ? DatePickerInputComponent : PureDatePickerInputComponent;
        return (
            <div className="font-roboto">
                <DayPickerInput
                    {...this.props}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="LL"
                    value={initDate ? `${formatDate(initDate, 'LL', 'ru')}`: ''}
                    placeholder={this.props.placeholder}
                    // placeholder={`${formatDate(new Date(), 'LL', 'ru')}`}
                    onDayChange={this.onChange}
                    dayPickerProps={{
                        selected: initDate,
                        locale: 'ru',
                        localeUtils: MomentLocaleUtils,
                        month: this.state.month,
                        fromMonth: fromMonth,
                        toMonth: toMonth,
                        captionElement: ({ date, localeUtils }) => (
                            <this.YearMonthForm
                                date={date}
                                localeUtils={localeUtils}
                                onChange={this.handleYearMonthChange}
                            />
                        ),
                        todayButton: "Сегодня",
                    }}
                    component={component}
                />
                {this.props.meta && this.props.meta.touched && this.props.meta.error && <span className="badge badge-danger">{this.props.meta.error}</span>}
                {this.props.meta && this.props.meta.touched && this.props.meta.warning && <span className="badge badge-warning">{this.props.meta.warning}</span>}
            </div>
        );
    }

    static calcDate(anchorValue, delta, useBusiness) {
        let newVal = moment(anchorValue, DATE_FORMATS.DATE);
        if (useBusiness) {
            let curDelta = 0;
            while (curDelta < delta) {
                newVal = newVal.add(1, 'days');
                // todo: дополнительно проверить по праздникам
                if (HOLIDAYS_CALENDAR.holidays.includes(newVal.format(DATE_FORMATS.HOLIDAYS))) {
                    // праздничные дни пропускаем
                    continue;
                }
                if (!newVal.isBusinessDay() && !HOLIDAYS_CALENDAR.potentially_workdays.includes(newVal.format(DATE_FORMATS.HOLIDAYS))) {
                    // так же пропусткаем выходные, если это не переносы рабочих дней
                    continue;
                }
                curDelta++;
            }
        } else {
            newVal = moment(anchorValue, DATE_FORMATS.DATE).add(delta, 'days');
        }
        return newVal;
    }

    renderWithDayShifter(initDate, tooltip_key) {
        const component = this.ShowIcon ? DatePickerInputComponent : PureDatePickerInputComponent;
        const tooltipId = `${tooltip_key}_tooltip`;
        return (
            <div className="font-roboto row input-group">
                <div className="col-sm-7 form-date-input-group input-group mb-3">
                    <DayPickerInput
                        {...this.props}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="LL"
                        value={initDate ? `${formatDate(initDate, 'LL', 'ru')}`: ''}
                        placeholder={this.props.placeholder}
                        // placeholder={`${formatDate(new Date(), 'LL', 'ru')}`}
                        onDayChange={this.onChange}
                        dayPickerProps={{
                            selected: initDate,
                            locale: 'ru',
                            localeUtils: MomentLocaleUtils,
                            month: this.state.month,
                            fromMonth: fromMonth,
                            toMonth: toMonth,
                            captionElement: ({ date, localeUtils }) => (
                                <this.YearMonthForm
                                    date={date}
                                    localeUtils={localeUtils}
                                    onChange={this.handleYearMonthChange}
                                />
                            ),
                            todayButton: "Сегодня",
                        }}
                        component={component}
                    />
                </div>

                <div className="col-sm-5 form-time-input-group input-group mb-3">
                    <input
                        placeholder={_.get(this.props, "day_shifter_placeholder")}
                        onChange={(e)=>{
                            if (!this.state.anchorValue) {
                                console.warn('Warning: field does not provide anchor value');
                                return;
                            }
                            const delta = Number(e.target.value);
                            const newVal = DatePickerInput.calcDate(this.state.anchorValue, delta, this.state.useBusinessDay);
                            this.setState({
                                'curValue': newVal.format(DATE_FORMATS.DATE),
                                'delta': delta,
                            });
                            this.onChange(newVal);
                        }}
                        className="form-control form-control-sm font-roboto"
                        type="number"
                        min="0"
                        max="1000"
                        step="1"
                    />
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <input
                                type="checkbox"
                                aria-label="Checkbox for following text input"
                                onChange={(e)=> {
                                    if (!this.state.anchorValue) {
                                        console.warn('Warning: field does not provide anchor value');
                                        return;
                                    }
                                    const delta = this.state.delta;
                                    const useBusiness = !this.state.useBusinessDay;
                                    const newVal = DatePickerInput.calcDate(this.state.anchorValue, delta, useBusiness);
                                    this.setState({
                                        'curValue': newVal.format(DATE_FORMATS.DATE),
                                        useBusinessDay: useBusiness,
                                    });
                                    this.onChange(newVal);
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-group-append form-date-input-append" id={tooltipId} onClick={this.toggle}>
                        <FontAwesomeIcon icon={faQuestionCircle}/>
                    </div>
                    <Popover placement="left" isOpen={this.state.popoverOpen} target={tooltipId} toggle={this.toggle}>
                        <PopoverHeader>{"Рабочие/кладендарные дни"}</PopoverHeader>
                        <PopoverBody>{"Если галочка отмечена, будут использоваться рабочие дни. В противном случае - календарные"}</PopoverBody>
                    </Popover>
                </div>
                <div className="input-group form-date-input-error-group">
                    {this.props.meta && this.props.meta.touched && this.props.meta.error && <span className="badge badge-danger">{this.props.meta.error}</span>}
                    {this.props.meta && this.props.meta.touched && this.props.meta.warning && <span className="badge badge-warning">{this.props.meta.warning}</span>}
                </div>
            </div>
        );
    }
}
