// strings should be unique across reducers so namespace them with the reducer name
export const ADMIN_USER_PAGE_CHANGED = 'admin_users.PAGE_CHANGED';
export const ADMIN_USER_SELECTED = 'admin_users.SELECTED';
export const ADMIN_USER_MODE_CREATE = 'admin_users.MODE_CREATE';
export const ADMIN_USER_MODE_EDIT = 'admin_users.MODE_EDIT';

export const ADMIN_USER_BEGIN_LOADING = 'admin_users.BEGIN_LOADING';
export const ADMIN_USER_GET_DONE = 'admin_users.GET_DONE';
export const ADMIN_USER_GET_ERROR = 'admin_users.GET_ERROR';

export const ADMIN_USER_CREATE_DONE = 'admin_users.CREATE_DONE';
export const ADMIN_USER_CREATE_ERROR = 'admin_users.CREATE_ERROR';

export const ADMIN_USER_EDIT_DONE = 'admin_users.EDIT_DONE';
export const ADMIN_USER_EDIT_ERROR = 'admin_users.EDIT_ERROR';

export const ADMIN_USER_DELETE_DONE = 'admin_users.USER_DELETE_DONE';
export const ADMIN_USER_DELETE_ERROR = 'admin_users.DELETE_ERROR';

export const ADMIN_USER_FILTER_CHANGED = 'admin_users.FILTER_CHANGED';
export const ADMIN_USER_DISMISS_ALL_ERRORS = 'admin_users.DISMISS_ALL_ERRORS';
