// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import _ from 'lodash';
import BackendSession from '../globals/session';
import {BackendServiceCommon} from "./serverCommon"

const SUB_PATH = 'ppe';
const SINGLE_NAME = 'ППЭ';
const LIST_NAME = 'ППЭ';

class BackendServicesPpe extends BackendServiceCommon{
    exportUrl(filters, fields) {
        let filtersParams = [];
        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                filtersParams.push({field_name: filters[i].id, field_value: filters[i].value})
            }
        }
        const filtersRequestParam = JSON.stringify(filtersParams);
        const fieldsRequestParam = JSON.stringify(fields);
        return `${this.backEndPoint}export/ppe?filters=${filtersRequestParam}&fields=${fieldsRequestParam}&token=${BackendSession.token}`;
    }

    certUrl(ppeId) {
        return `${this.backEndPoint}${SUB_PATH}/${ppeId}/create-cert?token=${BackendSession.token}`;
    }

    static getBodyFromElement(element) {
        const keys = {
            ppe: 'ppe',
            district: 'district',
            name: 'name',
            address: 'address',
        };
        const numberFields = [];
        let res = {};
        for (let k in keys) {
            // добавим поля в данные - кроме файловых полей
            if (!keys.hasOwnProperty(k)) { continue }
            if (element[keys[k]] === undefined || element[keys[k]] === null) { continue }
            if (typeof element[keys[k]] === "string" && !element[keys[k]]) { continue }
            if (numberFields.includes(k)) {
                res[k] = Number(element[keys[k]])
            } else {
                res[k] = element[keys[k]]
            }
        }
        return res;
    }

    static getObjectFromResponseData(respData) {
        return {
            ID: _.get(respData, 'id'),
            Ppe: _.get(respData, 'ppe'),
            District: _.get(respData, 'district'),
            Name: _.get(respData, 'name'),
            Address: _.get(respData, 'address'),

            ServiceAction: _.get(respData, 'service_actions'),
        }
    }

    async getList(archive = false, searchQuery = undefined) {
        // console.time('getListFromBack');
        const searchParam = searchQuery ? `&search=${searchQuery}` : '';
        const archiveParam = archive ? '/archive' : '';
        const url = `${this.backEndPoint}${SUB_PATH}${archiveParam}?token=${BackendSession.token}${searchParam}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
            mode: 'cors'
        });
        if (!response.ok) {
            throw new Error(`Ошибка получения списка ${LIST_NAME}, HTTP status ${response.status}`);
        }

        const data = await response.json();
        // console.timeEnd('getListFromBack');

        // console.time('getListFrontProcessing');
        // let result = _.map(data, (generalData) => {
        //     // в общем случае мы должны абстрагироваться от специфики структуры даннхы, которых мы получили,
        //     // здесь формируем объект, с которым нам потом будет удобно работать
        //     return BackendServicesPpe.getMainObjectFromResponseData(generalData);
        // });
        // console.timeEnd('getListFrontProcessing');
        // return result;

        return _.map(data, (generalData) => {
            // в общем случае мы должны абстрагироваться от специфики структуры даннхы, которых мы получили,
            // здесь формируем объект, с которым нам потом будет удобно работать
            return BackendServicesPpe.getObjectFromResponseData(generalData);
        });
    }

    async create(element) {
        const url = `${this.backEndPoint}${SUB_PATH}?token=${BackendSession.token}`;
        const data = new FormData();
        data.append('body', JSON.stringify(BackendServicesPpe.getBodyFromElement(element)));
        // const PreparedElement = BackendServicesPpe.getBodyFromElement(element);
        // for (let k in PreparedElement) {
        //     // добавим поля в данные - кроме файловых полей
        //     if (PreparedElement.hasOwnProperty(k)) {
        //         if(['files_act', 'files_att_doc', 'files_doc'].indexOf(k) === -1) {
        //             data.append(k, PreparedElement[k]);
        //         }
        //     }
        // }
        // докинем файлы до кучи
        if (element.files_kp) {
            for (let i = 0; i < element.files_kp.length; i++) {
                data.append('files_kp[]', element.files_kp[i]);
            }
        }
        if (element.files_doc) {
            for (let i = 0; i < element.files_doc.length; i++) {
                data.append('files_doc[]', element.files_doc[i]);
            }
        }

        const rawResponse = await fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка создания ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        const content = await rawResponse.json();
        return BackendServicesPpe.getObjectFromResponseData(content);
    }

    async edit(id, element) {
        const url = `${this.backEndPoint}${SUB_PATH}/${id}?token=${BackendSession.token}`;
        const data = new FormData();
        data.append('body', JSON.stringify(BackendServicesPpe.getBodyFromElement(element)));
        // const PreparedElement = BackendServicesPpe.getBodyFromElement(element);
        // for (let k in PreparedElement) {
        //     // добавим поля в данные - кроме файловых полей
        //     if (PreparedElement.hasOwnProperty(k)) {
        //         if(['files_act', 'files_att_doc', 'files_doc'].indexOf(k) === -1) {
        //             data.append(k, PreparedElement[k]);
        //         }
        //     }
        // }
        // докинем файлы до кучи
        if (element.files_kp) {
            for (let i = 0; i < element.files_kp.length; i++) {
                data.append('files_kp[]', element.files_kp[i]);
            }
        }
        if (element.files_doc) {
            for (let i = 0; i < element.files_doc.length; i++) {
                data.append('files_doc[]', element.files_doc[i]);
            }
        }

        const rawResponse = await fetch(url, {
            method: 'PUT',
            body: data,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка изменения ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        const content = await rawResponse.json();
        //true/false в зависимости от того, что как там сервер свалидировал
        return BackendServicesPpe.getObjectFromResponseData(content);
    }

    async delete(id) {
        const url = `${this.backEndPoint}${SUB_PATH}/${id}?token=${BackendSession.token}`;
        const rawResponse = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка удаления ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        await rawResponse.json();
    }
}

export default new BackendServicesPpe();
