// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import './StreamsScreen.css';
import React from 'react';
import autoBind from 'react-autobind';
import {Alert} from 'reactstrap';
import {connect} from 'react-redux';
import {
    faDownload, faVideoSlash, faUnlink
} from "@fortawesome/free-solid-svg-icons";
import * as configs from '../globals/config';
import * as actions from '../store/streams/actions';
import * as selectors from '../store/streams/reducer';
import PagingView from '../components/PagingView';
import ToolBarView from '../components/ToolBarView';
import ReactTableView from '../components/ReactTableView'
import {
    calcAvailability,
    checkFiltersEqual,
    checkSortsEqual, humanFileSize
} from '../globals/functions'
import {GeneralContainer, mapGeneralStateToProps} from "./GeneralContainer";

class StreamsScreen extends GeneralContainer {

    constructor(props) {
        super(props);
        autoBind(this);
        this.sectionName = configs.SECTIONS_NAMES.streams;
        this.filterTimer = 0;
        this.updateTimerId = undefined;
        this.tableSettingsTitle = "настроек таблицы потоков";

        // Колонку состояния исключаем из набора в соотвествии с ТЗ
        this.allColumns = [{
            Header: 'ППЭ',
            accessor: 'Ppe',
            width: 75,
            style: {textAlign: 'center'},
            Cell: row => {
                return (
                    <a href={row.value.link} target="_blank" rel="noopener noreferrer">{row.value.number}</a>
                );
            },
        }, {
            Header: 'Район, название',
            accessor: 'NameForTable',
            // width: 85,
            sortable: false,
            style: {textAlign: 'left'},
            Cell: row => {
                return (
                    <div>
                        <span>Район: {row.value.district}</span><br/>
                        <span>Название: {row.value.name}</span>
                    </div>
                );
            }
        }, {
            Header: 'Доступы',
            accessor: 'AccessDetails',
            filterable: false,
            sortable: false,
            width: 120,
            style: {textAlign: 'left'},
            Cell: row => {
                return (
                    <div>
                        <span>VPN: {row.value.vpn}</span><br/>
                        <span className="cellSmallInfo">IP: {row.value.ip}</span>
                    </div>
                );
            }
        }, {Header: 'Всего кам.',
            accessor: 'CamsTotalCount',
            width: 85,
            filterable: false,
            style: {textAlign: 'center'},
        }, {
            Header: 'В сети',
            accessor: 'OnlineForTable',
            width: 110,
            sortable: false,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value.online) {
                    return (
                        <div className="status-green">
                            <span>Online</span><br/>
                            <span className="cellSmallInfo">{row.value.lastSeen}</span>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Offline</span><br/>
                        <span className="cellSmallInfo">{row.value.lastSeen}</span>
                    </div>
                );
            }
        }, {
            Header: 'Интернет',
            accessor: 'AvsHasInternet',
            width: 70,
            sortable: false,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value) {
                    return (
                        <div className="status-green">
                            <span>Да</span>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Нет</span>
                    </div>
                );
            }
        }, {
            Header: 'Порты',
            accessor: 'AvsPushPortsAvailable',
            width: 70,
            sortable: false,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value) {
                    return (
                        <div className="status-green">
                            <span>Да</span>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Нет</span>
                    </div>
                );
            }
        }, {
            Header: 'SSH',
            accessor: 'SshForTable',
            sortable: false,
            width: 70,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value) {
                    return (
                        <div className="status-green">
                            <span>Online</span>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Offline</span>
                    </div>
                );
            }
            // Filter: ({filter, onChange}) => {
            //     const id = "ImplementationTimeFilter";
            //     const curVal = filter ? filter.value : configs.FILTER_PLACEHOLDER;
            //     return (
            //         <div className="dropdown top-element">
            //             <Fragment>{this.renderDateFilterBtn(id, curVal)}</Fragment>
            //             <Fragment>{this.renderDateFilterElement(id, onChange, filter)}</Fragment>
            //         </div>
            //     );
            // },
            // Cell: row => (
            //     StreamsScreen.renderImplementationDate(row.value)
            // )
        }, {Header: 'AVS',
            accessor: 'Avs',
            width: 70,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value.installed) {
                    return (
                        <div className="status-green">
                            {row.value.version}
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        {row.value.version}
                    </div>
                );
            }
        }, {Header: 'HDD',
            accessor: 'SysFreeSpace',
            filterable: false,
            width: 85,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value > configs.HDD_MIN_LIMIT) {
                    return (
                        <div className="status-green">
                            <span>{humanFileSize(row.value, true)}</span>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>{humanFileSize(row.value, true)}</span>
                    </div>
                );
            }
        },
        ];
        this.initLabelsAndNames();
    }

    initLoadingByTimer = () => {
        if (this.props.viewMode === configs.VIEW_MODES.STREAMS_CAL) {
            this.props.dispatch(actions.Get(true));
            // this.props.dispatch(actions.BeginLoadingCalendar());
            // setTimeout(()=>{
            //     this.props.dispatch(actions.Get(true));
            // }, 333);
        } else {
            this.props.dispatch(actions.Get(false));
            // this.props.dispatch(actions.BeginLoadingAll());
            // setTimeout(()=>{
            //     this.props.dispatch(actions.Get(false));
            // }, 333);
        }
    };

    componentWillUnmount(): void {
        if (this.updateTimerId) {
            clearTimeout(this.updateTimerId);
        }
        actions.CloseSse();
    }

    componentDidMount() {
        super.componentDidMount();
        // списки грузим всегда - ибо не подгрузятся иначе изменения сделанные в других разделах, например новый клиент
        this.props.dispatch(actions.BeginLoadingAll());
        this.props.dispatch(actions.Get());
        this.props.dispatch(actions.StartSse());

        let that = this;
        if (this.updateTimerId) {
            clearTimeout(this.updateTimerId);
        }
        this.updateTimerId = setTimeout(function tick() {
            that.initLoadingByTimer();
            that.updateTimerId = setTimeout(tick, 10000);
        }, 10000);
    }

    renderTable = (fetchError, data, pageSize, loading, height) => {
        return (
            <ReactTableView
                columns={[
                    {
                        Header: 'ППЭ',
                        accessor: 'Ppe',
                        width: 85,
                        resizable: false,
                        style: {textAlign: 'center'},
                        Cell: row => {
                            return (
                                <a href={row.value.link} target="_blank" rel="noopener noreferrer">{row.value.number}</a>
                            );
                        }
                    },
                    {
                        Header: 'Камера',
                        accessor: 'ID',
                        resizable: false,
                        width: 90,
                        // style: {textAlign: 'center'},
                    },
                    {
                        Header: 'Описание',
                        accessor: 'Reason',
                        resizable: false,
                        // width: 140,
                        // style: {textAlign: 'center'},
                    },
                ]}
                noDataText={"Нет данных!"}
                sortable={false}
                filterable={false}
                style={height}
                defaultFilter={[]}
                defaultSort={[]}
                fetchError={fetchError}
                data={data}
                pageSize={pageSize}
                loading={loading}
                // onFetchData={this.onReactTableFetch}
                onRowClick={() => {}}
                onRowDoubleClick={() => {}}
                curProdId={-1}
                dismissErrorAlert={this.dismissErrorAlert}
                contextMenuItems = {[]}
            />
        );
    };
    renderToolBar = (title, icon) => {
        return (
            <ToolBarView
                groups={[{
                    key: 1,
                    className: 'col-12',
                    elements: [{
                        type: configs.TOOLBAR_ELEMENT_TYPES.LABEL,
                        text: title,
                        color: 'danger',
                        icon: icon,
                    }]
                }]}
            />
        );
    };


    renderSubTables() {
        if (this.props.viewMode !== configs.VIEW_MODES.STREAMS_CAL) {
            return;
        }

        return (
            <div className="col double-table-container">
                <div className="w-100 double-table-container-half">
                    {this.renderToolBar("Проблемы с записью", faVideoSlash)}
                    {this.renderTable(
                        this.props.recOffElsFetchError,
                        this.props.recOffElsForTable,
                        Math.max(this.props.recOffElsTotalCount, 8),
                        this.props.recOffElsLoading,
                        {"height": "calc(100% - 40px)"}
                    )}
                </div>

                <div className="w-100 double-table-container-half">
                    {this.renderToolBar("Проблемы с трансляцией", faUnlink)}
                    {this.renderTable(
                        this.props.badStreamsElsFetchError,
                        this.props.badStreamsElsForTable,
                        Math.max(this.props.badStreamsElsTotalCount, 8),
                        this.props.badStreamsElsLoading,
                        {"height": "100%"}
                    )}
                </div>
            </div>
        );
    }

    renderRecTable() {
        if (this.props.viewMode !== configs.VIEW_MODES.STREAMS_CAL) {
            return;
        }
        return (
            <div className="col-3 double-table-container">
                {this.renderToolBar("Проблемы с записью", faVideoSlash)}
                {this.renderTable(
                    this.props.recOffElsFetchError,
                    this.props.recOffElsForTable,
                    Math.max(this.props.recOffElsTotalCount, 19),
                    this.props.recOffElsLoading,
                    {"height": "100%"}
                )}
            </div>
        );
    }

    renderRelayTable() {
        if (this.props.viewMode !== configs.VIEW_MODES.STREAMS_CAL) {
            return;
        }

        return (
            <div className="col-3 double-table-container">
                {this.renderToolBar("Проблемы с трансляцией", faUnlink)}
                {this.renderTable(
                    this.props.badStreamsElsFetchError,
                    this.props.badStreamsElsForTable,
                    Math.max(this.props.badStreamsElsTotalCount, 19),
                    this.props.badStreamsElsLoading,
                    {"height": "100%"}
                )}
            </div>
        );
    }

    renderMainTable() {
        const fullScreen = this.props.viewMode !== configs.VIEW_MODES.STREAMS_CAL;
        const colClass = fullScreen ? "col-12" : "col-6";
        return(
            <div className={`${colClass} double-table-container`}>
                <ToolBarView
                    groups={this.getToolbarGroups()}
                />
                <ReactTableView
                    style={{"height": "100%"}}
                    columns={this.getTableColumns()}
                    hideFilters={() => {
                        this.hideAllFilters(true)
                    }}
                    // sortable={true}
                    defaultFilter={this.getFilters()}
                    defaultSort={this.getSorts()}
                    fetchError={this.props.elementsFetchError}
                    data={this.props.elementsForTable}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    loading={this.props.elementsLoading}
                    onFetchData={this.onReactTableFetch}
                    onRowClick={this.onTableRowClick}
                    onRowDoubleClick={this.onTableRowDoubleClick}
                    curProdId={this.props.currentElementId}
                    dismissErrorAlert={this.dismissErrorAlert}
                    onContextMenu = {this.onTableRowClick}
                    contextMenuItems = {[]}
                />
                {this.props.elementDeleteError ?
                    <Alert color="danger" isOpen={true} toggle={this.dismissErrorAlert}>
                        Ошибка удаления: {this.props.elementDeleteError.message}!
                    </Alert> : ''
                }
                {this.renderContextInfoView()}
                <div className="mb-2"/>
                <PagingView
                    totalCount={this.props.elementsTotalCount}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    curPageNumber={this.props.currentPageNumber}
                    onPageChange={this.onPageChange}
                    onSettingsClick={this.onSettingsClick}
                />
            </div>
        );
    }
    renderTables() {
        return (
            <div className="row double-table-root-container">
                {this.renderMainTable()}
                {this.renderRecTable()}
                {this.renderRelayTable()}
                {/*{this.renderSubTables()}*/}
            </div>
        );
    }

    render() {
        return (
            <div className="w-100 h-100 font-roboto">
                {this.renderSettings()}
                {this.renderNavigationBarView(1)}
                {this.renderTables()}
            </div>
        );
    }

    getSorts() {
        return [];
    }

    getFilters() {
        return [];
    }

    onPageChange(pageNumber) {
        this.props.dispatch(actions.ChangePage(pageNumber))
    }

    onReactTableFetch(state, instance) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(()=>{
            if (!checkFiltersEqual(this.props.curFilters, state.filtered) || !checkSortsEqual(this.props.curSorts, state.sorted)) {
                this.props.dispatch(actions.Filter(state.filtered, state.sorted));
            }
        }, configs.FILTER_TIMEOUT);
    }

    onTableRowClick(id) {
        this.props.dispatch(actions.Select(id));
    }

    onTableRowDoubleClick(id) {
    }

    dismissErrorAlert() {
        this.props.dispatch(actions.DismissErrorAlert())
    }

    changeModeToCalendar() {
        if (this.props.viewMode === configs.VIEW_MODES.STREAMS_CAL) {
            return
        }
        this.props.dispatch(actions.BeginLoadingCalendar());
        this.props.dispatch(actions.Get(true));
    }

    changeModeToAll() {
        if (this.props.viewMode === configs.VIEW_MODES.STREAMS_ALL) {
            return
        }
        this.props.dispatch(actions.BeginLoadingAll());
        this.props.dispatch(actions.Get(false));
    }

    getToolbarGroups() {
        const fields = this.getFields();
        return [
            {
                key: 1,
                className: 'col-12',
                elements: [
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON_SWITCH,
                        key: 21,
                        color: 'normal',
                        icon: undefined,
                        disabled: false,
                        labelLeft: 'ОТОБРАЖАТЬ ВСЕ',
                        labelRight: 'ФИЛЬТР ПО КАЛЕНДАРЮ',
                        leftActiveClass: 'main-text',
                        rightActiveClass: 'main-text',
                        onColor: '#86d3ff',
                        onHandleColor: '#2693e6',
                        leftAligned: true,
                        // onChange: (checked, event, id) => {
                        onChange: (checked) => {
                            if (checked) {
                                this.changeModeToCalendar();
                            } else {
                                this.changeModeToAll();
                            }
                        },
                        checked: this.props.viewMode !== configs.VIEW_MODES.STREAMS_ALL,
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.LINK_DOWNLOAD,
                        color: 'normal',
                        href: actions.exportUrl(this.props.viewMode === configs.VIEW_MODES.STREAMS_CAL, this.props.curFilters, fields),
                        text: 'Выгрузить потоки',
                        hint: '',
                        icon: faDownload,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EXPORT, this.props.currentElementId),
                        onClick: (e) => {
                            e.target.blur();
                        }
                    },
                ]
            },
        ];
    }
}

// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    let [tblMainEls, tblMainElsTotalCount] = selectors.getMainElementsForTable(state, GeneralContainer.getTableRowCountLimit(configs.SECTIONS_NAMES.streams));
    let [tblRecOffEls, tblRecOffElsTotalCount] = selectors.getRecOffElementsForTable(state);
    let [tblBadStreamsEls, tblBadStreamsElsTotalCount] = selectors.getBadStreamsElementsForTable(state);
    return {
        ...mapGeneralStateToProps(state), ...{
            currentElementId: selectors.getCurrentElementId(state),
            currentElement: selectors.getCurrentElement(state),
            elementsFetchError: selectors.getFetchError(state),
            recOffElsFetchError: selectors.getRecOffFetchError(state),
            viewMode: selectors.getViewMode(state),
            elementsForTable: tblMainEls,
            elementsTotalCount: tblMainElsTotalCount,
            recOffElsForTable: tblRecOffEls,
            recOffElsTotalCount: tblRecOffElsTotalCount,
            badStreamsElsForTable: tblBadStreamsEls,
            badStreamsElsTotalCount: tblBadStreamsElsTotalCount,
            elementsLoading: selectors.isElementsLoading(state),
            recOffElsLoading: selectors.isRecOfElsLoading(state),
            badStreamsElsLoading: selectors.isBadStreamsElsLoading(state),
            currentPageNumber: selectors.getCurrentPageNumber(state),
            curFilters: selectors.getCurrentFilters(state),
            curSorts: selectors.getCurrentSorts(state),
        }
    };
}

export default connect(mapStateToProps)(StreamsScreen);
