// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import * as config from '../../globals/config'
import {filterMonitoringAstral, sortMonitoringAstral} from '../../globals/functions'

const initialState = Immutable({

    recOffEls: undefined,
    recOffElsIds: undefined,
    recOffElsFetchError: undefined,
    recOffElsLoading: true,
    badStreamsEls: undefined,
    badStreamsElsIds: undefined,
    badStreamsElsFetchError: undefined,
    badStreamsElsLoading: true,

    elements: undefined,
    elementsIds: undefined,
    elementsCalendar: undefined,
    elementsIdsCalendar: undefined,
    elementFetchError: undefined,
    currentPageNumber: 0,
    currentElementId: undefined,
    viewMode: config.VIEW_MODES.STREAMS_ALL,
    elementsLoading: true,
    elementsFilters: undefined,
    elementsSorted: undefined,
});

const dismissAllErrorsState = {
    elementFetchError: false,
    recOffElsFetchError: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.STREAMS_SSE_BAD_RECORDS_RECEIVED:
            return state.merge({
                recOffElsLoading: false,
                recOffEls: action.result.elements,
                recOffElsIds: action.result.elementsIds,
                recOffElsFetchError: undefined,
            });
        case types.STREAMS_SSE_BAD_STREAMS_RECEIVED:
            return state.merge({
                badStreamsElsLoading: false,
                badStreamsEls: action.result.elements,
                badStreamsElsIds: action.result.elementsIds,
                badStreamsElsFetchError: undefined,
            });
        case types.STREAMS_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.STREAMS_BEGIN_LOADING_ALL:
            return state.merge({
                elementsLoading: true,
                viewMode: config.VIEW_MODES.STREAMS_ALL,
            });
        case types.STREAMS_BEGIN_LOADING_CAL:
            return state.merge({
                elementsLoading: true,
                viewMode: config.VIEW_MODES.STREAMS_CAL,
            });
        case types.STREAMS_GET_DONE_ALL:
            return state.merge({
                elementsLoading: false,
                elements: action.result.elements,
                elementsIds: action.result.elementsIds,
                elementFetchError: undefined,
                viewMode: config.VIEW_MODES.STREAMS_ALL,
            });
        case types.STREAMS_GET_DONE_CAL:
            return state.merge({
                elementsLoading: false,
                elementsCalendar: action.result.elements,
                elementsIdsCalendar: action.result.elementsIds,
                elementFetchError: undefined,
                viewMode: config.VIEW_MODES.STREAMS_CAL,
            });
        case types.STREAMS_GET_ERROR:
            return state.merge({
                elementsLoading: false,
                elements: {},
                elementsIds: [],
                elementsCalendar: {},
                elementsIdsCalendar: [],
                elementFetchError: action.error
            });

        case types.STREAMS_SELECTED:
            return state.merge({
                currentElementId: action.currentElementId
            });
        case types.STREAMS_PAGE_CHANGED:
            return state.merge({
                currentPageNumber: action.pageNumber,
                currentElementId: undefined,
            });
        case types.STREAMS_FILTER_CHANGED:
            return state.merge({
                elementsFilters: action.filters ? action.filters : undefined,
                elementsSorted: action.sorted ? action.sorted : undefined,
                currentPageNumber: 0,
                currentElementId: undefined,
            });
        default:
            return state;
    }
}

// selectors

export function getMainElementsForTable(state, limit) {
    let srcElIds = state.streams.elementsIds;
    let srcEls = state.streams.elements;
    if (state.streams.viewMode === config.VIEW_MODES.STREAMS_CAL) {
        srcElIds = state.streams.elementsIdsCalendar;
        srcEls = state.streams.elementsCalendar;
    }
    if (!srcElIds) {
        return [[], 0];
    }
    const from = state.streams.currentPageNumber * limit;
    const to = from + limit;
    let filteredElementsIds = [];
    let filteredElements = {};
    if (state.streams.elementsFilters && state.streams.elementsFilters.length > 0) {
        for (let i = 0; i < srcElIds.length; i++) {
            const id = srcElIds[i];
            if (filterMonitoringAstral(state.streams.elementsFilters, srcEls[id])) {
                filteredElementsIds.push(id);
                filteredElements[id] = srcEls[id];
            }
        }
    } else {
        filteredElementsIds = srcElIds;
        filteredElements = srcEls;
    }
    let outIds, outEl;
    if (state.streams.elementsSorted && state.streams.elementsSorted.length > 0) {
        let filterDirections = [];
        for (let i = 0; i < state.streams.elementsSorted.length; i++) {
            filterDirections.push(state.streams.elementsSorted[i].desc ? 'desc' : 'asc')
        }
        [outIds, outEl] = sortMonitoringAstral(_.keyBy(filteredElements, (element) => element.ID), state.streams.elementsSorted, filterDirections);
    } else {
        [outIds, outEl] = sortMonitoringAstral(_.keyBy(filteredElements, (element) => element.ID));
    }

    const elementsIds = outIds.slice(from, to);
    let res = [];
    for (let i = 0; i < elementsIds.length; i++) {
        res.push(_.get(outEl, elementsIds[i]));
    }
    return [res, filteredElementsIds.length];
}

export function getRecOffElementsForTable(state) {
    if (!state.streams.recOffElsIds) {
        return[[], 0];
    }
    let res = [];
    for (let i = 0; i < state.streams.recOffElsIds.length; i++) {
        res.push(_.get(state.streams.recOffEls, state.streams.recOffElsIds[i]));
    }
    return [res, state.streams.recOffElsIds.length];
}

export function getBadStreamsElementsForTable(state) {
    if (!state.streams.badStreamsElsIds) {
        return[[], 0];
    }
    let res = [];
    for (let i = 0; i < state.streams.badStreamsElsIds.length; i++) {
        res.push(_.get(state.streams.badStreamsEls, state.streams.badStreamsElsIds[i]));
    }
    return [res, state.streams.badStreamsElsIds.length];
}

export function isElementsLoading(state) {
    return state.streams.elementsLoading;
}

export function isRecOfElsLoading(state) {
    return state.streams.recOffElsLoading;
}

export function isBadStreamsElsLoading(state) {
    return state.streams.badStreamsElsLoading;
}

export function getCurrentElementId(state) {
    return state.streams.currentElementId;
}

export function getCurrentElement(state) {
    if (state.streams.viewMode === config.VIEW_MODES.STREAMS_ALL) {
        return _.get(state.streams.elements, state.streams.currentElementId);
    }
    return _.get(state.streams.elementsCalendar, state.streams.currentElementId);
}

export function getCurrentPageNumber(state) {
    return state.streams.currentPageNumber;
}

export function getFetchError(state) {
    return state.streams.elementFetchError;
}

export function getRecOffFetchError(state) {
    return state.streams.recOffElsFetchError;
}

export function getBadStreamsFetchError(state) {
    return state.streams.badStreamsElsFetchError;
}

export function getViewMode(state) {
    return state.streams.viewMode;
}

export function getCurrentFilters(state) {
    return state.streams.elementsFilters;
}

export function getCurrentSorts(state) {
    return state.streams.elementsSorted;
}