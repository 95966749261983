import React, { Component } from 'react'
import autoBind from "react-autobind";
import reactCSS from 'reactcss'
import { SwatchesPicker } from 'react-color';
import './ColorPickerInput.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/free-solid-svg-icons";


export default class ColorPickerInput extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            displayColorPicker: false,
        };
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ displayColorPicker: false });
        this.props.input.onChange(color.hex);
    };

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '100%',
                    height: '100%',
                    borderRadius: '2px',
                    background: `${this.props.input.value}`,
                },
                swatch: {
                    // width: '100%',
                    width: "calc(100% - 36px)",
                    // height: "30px",
                    // padding: '5px',
                    marginLeft: '15px',
                    paddingBottom: '0px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    paddingTop: '5px',
                    background: `${this.props.input.value}`,
                    // background: `transparent`,
                    borderRadius: '2px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className="font-roboto row form-color-picker-input-group">
                <div
                    style={ styles.swatch }
                    onClick={ this.handleClick }
                >
                    <input
                        {...this.props}
                        readOnly={true}
                        style={ styles.color }
                        // className="form-control form-control-sm font-roboto form-transparent-no-border-input"
                        className="form-transparent-no-border-input"
                    />
                </div>
                <div className="input-group-append form-date-input-append">
                    <FontAwesomeIcon icon={faPalette} />
                </div>

                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                    <SwatchesPicker color={ this.state.color } onChange={ this.handleChange } />
                </div> : null }

                {this.props.meta.touched && this.props.meta.error && <span className="badge badge-danger">{this.props.meta.error}</span>}
                {this.props.meta.touched && this.props.meta.warning && <span className="badge badge-warning">{this.props.meta.warning}</span>}
            </div>
        );
    }
}
