import _ from 'lodash'


class BackendSession {
    constructor() {
        this.token = undefined;
        this.userId = undefined;
        this.userName = undefined;
        this.userLogin = undefined;
        this.role = undefined;
        this.rolePrivileges = undefined;
    }

    authorizeRead(sectionName) {
        // для чтения проверяем 0-й или 1-й бит (запись гарантирует чтение)
        const privilegeValue = _.get(this.rolePrivileges, sectionName, 0);
        return ((privilegeValue & 0x1) > 0)
    }

    authorizeWrite(sectionName) {
        const privilegeValue = _.get(this.rolePrivileges, sectionName, 0);
        return ((privilegeValue & 0x3) > 0)
    }

    authorizeExport(sectionName) {
        const privilegeValue = _.get(this.rolePrivileges, sectionName, 0);
        return ((privilegeValue & 0x3) > 0)
    }
}

export default new BackendSession();