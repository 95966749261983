import * as configs from './config'

export function FormUserInitState(isCreate, user) {
    if (isCreate || !user) {
        return {
            login: '',
            password: '',
            name: '',
            comment: '',
            role: configs.UserRoleReadOnly,
        }
    }

    return {
        login: user.Login,
        password: undefined,
        name: user.Name,
        comment: user.Comment,
        role: user.Role,
    }
}

export function FormPpeInitState(isCreate, element) {
    if (isCreate || !element) {
        return {
            ppe: undefined,
            district: undefined,
            name: undefined,
            address: undefined,
        }
    }
    return {
        ppe: element.Ppe,
        district: element.District,
        name: element.Name,
        address: element.Address,
    }
}

export function FormCalendarInitState(isCreate, element) {
    if (isCreate || !element) {
        return {
            ppe: undefined,
        }
    }
    return {
        ppe: element.Ppe,
    }
}
