// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import './CalendarScreen.css';
import React, {Fragment} from 'react';
import autoBind from 'react-autobind';
import {connect} from 'react-redux';
import {faUpload, faAngleRight, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import * as configs from '../globals/config';
import * as actions from '../store/calendar/actions';
import * as selectors from '../store/calendar/reducer';
import {GeneralContainer, mapGeneralStateToProps} from "./GeneralContainer";
import ToolBarView from '../components/ToolBarView';
import EditView from "../components/EditView";
import InfoView from '../components/InfoView'
import {formFieldValidateRequire} from "../globals/validators";
import {Alert} from 'reactstrap';

import ReactTableView from "../components/ReactTableView";
import {checkFiltersEqual, checkSortsEqual} from "../globals/functions";


class CalendarScreen extends GeneralContainer {

    constructor(props) {
        super(props);
        autoBind(this);
        this.sectionName = configs.SECTIONS_NAMES.calendar;
        this.filterTimer = 0;
    }

    getTableColumns = () => {
        // Колонку состояния исключаем из набора в соотвествии с ТЗ
        let res = [{
            Header: 'ППЭ',
            accessor: 'Ppe',
            width: 90,
            style: {textAlign: 'center'},
        }, ];
        let day = moment(this.props.periodDateFrom);
        day.locale('ru');
        const to = moment(this.props.periodDateTo);
        let dayIndex = 1;
        while (day <= to) {
            day.locale('ru');
            const accessor = `day_${dayIndex++}`;
            let countAddiction = "";
            if (this.props.tableCountsByCols) {
                countAddiction = `(${this.props.tableCountsByCols[accessor]} шт)`
            }
            const dayName = day.format(`ddd DD.MM.YYYY ${countAddiction}`);
            res.push({
                Header: dayName,
                // accessor: `${accessor}_raw`,
                accessor: `${accessor}`,
                // width: 220,
                style: {textAlign: 'center'},
                filterable: false,
                Cell: row => {
                    return (<Fragment key={row.value.key}>{row.value.data}</Fragment>);
                },
            });
            day = day.clone().add(1, 'd');
        }
        return res
    };

    nextWeekClick() {
        let dtFrom = moment(this.props.periodDateFrom);
        dtFrom.add(1, 'weeks');
        let dtTo = moment(this.props.periodDateTo);
        dtTo.add(1, 'weeks');
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get(dtFrom, dtTo));
        // setTimeout(this.props.dispatch(actions.Get(dtFrom, dtTo)), configs.FILTER_TIMEOUT * 3);
    }

    prevWeekClick() {
        let dtFrom = moment(this.props.periodDateFrom);
        dtFrom.subtract(1, 'weeks');
        let dtTo = moment(this.props.periodDateTo);
        dtTo.subtract(1, 'weeks');
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get(dtFrom, dtTo));
        // setTimeout(this.props.dispatch(actions.Get(dtFrom, dtTo)), configs.FILTER_TIMEOUT * 3);
    }

    componentDidMount() {
        super.componentDidMount();
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get(this.props.periodDateFrom, this.props.periodDateTo));
        // setTimeout(this.props.dispatch(actions.Get(this.props.periodDateFrom, this.props.periodDateTo)), configs.FILTER_TIMEOUT * 3);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        if (this.props.needReload) {
            this.props.dispatch(actions.DismissAllSysActions());
            this.props.dispatch(actions.BeginLoading());
            this.props.dispatch(actions.Get(this.props.periodDateFrom, this.props.periodDateTo));
            // setTimeout(this.props.dispatch(actions.Get(this.props.periodDateFrom, this.props.periodDateTo)), configs.FILTER_TIMEOUT * 3);
        }
    }

    render() {
        const tblStyle = {"height": "calc(100% - 140px)"};
        return (
            <div className="w-100 h-100 font-roboto">
                {this.renderSettings()}
                {this.renderDateChooser()}
                <InfoView
                    show={this.props.infoShow}
                    title={this.props.infoTitle}
                    info={this.props.infoData}
                    onExit={this.onInfoClose}
                />
                {this.renderNavigationBarView(2)}
                <ToolBarView
                    groups={this.getToolbarGroups()}
                />
                <ReactTableView
                    style={tblStyle}
                    columns={this.getTableColumns()}
                    hideFilters={() => {
                        this.hideAllFilters(true)
                    }}
                    sortable={false}
                    filterable={true}
                    fetchError={this.props.elementsFetchError}
                    data={this.props.elementsForTable}
                    pageSize={this.props.elementsTotalCount}
                    loading={this.props.elementsLoading}
                    onFetchData={this.onReactTableFetch}
                    onRowClick={()=>{}}
                    onRowDoubleClick={()=>{}}
                    curProdId={-1}
                    dismissErrorAlert={this.dismissErrorAlert}
                    onContextMenu={this.onTableRowClick}
                    contextMenuItems={[]}
                />
                {this.props.importError ?
                    <Alert color="danger" isOpen={true} toggle={this.dismissErrorAlert}>
                        Ошибка импорта продуктов: {this.props.importError.message}!
                    </Alert> : ''
                }
                <div className="mb-2"/>
                {/*<PagingView*/}
                {/*    totalCount={this.props.elementsTotalCount}*/}
                {/*    onlyTotal={true}*/}
                {/*    pageSize={this.props.elementsTotalCount}*/}
                {/*    curPageNumber={this.props.currentPageNumber}*/}
                {/*    onPageChange={this.onPageChange}*/}
                {/*    onSettingsClick={this.onSettingsClick}*/}
                {/*/>*/}
            </div>
        );
    }

    onReactTableFetch(state, instance) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(()=>{
            if (!checkFiltersEqual(this.props.curFilters, state.filtered) || !checkSortsEqual(this.props.curSorts, state.sorted)) {
                this.props.dispatch(actions.Filter(state.filtered, state.sorted));
            }
        }, configs.FILTER_TIMEOUT);
    }


    renderDateChooser() {
        if (!this.props.showDateChooser) {
            return '';
        }
        return (
            <EditView
                // class="modal-body-scrollable"
                size="lg"
                mode={configs.EDIT_VIEW_MODE.Local}
                sectionName={this.sectionName}
                titleEntity={undefined}
                initialValues={CalendarScreen.dateChooserInitialValues()}
                show={true}
                CustomTitle={"Выбор даты экзамена"}
                fields={[
                    {
                        name: 'date_of_exam',
                        placeholder: 'Дата экзамена',
                        type: configs.FORM_ELEMENT_TYPES.DATE_PICKER,
                        validate: [
                            formFieldValidateRequire,
                        ],
                        warn: [],
                    },
                    {
                        name: 'files',
                        name_hidden: 'files_hidden',
                        label: 'Список аудиторий',
                        placeholder: '',
                        type: configs.FORM_ELEMENT_TYPES.FILE_DROPZONE,
                        validate: [
                            formFieldValidateRequire,
                        ],
                        warn: [],
                    },
                ]}
                createMode={false}
                editError={undefined}
                dismissErrorAlert={this.dismissErrorAlert}
                loadingData={this.props.isImportInProgress}
                onCancel={this.onDateChooserCancel}
                onSubmit={this.onDateChooserSave}
                disableFieldsInCreateMode={false}
                enabledFieldsInCreateMode={[]}
            />
        );
    }

    static dateChooserInitialValues() {
        return {
            date_of_exam: moment().startOf('day'),
            files: [],
            files_hidden: [],
        }
    }

    onInfoClose() {
        this.props.dispatch(actions.CloseInfo());
    }

    showDateChoose() {
        this.props.dispatch(actions.ShowDateChooseForm())
    }

    onDateChooserCancel() {
        this.props.dispatch(actions.CloseDateChooseForm());
    }

    onDateChooserSave = (values) => {
        this.props.dispatch(actions.BeginImport());
        this.props.dispatch(actions.ImportExamDayAudiences(values));
    };

    dismissErrorAlert() {
        this.props.dispatch(actions.DismissErrorAlert())
    }

    getToolbarGroups() {
        return [
            {
                key: 1,
                className: 'col-3',
                elements: [
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        text: 'Пред. неделя',
                        icon: faAngleLeft,
                        disabled: this.props.elementsLoading,
                        onClick: (e) => {
                            e.target.blur();
                            this.prevWeekClick()
                        }
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        text: 'След. неделя',
                        icon: faAngleRight,
                        disabled: this.props.elementsLoading,
                        onClick: (e) => {
                            e.target.blur();
                            this.nextWeekClick()
                        }
                    },
                ]
            },
            {
                key: 2,
                className: 'col-9',
                elements: [
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        text: 'Импорт',
                        icon: faUpload,
                        disabled: false,
                        onClick: (e) => {
                            e.target.blur();
                            this.showDateChoose()
                        }
                    },
                ]
            }
        ];
    }
}

// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    let [tblMainEls, tblMainElsTotalCount] = selectors.getMainElementsForTable(state);
    return {
        ...mapGeneralStateToProps(state), ...{
            elementsFetchError: selectors.getFetchError(state),
            periodDateFrom: selectors.getDateFrom(state),
            periodDateTo: selectors.getDateTo(state),
            showDateChooser: selectors.getShowDateChooser(state),
            elementsForTable: tblMainEls,
            elementsTotalCount: tblMainElsTotalCount,
            infoShow: selectors.getInfoShow(state),
            infoTitle: selectors.getInfoTitle(state),
            infoData: selectors.getInfoData(state),
            importError: selectors.getImportError(state),
            elementsLoading: selectors.isElementsLoading(state),
            curFilters: selectors.getCurrentFilters(state),
            curSorts: selectors.getCurrentSorts(state),
            needReload: selectors.needReload(state),
            tableCountsByCols: selectors.getTableCountsByCols(state),
            isImportInProgress: selectors.isImportInProgress(state),
        }
    };
}

export default connect(mapStateToProps)(CalendarScreen);