// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import {filterCalendar, sortCalendar} from "../../globals/functions";
import _ from "lodash";
import moment from 'moment'

const initialState = Immutable({
    elementsFilters: undefined,
    elementsSorted: undefined,
    elementsLoading: false,
    tableCountsByCols: undefined,
    elements: undefined,
    elementsIds: undefined,
    fetchError: undefined,
    filters: undefined,
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek'),
    showDateChooser: false,
    importInProgress: false,
    importError: undefined,
    infoShow: false,
    infoData: undefined,
    infoTitle: undefined,
    reload: false,
});

const dismissAllErrorsState = {
    fetchError: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.CALENDAR_DISMISS_ALL_SYS_ACTIONS:
            return state.merge({
                reload: false,
            });
        case types.CALENDAR_FILTER_CHANGED:
            return state.merge({
                elementsFilters: action.filters ? action.filters : undefined,
                elementsSorted: action.sorted ? action.sorted : undefined,
                currentPageNumber: 0,
                currentElementId: undefined,
            });
        case types.CALENDAR_BEGIN_LOADING:
            return state.merge({
                elementsLoading: true,
            });
        case types.CALENDAR_OPEN_DATE_CHOOSER:
            return state.merge({
                showDateChooser: true,
            });
        case types.CALENDAR_CLOSE_DATE_CHOOSER:
            return state.merge({
                showDateChooser: false,
            });
        case types.CALENDAR_SET_PERIOD:
            return state.merge({
                startDate: action.result.dtFrom,
                endDate: action.result.dtTo,
            });
        case types.CALENDAR_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.CALENDAR_GET_DONE:
            return state.merge({
                elementsLoading: false,
                tableCountsByCols: action.result.counts,
                elements: action.result.elements,
                elementsIds: action.result.elementsIds,
                fetchError: undefined,
            });
        case types.CALENDAR_GET_ERROR:
            return state.merge({
                elementsLoading: false,
                elements: undefined,
                elementsIds: undefined,
                fetchError: action.error
            });
        case types.CALENDAR_IMPORT_BEGIN:
            return state.merge({
                importInProgress: true,
            });
        case types.CALENDAR_IMPORT_DONE:
            const overall = Number(action.result.res.OverallCount);
            const bad = Number(action.result.res.ProblemCount);
            const good = overall - bad;
            const infoData = [
                {
                    message: `Всего импортировано: ${overall}`,
                    messageDetails: '',
                    className: "alert alert-secondary",
                    key: "1000",
                },
                {
                    message: `Успешно импортировано: ${good}`,
                    messageDetails: '',
                    className: "alert alert-success",
                    key: "1001",
                },
                {
                    message: `Ошибок импорта: ${bad}`,
                    messageDetails: action.result.res.ProblemDetails,
                    className: "alert alert-danger",
                    key: "1003",
                },
            ];

            return state.merge({
                reload: true,
                importInProgress: false,
                infoTitle: "Результат импорта",
                infoShow: true,
                infoData: infoData,
                productsById: action.result.productsById,
                productsIds: action.result.productsIds,
                productsImportError: undefined,
            });
        case types.CALENDAR_IMPORT_ERROR:
            return state.merge({
                importInProgress: false,
                importError: action.error,
            });
        case types.CALENDAR_INFO_CLOSE:
            return state.merge({
                infoTitle: undefined,
                infoShow: false,
                infoData: undefined,
            });
        default:
            return state;
    }
}

// selectors

export function getMainElementsForTable(state) {
    let srcElIds = state.calendar.elementsIds;
    let srcEls = state.calendar.elements;
    if (!srcElIds) {
        return [[], 0];
    }
    let filteredElementsIds = [];
    let filteredElements = {};
    if (state.calendar.elementsFilters && state.calendar.elementsFilters.length > 0) {
        for (let i = 0; i < srcElIds.length; i++) {
            const id = srcElIds[i];
            if (filterCalendar(state.calendar.elementsFilters, srcEls[id])) {
                filteredElementsIds.push(id);
                filteredElements[id] = srcEls[id];
            }
        }
    } else {
        filteredElementsIds = srcElIds;
        filteredElements = srcEls;
    }
    let outIds, outEl;
    if (state.calendar.elementsSorted && state.calendar.elementsSorted.length > 0) {
        let filterDirections = [];
        for (let i = 0; i < state.calendar.elementsSorted.length; i++) {
            filterDirections.push(state.calendar.elementsSorted[i].desc ? 'desc' : 'asc')
        }
        [outIds, outEl] = sortCalendar(_.keyBy(filteredElements, (element) => element.ID), state.calendar.elementsSorted, filterDirections);
    } else {
        [outIds, outEl] = sortCalendar(_.keyBy(filteredElements, (element) => element.ID));
    }

    let res = [];
    for (let i = 0; i < outIds.length; i++) {
        res.push(_.get(outEl, outIds[i]));
    }
    return [res, filteredElementsIds.length];
}

export function getFetchError(state) {
    return state.calendar.fetchError;
}

export function getDateFrom(state) {
    return state.calendar.startDate;
}

export function getDateTo(state) {
    return state.calendar.endDate;
}

export function getShowDateChooser(state) {
    return state.calendar.showDateChooser;
}

export function getImportError(state) {
    return state.calendar.productsImportError;
}

export function getInfoShow(state) {
    return state.calendar.infoShow;
}

export function getInfoTitle(state) {
    return state.calendar.infoTitle;
}

export function getInfoData(state) {
    return state.calendar.infoData;
}

export function isElementsLoading(state) {
    return state.calendar.elementsLoading;
}

export function isImportInProgress(state) {
    return state.calendar.importInProgress;
}

export function getCurrentFilters(state) {
    return state.calendar.elementsFilters;
}

export function getCurrentSorts(state) {
    return state.calendar.elementsSorted;
}

export function needReload(state) {
    return state.calendar.reload;
}

export function getTableCountsByCols(state) {
    return state.calendar.tableCountsByCols;
}