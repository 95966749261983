// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import './MonitoringScreen.css';

import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as configs from '../globals/config';
import * as actions from '../store/monitoringRT/actions';
import * as selectors from '../store/monitoringRT/reducer';

import {GeneralContainer, mapGeneralStateToProps} from "./GeneralContainer";
import PagingView from '../components/PagingView';
import ToolBarView from '../components/ToolBarView';
import ReactTableView from '../components/ReactTableView'


class MonitoringScreen extends GeneralContainer {

    constructor(props) {
        super(props);
        autoBind(this);

        this.sectionName = configs.SECTIONS_NAMES.ppe;
        this.filterTimer = 0;
        this.updateTimerId = undefined;
        this.tableSettingsTitle = "настроек таблицы мониторинга доступности";
        this.allColumns = [{
            Header: 'Номер ППЭ',
            accessor: 'Ppe',
            width: 100,
            style: {textAlign: 'center'},
        }, {
            Header: 'Район',
            accessor: 'District',
            // width: 160,
            style: {textAlign: 'left'},
        }, {
            Header: 'Название',
            accessor: 'Name',
            // width: 160,
            style: {textAlign: 'left'},
        }, {
            Header: 'В сети',
            accessor: 'Online',
            width: 160,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value) {
                    return (
                        <div className="status-green">
                            <span>Online</span><br/>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Offline</span><br/>
                    </div>
                );
            }
        },{
            Header: 'Доступность вещания',
            accessor: 'CanRelay',
            width: 180,
            style: {textAlign: 'center'},
            Cell: row => {
                if (row.value) {
                    return (
                        <div className="status-green">
                            <span>Да</span><br/>
                        </div>
                    );
                }
                return (
                    <div className="status-red">
                        <span>Нет</span><br/>
                    </div>
                );
            }
        },];
        this.initLabelsAndNames();
    }

    componentWillUnmount(): void {
        if (this.updateTimerId) {
            clearTimeout(this.updateTimerId);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        // проверим, если у нас нет еще списков - подгрузим
        this.dismissErrorAlert();
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get());

        let that = this;
        if (this.updateTimerId) {
            clearTimeout(this.updateTimerId);
        }
        this.updateTimerId = setTimeout(function tick() {
            that.initLoadingByTimer();
            that.updateTimerId = setTimeout(tick, 10000);
        }, 10000);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // вызовем метод у супера, а потом уже свои доработочки
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    render() {
        const tblStyle = {"height": "calc(100% - 170px)"};
        return (
            <div className="w-100 h-100 font-roboto">
                {this.renderSettings()}
                {this.renderNavigationBarView(0)}
                <ToolBarView
                    groups={this.getToolbarGroups()}
                />
                <ReactTableView
                    style={tblStyle}
                    columns={this.getTableColumns()}
                    hideFilters={() => {
                        this.hideAllFilters(true)
                    }}
                    fetchError={this.props.elementsFetchError}
                    data={this.props.elementsForTable}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    loading={this.props.elementsLoading}
                    onFetchData={this.onReactTableFetch}
                    onRowClick={this.onTableRowClick}
                    onRowDoubleClick={this.onTableRowDoubleClick}
                    curProdId={this.props.currentElementId}
                    dismissErrorAlert={this.dismissErrorAlert}
                    onContextMenu={this.onTableRowClick}
                    contextMenuItems={[]}
                />
                <div className="mb-2"/>
                <PagingView
                    totalCount={this.props.elementsTotalCount}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    curPageNumber={this.props.currentPageNumber}
                    onPageChange={this.onPageChange}
                    onSettingsClick={this.onSettingsClick}
                />
            </div>
        );
    }

    initLoadingByTimer = () => {
        this.props.dispatch(actions.Get());
    };
    onPageChange(pageNumber) {
        this.props.dispatch(actions.ChangePage(pageNumber))
    }

    onReactTableFetch(state, instance) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(() => {
            this.props.dispatch(actions.Filter(state.filtered, state.sorted))
        }, configs.FILTER_TIMEOUT);
    }

    onTableRowClick(id) {
        this.props.dispatch(actions.Select(id));
    }

    onTableRowDoubleClick(id) {
    }

    dismissErrorAlert() {
        this.props.dispatch(actions.DismissErrorAlert())
    }

    getToolbarGroups() {
        return [
            {
                key: 1,
                className: 'col-12',
                elements: [
                ]
            },
        ];
    }
}
// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    let [elementsForTable, elementsTotalCount] = selectors.getMainElementsForTable(state, GeneralContainer.getTableRowCountLimit(configs.SECTIONS_NAMES.monitoringRT));
    return {...mapGeneralStateToProps(state), ...{
            currentElementId: selectors.getCurrentElementId(state),
            currentElement: selectors.getCurrentElement(state),
            elementsFetchError: selectors.getFetchError(state),
            elementsForTable: elementsForTable,
            elementsTotalCount: elementsTotalCount,
            elementsLoading: selectors.isElementsLoading(state),
            currentPageNumber: selectors.getCurrentPageNumber(state),
            curFilters: selectors.getCurrentFilters(state),
        }
    };
}

export default connect(mapStateToProps)(MonitoringScreen);
