// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash'
import React, {Component} from 'react';
import Switch from 'react-switch'
import autoBind from 'react-autobind';
import {reduxForm} from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {TOOLBAR_ELEMENT_TYPES} from '../globals/config'
import {Link} from "react-router-dom";
import MonthPickerComponent from './MonthPicker'


class ToolBarView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        if (this.props.groups.length === 0) {
            return <div className="row toolbar-empty" />
        }
        return (
            <div className="row toolbar-main">
                {_.map(this.props.groups, this.renderGroup)}
            </div>
        );
    }

    renderGroup(group) {
        const className = `${group.className} toolbar-main`;
        return (
            <div key={group.key} className={className}>
                <div className="btn-group w-100" role="toolbar" aria-label="Управление">
                    {_.map(group.elements, this.renderElement)}
                </div>
            </div>
        );
    }

    renderElement(element) {
        switch (element.type) {
            case TOOLBAR_ELEMENT_TYPES.LABEL:
                return ToolBarView.renderLabelElement(element);
            case TOOLBAR_ELEMENT_TYPES.BUTTON:
                return ToolBarView.renderBtnElement(element);
            case TOOLBAR_ELEMENT_TYPES.LINK_DOWNLOAD:
                return ToolBarView.renderDownloadLink(element);
            case TOOLBAR_ELEMENT_TYPES.LINK_UPLOAD:
                return this.renderUploadLink(element);
            case TOOLBAR_ELEMENT_TYPES.BUTTON_SWITCH:
                return ToolBarView.renderBtnSwitch(element);
            case TOOLBAR_ELEMENT_TYPES.BUTTON_TOGGLER:
                return ToolBarView.renderBtnToggler(element);
            case TOOLBAR_ELEMENT_TYPES.LINK_INTERNAL:
                return ToolBarView.renderInternalLink(element);
            case TOOLBAR_ELEMENT_TYPES.PICKER_MONTH:
                return this.renderPickerMonth(element);
            default:
                return;
        }
    }

    static renderBtnSwitch(element) {
        const leftActiveClass = _.get(element, 'leftActiveClass', "main-text");
        const rightActiveClass = _.get(element,'rightActiveClass', "red-text");
        const leftClass = element.checked ?
            "inactive-text toolbar-left-text font-roboto" :
            `${leftActiveClass} toolbar-left-text font-roboto`;
        const rightClass = element.checked ?
            `${rightActiveClass} toolbar-right-text font-roboto` :
            "inactive-text toolbar-right-text font-roboto";
        const className = _.get(element, 'leftAligned', false) ? "toolbar" : "toolbar-right w-100 h-100";
        return (
            <div className={`${className}`} key={element.key}>
            {/*<label key={element.key} htmlFor="material-switch" className="toolbar-label">*/}
                <span className={leftClass}>{element.labelLeft}</span>
                <Switch
                    id="material-switch"
                    className="react-switch toolbar-switch"
                    checked={element.checked}
                    onChange={element.onChange}
                    // onColor="#86d3ff"
                    // onHandleColor="#2693e6"
                    onColor={_.get(element, 'onColor', "#FF9582")}
                    onHandleColor={_.get(element, 'onHandleColor',"#F6522E")}
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={13}
                    width={35}
                />
                <span className={rightClass}>{element.labelRight}</span>
            </div>
        )
    }

    static renderBtnElement(element) {
        const className = `btn font-roboto  btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.icon) {
            return (
                <button
                    key={element.text ? element.text : element.hint}
                    title={element.hint}
                    type="button"
                    className={className}
                    onClick={element.onClick}
                    disabled={element.disabled}
                ><FontAwesomeIcon icon={element.icon} /> {element.text}</button>
            );
        }
        return (
            <button
                key={element.text ? element.text : element.hint}
                type="button"
                className={className}
                onClick={element.onClick}
                disabled={element.disabled}
            >{element.text}</button>
        );
    }

    static renderBtnToggler(element) {
        const className = `btn font-roboto  btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.icon) {
            return (
                <button
                    key={element.text}
                    type="button"
                    className={className}
                    data-toggle={element.dataToggle}
                    data-target={element.dataTarget}
                    aria-expanded={element.ariaExpanded}
                    aria-controls={element.ariaControls}
                    onClick={element.onClick}
                    disabled={element.disabled}
                ><FontAwesomeIcon icon={element.icon} /> {element.text}</button>
            );
        }
        return (
            <button
                key={element.text}
                type="button"
                className={className}
                data-toggle={element.dataToggle}
                data-target={element.dataTarget}
                aria-expanded={element.ariaExpanded}
                aria-controls={element.ariaControls}
                onClick={element.onClick}
                disabled={element.disabled}
            >{element.text}</button>
        );
    }

    static renderLabelElement(element) {
        const className = `btn font-roboto btn-link-${element.color}`;
        if (element.icon) {
            return (
                <div
                    key={element.text}
                    className={element.className}
                >
                    <label className={className}><FontAwesomeIcon icon={element.icon}/> {element.text}</label>
                </div>
            );
        }
        return (
            <div
                key={element.text}
                className={element.className}
            >
                <label className={className}>{element.text}</label>
            </div>
        );
    }

    static renderDownloadLink(element) {
        const className = `btn font-roboto btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.disabled) {
            return ToolBarView.renderDisabledElement(element)
        }
        if (element.icon) {
            return (
                <a href={element.href}
                   key={element.text ? element.text : element.hint}
                   title={element.hint}
                   download
                   className={className}
                   role="button"
                   onClick={element.onClick}
                ><FontAwesomeIcon icon={element.icon}/> {element.text}</a>
            );
        }
        return (
            <a href={element.href}
               key={element.text ? element.text : element.hint}
               download
               className={className}
               role="button"
               onClick={element.onClick}
            >{element.text}</a>
        );
    }

    static renderInternalLink(element) {
        const className = `btn font-roboto btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.disabled) {
            return ToolBarView.renderDisabledElement(element)
        }
        if (element.icon) {
            return (
                <Link
                    onClick={ (e)=> {
                        if ('onClick' in element && typeof element.onClick === 'function') {
                            element.onClick(e);
                        }
                    }}
                    key={element.text}
                    className={className}
                    to={element.url}
                ><FontAwesomeIcon icon={element.icon}/> {element.text}</Link>
            );
        }
        return (
            <Link
                onClick={ (e)=> {
                    if ('onClick' in element && typeof element.onClick === 'function') {
                        element.onClick(e);
                    }
                }}
                key={element.text}
                className={className}
                to={element.url}
            >{element.text}</Link>
        );
    }

    renderPickerMonth(element) {
        return (
            <MonthPickerComponent
                key={'MonthPicker'}
                element={element}
            />
        );
        // let pickerLang = {
        //     months: [
        //         'Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'
        //     ],
        //     from: 'С',
        //     to: 'По'
        // };
        // let mvalue = {year: 2015, month: 11};
        // let mrange = {from: {year: 2014, month: 8}, to: {year: 2015, month: 5}};
        //
        // let makeText = m => {
        //     if (m && m.year && m.month) {
        //         return (pickerLang.months[m.month-1] + '. ' + m.year);
        //     }
        //     return '?';
        // };
        //
        //
        // return (
        //     <div className="edit">
        //         <Picker
        //             ref={el => (this.pickMonthRange = el)}
        //             // ref="pickRange"
        //             years={{min: 2010, max: 2018}}
        //             range={mrange}
        //             lang={pickerLang}
        //             theme="dark"
        //             show={true}
        //             onChange={element.handleRangeChange}
        //             onDismiss={element.handleRangeDismiss}
        //         >
        //             <MonthBox value={makeText(mrange.from) + ' ~ ' + makeText(mrange.to)} onClick={this.handleClickRangeBox} />
        //         </Picker>
        //     </div>
        // );
    }

    renderUploadLink(element) {
        const {handleSubmit} = this.props;
        const className = `btn font-roboto btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.disabled) {
            return ToolBarView.renderDisabledElement(element)
        }
        // todo: id формы возможно стоит вынести вверх так же
        if (element.icon) {
            return (
                <form key={element.text} className="form-signin" id="importForm" onSubmit={handleSubmit}>
                    <label className={className}
                    ><FontAwesomeIcon icon={element.icon}/> {element.text}<input
                        type="file" hidden
                        accept={element.filter}
                        className="custom-file-input"
                        id="xlsImportFile"
                        name="xlsImportFile"
                        onChange={element.onChange}
                    />
                    </label>
                </form>
            );
        }
        return (
            <form key={element.text} className="form-signin" id="importForm" onSubmit={handleSubmit}>
                <label className={className}
                >{element.text}<input
                    type="file" hidden
                    accept={element.filter}
                    className="custom-file-input"
                    id="xlsImportFile"
                    name="xlsImportFile"
                    onChange={element.onChange}
                />
                </label>
            </form>
        );
    }

    static renderDisabledElement(element) {
        const className = `btn font-roboto btn-link-${element.color} hvr-underline-from-center-${element.color}`;
        if (element.icon) {
            return (
                <button
                    key={element.text}
                    type="button"
                    className={className}
                    disabled={true}
                ><FontAwesomeIcon icon={element.icon} /> {element.text}</button>
            );
        }
        return (
            <button
                key={element.text}
                type="button"
                className={className}
                disabled={true}
            >{element.text}</button>
        );
    }
}


ToolBarView = reduxForm({
    form: 'toolbarForm',        // имя формы в state (state.form.toolbarForm)
})(ToolBarView);

export default ToolBarView;
