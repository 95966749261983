import { Component } from 'react';

import './App.css';

class App extends Component {
    render() {
        return (
            this.props.children
        );
    }
}

export default App;
