// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import BackendSession from '../globals/session';
import {BackendServiceCommon} from "./serverCommon"

const SUB_PATH = 'sys/update-cams';

class BackendServicesSys extends BackendServiceCommon{

    async updateCams() {
        const url = `${this.backEndPoint}${SUB_PATH}?token=${BackendSession.token}`;
        const rawResponse = await fetch(url, {
            method: 'POST',
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка обновления списка ппэ и аудиторий, HTTP status ${rawResponse.status}`);
        }
    }
}

export default new BackendServicesSys();
