// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    logged: false,
    error: undefined
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
            return state.merge({
                logged: true,
                error: undefined
            });
        case types.LOGIN_CLEAR:
            return state.merge({
                logged: false,
                error: undefined
            });
        case types.LOGIN_FAILED:
            return state.merge({
                logged: false,
                error: "Неверное имя пользователя или пароль"
            });
        case types.LOGOUT_SUCCESS:
            return state.merge({
                logged: false,
                error: undefined
            });
        case types.LOGOUT_FAILED:
            return state.merge({
                logged: true,
                error: action.error
            });
        default:
            return state;
    }
}

// selectors

export function isLoggedIn(state) {
    return state.auth.logged;
}

export function loginError(state) {
    return state.auth.error;
}
