// strings should be unique across reducers so namespace them with the reducer name
export const PPE_PAGE_CHANGED = 'ppe.PAGE_CHANGED';
export const PPE_SELECTED = 'ppe.SELECTED';
export const PPE_MODE_CREATE = 'ppe.MODE_CREATE';
export const PPE_MODE_EDIT = 'ppe.MODE_EDIT';

export const PPE_BEGIN_LOADING = 'ppe.BEGIN_LOADING';
export const PPE_GET_DONE = 'ppe.GET_DONE';
export const PPE_GET_ERROR = 'ppe.GET_ERROR';
export const PPE_CREATE_DONE = 'ppe.CREATE_DONE';
export const PPE_CREATE_ERROR = 'ppe.CREATE_ERROR';
export const PPE_EDIT_DONE = 'ppe.EDIT_DONE';
export const PPE_EDIT_ERROR = 'ppe.EDIT_ERROR';
export const PPE_DELETE_DONE = 'ppe.DELETE_DONE';
export const PPE_DELETE_ERROR = 'ppe.DELETE_ERROR';

export const PPE_BEGIN_UPDATE_FROM_HUB = 'ppe.BEGIN_BEGIN_UPDATE_FROM_HUB';
export const PPE_UPDATE_FROM_HUB_DONE = 'ppe.UPDATE_FROM_HUB_DONE';
export const PPE_UPDATE_FROM_HUB_ERROR = 'ppe.UPDATE_FROM_HUB_ERROR';

export const PPE_FILTER_CHANGED = 'ppe.FILTER_CHANGED';
export const PPE_DISMISS_ALL_ERRORS = 'ppe.DISMISS_ALL_ERRORS';
