export const TABLES_PAGE_SIZE = 20;
export const SELECT_ELEMENT_LIMIT = 200;

// 1-ЗАО "Калуга Астрал"
export const DEFAULT_PRINCIPAL_ID = 1;


let DEBUG;
try {
    DEBUG = require('../.debug');
} catch (e) {
    DEBUG = false
}
let DEBUG_BACK_ENDPOINT;
let BACKEND_ROOT;
if (DEBUG) {
    DEBUG_BACK_ENDPOINT = 'http://localhost:3013/api/';
    BACKEND_ROOT = 'http://localhost:3013/';
} else {
    DEBUG_BACK_ENDPOINT = undefined;
    BACKEND_ROOT = ''
}
export {DEBUG_BACK_ENDPOINT};
export {BACKEND_ROOT};

export const FORM_ELEMENT_TYPES = {
    INPUT: 'input',
    REACT_SELECT: 'react-select',
    REACT_MULTI_SELECT_TEXT: 'react-multi-select-text',
    TEXT_AREA: 'textarea',
    DATE_PICKER: 'date-picker',
    H_DIVIDER: 'horizontal-divider',
    H_DIVIDER_WITH_TEXT: 'horizontal-divider-with-text',
    DATE_TIME_PICKER: 'date-time-picker',
    DATE_PICKER_RANGE: 'date-picker-range',
    RADIO_GROUP: 'radio-group',
    CHECKBOX: 'checkbox',
    CHECKBOX_LINK: 'checkbox-link',
    COLOR_PICKER: 'color-picker',
    CHECKBOX_GROUP: 'checkbox-group',
    FILE_DROPZONE: 'file-dropzone',
    FILE_HIDDEN: 'file-hidden',
};

export const FORM_INPUT_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
    NUMBER: 'number',
    CHECKBOX: 'checkbox',
};

export const TOOLBAR_ELEMENT_TYPES = {
    LABEL: 'label',
    BUTTON: 'btn',
    PICKER_MONTH: 'picker-month',
    BUTTON_TOGGLER: 'btn-toggler',
    LINK_DOWNLOAD: 'link-download',
    LINK_UPLOAD: 'link-upload',
    LINK_INTERNAL: 'link-internal',
    BUTTON_SWITCH: 'btn-switch',
};

export const DATE_FILTER_ELEMENT_TYPES = {
    BUTTON: 'btn-picker',
    INPUT: 'input',
    DATE: 'date-picker',
    DATE_RANGE: 'date-range-picker',
};

export const NUMERIC_FILTER_ELEMENT_TYPES = {
    BUTTON: 'btn-picker',
    INPUT: 'input',
    INPUT_RANGE: 'date-range-picker',
};

export const DATE_FORMATS = {
    DATE: "DD.MM.YYYY",
    DATE_TIME: "DD.MM.YYYY HH:mm:ss",
    DATE_RUS: "DD.MM.YYYY",
    HOLIDAYS: "YYYY.MM.DD",
};

export const SECTIONS_NAMES = {
    situationCenterMap: "sc-map",
    monitoringRT: "rt-avs-monitoring",
    streams: "streams",
    calendar: "calendar",
    ppe: "ppe",
    admin: 'admin',
};

export const VIEW_MODES = {
    STREAMS_ALL: 1,
    STREAMS_CAL: 2,
};

export const TOOLBAR_ACTIONS = {
    COPY_TO_CLIPBOARD: 100500,
    READ: 0,
    CREATE: 1,
    EDIT: 2,
    DELETE: 3,
    EXPORT: 4,
    IMPORT: 5,
};

export const SYS_ROLES_LAST_ID = 13;

export const EDIT_VIEW_MODE = {
    Local: 1,
    Remote: 2,
    NoCheck: 3,
};

export const FILTER_TIMEOUT = 333;
export const FILTER_PLACEHOLDER = 'Выбрать';
export const FILTER_DATE_FIELD_SUFFIX = '--ForFilter';

export const CONTEXT_MENU_EL_TYPES = {
    DIVIDER: 'context-menu-element-divider',
    BUTTON: 'context-menu-element-button',
    COPY_BUTTON: 'context-menu-element-copy-button',
};

//http://xmlcalendar.ru/
export const HOLIDAYS_CALENDAR = {
    holidays: [
        "2016.01.01",
        "2016.01.02",
        "2016.01.03",
        "2016.01.04",
        "2016.01.05",
        "2016.01.06",
        "2016.01.07",
        "2016.01.08",
        "2016.02.22",
        "2016.02.23",
        "2016.03.07",
        "2016.03.08",
        "2016.05.01",
        "2016.05.02",
        "2016.05.03",
        "2016.05.09",
        "2016.06.12",
        "2016.06.13",
        "2016.11.04",

        "2017.01.01",
        "2017.01.02",
        "2017.01.03",
        "2017.01.04",
        "2017.01.05",
        "2017.01.06",
        "2017.01.07",
        "2017.01.08",
        "2017.02.23",
        "2017.02.24",
        "2017.03.08",
        "2017.05.01",
        "2017.05.08",
        "2017.05.09",
        "2017.06.12",
        "2017.11.04",
        "2017.11.06",

        "2018.01.01",
        "2018.01.02",
        "2018.01.03",
        "2018.01.04",
        "2018.01.05",
        "2018.01.06",
        "2018.01.07",
        "2018.01.08",
        "2018.02.23",
        "2018.03.08",
        "2018.03.09",
        "2018.04.30",
        "2018.05.01",
        "2018.05.02",
        "2018.05.09",
        "2018.06.11",
        "2018.06.12",
        "2018.11.04",
        "2018.11.05",
        "2018.12.31",

        "2019.01.01",
        "2019.01.02",
        "2019.01.03",
        "2019.01.04",
        "2019.01.05",
        "2019.01.06",
        "2019.01.07",
        "2019.01.08",
        "2019.02.23",
        "2019.03.08",
        "2019.05.01",
        "2019.05.02",
        "2019.05.03",
        "2019.05.09",
        "2019.05.10",
        "2019.06.12",
        "2019.11.04",
    ],
    potentially_workdays: [
        "2016.02.20",
        "2016.11.03",

        "2017.02.22",
        "2017.03.07",
        "2017.11.03",

        "2018.02.22",
        "2018.03.07",
        "2018.04.28",
        "2018.05.08",
        "2018.06.09",
        "2018.12.29",

        "2019.02.22",
        "2019.03.07",
        "2019.04.30",
        "2019.05.08",
        "2019.06.11",
        "2019.12.31",
    ]
};

export const HDD_MIN_LIMIT = 300 * 1000 * 1000 * 1000;

export const FILTER_TYPES = {
    Str: 100500,
    Bool: 100501,
};

export const UserRoleUnauthorized = "unauthorized";
export const UserRoleReadOnly = "read_only";
export const UserRoleAdmin = "admin";
export const UserRoleRT = "rt";
export const UserRoleMinOfEducation = "min_of_education";

export const ROLE_NAMES = {
    [UserRoleUnauthorized]: "нет доступа",
    [UserRoleReadOnly]: "Холоп",
    [UserRoleAdmin]: "Администратор",
    [UserRoleRT]: "РосТелеком",
    [UserRoleMinOfEducation]: "Мин.образования",
};

export const ALL_ROLES = {
    [UserRoleUnauthorized]: {value: UserRoleUnauthorized, label: ROLE_NAMES[UserRoleUnauthorized]},
    [UserRoleReadOnly]: {value: UserRoleReadOnly, label: ROLE_NAMES[UserRoleReadOnly]},
    [UserRoleAdmin]: {value: UserRoleAdmin, label: ROLE_NAMES[UserRoleAdmin]},
    [UserRoleRT]: {value: UserRoleRT, label: ROLE_NAMES[UserRoleRT]},
    [UserRoleMinOfEducation]: {value: UserRoleMinOfEducation, label: ROLE_NAMES[UserRoleMinOfEducation]},
};

export const USER_ROLES = {
    [UserRoleReadOnly]: {value: UserRoleReadOnly, label: ROLE_NAMES[UserRoleReadOnly]},
    [UserRoleAdmin]: {value: UserRoleAdmin, label: ROLE_NAMES[UserRoleAdmin]},
    [UserRoleRT]: {value: UserRoleRT, label: ROLE_NAMES[UserRoleRT]},
    [UserRoleMinOfEducation]: {value: UserRoleMinOfEducation, label: ROLE_NAMES[UserRoleMinOfEducation]},
};
