// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import _ from 'lodash';
import React from 'react'
import BackendSession from '../globals/session';
import {BackendServiceCommon} from "./serverCommon"
import moment from "moment";
import * as configs from "../globals/config";

const SUB_PATH = 'calendar';
const LIST_NAME = 'календаря';

class BackendServicesCalendar extends BackendServiceCommon{

    static getBodyFromElement(element) {
        element.input_files = [];
        if (element.files_hidden) {
            for (let i = 0; i < element.files_hidden.length; i++) {
                element.input_files.push(Number(element.files_hidden[i].Id))
            }
        }

        const keys = {
            date_of_exam: 'date_of_exam',
        };
        const numberFields = [];
        let res = {};
        for (let k in keys) {
            // добавим поля в данные - кроме файловых полей
            if (!keys.hasOwnProperty(k)) { continue }
            if (element[keys[k]] === undefined || element[keys[k]] === null) { continue }
            if (numberFields.includes(k)) {
                res[k] = Number(element[keys[k]])
            } else {
                res[k] = element[keys[k]]
            }
        }
        return res;
    }

    async importExamDayAudiences(element) {
        const dtParam = moment(element.date_of_exam, "DD.MM.YYYY").format('YYYY-MM-DD');
        const url = `${this.backEndPoint}import/${SUB_PATH}/${dtParam}?token=${BackendSession.token}`;
        const data = new FormData();
        data.append('body', JSON.stringify(BackendServicesCalendar.getBodyFromElement(element)));
        // докинем файлы до кучи
        if (element.files && element.files.length > 0) {
            data.append('files[]', element.files[0]);
        }
        const rawResponse = await fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            if (rawResponse.status === 500) {
                const err = await rawResponse.json();
                throw new Error(err.Message);
            } else {
                throw new Error(`Ошибка импорта, HTTP status ${rawResponse.status}`);
            }
        }
        return await rawResponse.json();
    }

    static renderCell(data) {
       return data.map((item, key) => {
           return <li key={key} className="list-group-item list-group-item-tiny">{item.code}: {item.name}</li>;
       })
    }

    async getList(dtFrom, dtTo) {
        const tsFrom = Math.round(dtFrom.toDate().getTime() / 1000);
        const tsTo = Math.round(dtTo.toDate().getTime() / 1000) - 1;
        let filtersParams = [{field_name: "date",field_value: `${tsFrom} - ${tsTo}`}];

        const filtersRequestParam = JSON.stringify(filtersParams);
        const url = `${this.backEndPoint}${SUB_PATH}?token=${BackendSession.token}&filters=${filtersRequestParam}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
            mode: 'cors'
        });
        if (!response.ok) {
            throw new Error(`Ошибка получения списка ${LIST_NAME}, HTTP status ${response.status}`);
        }

        const data = await response.json();
        // Потребитель функции подразумевает что данные на неделю - и ключи для данных по дням должны быть следующие:
        // day_1, .... day_7 - поэтому тут такая своеобразная обработка
        const days = {
            'day_1': moment(dtFrom),
            'day_2': moment(dtFrom).add(1, 'days'),
            'day_3': moment(dtFrom).add(2, 'days'),
            'day_4': moment(dtFrom).add(3, 'days'),
            'day_5': moment(dtFrom).add(4, 'days'),
            'day_6': moment(dtFrom).add(5, 'days'),
            'day_7': moment(dtFrom).add(6, 'days'),
        };

        let res = [];
        let counts = {
            'day_1': 0,
            'day_2': 0,
            'day_3': 0,
            'day_4': 0,
            'day_5': 0,
            'day_6': 0,
            'day_7': 0,

        };
        for (let ppeNumber in data) {
            // check if the property/key is defined in the object itself, not in parent
            if (!data.hasOwnProperty(ppeNumber)) {
                continue;
            }
            let ppeObject = {
                ID: ppeNumber,
                Ppe: ppeNumber,
                [`Ppe${configs.FILTER_DATE_FIELD_SUFFIX}`]: Number(ppeNumber),
                'day_1': [],
                'day_1_raw': [],
                'day_2': [],
                'day_2_raw': [],
                'day_3': [],
                'day_3_raw': [],
                'day_4': [],
                'day_4_raw': [],
                'day_5': [],
                'day_5_raw': [],
                'day_6': [],
                'day_6_raw': [],
                'day_7': [],
                'day_7_raw': [],
            };
            for (let i = 0; i < data[ppeNumber].length; i++) {
                let date = moment(data[ppeNumber][i].date_str);
                for (let day in days) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (!days.hasOwnProperty(day)) {
                        continue;
                    }
                    if (date.year() === days[day].year() && date.month() === days[day].month() && date.date() === days[day].date()) {
                        const audCode = data[ppeNumber][i].camera_aud_code;
                        if (audCode.substring(audCode.length - 2) !== '_2' || audCode.substring(0, 4) === '7777') {
                            ppeObject[`${day}_raw`].push({code: audCode, name: data[ppeNumber][i].camera_aud_name})
                            ppeObject[`${day}_raw`] = _.orderBy(ppeObject[`${day}_raw`], ['code'], ['asc'])
                        }
                    }
                }
            }
            for (let day in days) {
                if (!days.hasOwnProperty(day)) {
                    continue;
                }
                const key = `${ppeObject.Ppe}_${day}`;
                ppeObject[day] = {
                    data: <ul key={`${key}_ul`} className="list-group">{BackendServicesCalendar.renderCell(ppeObject[`${day}_raw`])}</ul>,
                    key:`${key}`};
            }

            res.push(ppeObject);
            for (let day in counts) {
                // check if the property/key is defined in the object itself, not in parent
                if (counts.hasOwnProperty(day)) {
                    if (ppeObject[`${day}_raw`].length > 0) {
                        counts[day]++;
                    }
                }
            }
        }
        return [res, counts]
    }
}

export default new BackendServicesCalendar();
