import React, { Component } from 'react'
import autoBind from "react-autobind";
import './MonthPicker.css';
import * as PropTypes from "prop-types";
import MonthPicker from 'react-month-picker'
import moment from "moment";

class MonthBox extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
            value: this.props.value || 'N/A',
        };

        this._handleClick = this._handleClick.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
        })
    }

    render() {

        return (
            <div className="container" onClick={this._handleClick}>
                <label>{this.state.value}</label>
            </div>
        )
    }

    _handleClick(e) {
        this.props.onClick && this.props.onClick(e)
    }
}

MonthBox.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};


export default class MonthPickerComponent extends Component {
    constructor(props, context) {
        super(props, context);
        autoBind(this);

        this.state = {
            mrange: {from: {year: 2014, month: 8}, to: {year: moment().year, month: moment().month}},
            // show: false,
        };
        this.show = false;
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
        })
    }

    render() {

        const pickerLang = {
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',
                'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            from: 'From', to: 'To',
        };
        const mrange = this.state.mrange;

        const makeText = m => {
            if (m && m.year && m.month) {
                return (pickerLang.months[m.month - 1] + ' ' + m.year);
            }
            return '?';
        };

        return (
            <MonthPicker
                // ref="pickRange"
                ref={(el)=>{this.mPicker = el}}
                years={{min: 2013}}
                range={mrange}
                lang={pickerLang}
                theme="light"
                show={this.show}
                onChange={this.handleRangeChange}
                onDismiss={this.handleRangeDissmis}
            >
                <MonthBox value={makeText(mrange.from) + ' ~ ' + makeText(mrange.to)} onClick={this._handleClickRangeBox} />
            </MonthPicker>
        )
    }

    _handleClickRangeBox(e) {
        console.log('----');
        // console.log(this.refs.pickRange);
        console.log(e);
        console.log('----');
        this.show = true;
        // this.refs.pickRange.show();
        this.mPicker.show();
        this.forceUpdate();
    }
    handleRangeChange(value, text, listIndex) {
        //
    }
    handleRangeDissmis(value) {
        this.setState( {mrange: value} )
    }
}


MonthPickerComponent.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};
