// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import _ from 'lodash'
import React, {Component, Fragment} from 'react';
import autoBind from 'react-autobind';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class InfoView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <Modal isOpen={this.props.show}>
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>
                    {_.map(this.props.info, InfoView.renderInfoBlock)}
                </ModalBody>
                <ModalFooter>
                    <button className="btn save-btn" onClick={this.props.onExit}>OK</button>
                </ModalFooter>
            </Modal>

        );
    }

    static renderInfoBlock(info) {
        if (info.messageDetails) {
            const detailClassName = _.get(info, 'detailsClassName','list-group-item-light');
            return (
                <div className={info.className} role="alert" key={info.key}>
                    {info.message.split('<br />').map((item, key) => {
                        return <Fragment key={key}>{item}<br/></Fragment>
                    })}
                    <ul className="list-group">
                        {info.messageDetails.map((detail)=>{
                            return InfoView.renderMsgDetail(detail, detailClassName)
                        })}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className={info.className} role="alert" key={info.key}>
                    {info.message}
                </div>
            );
        }
    }

    static renderMsgDetail(detail, classNameAdd) {
        const className = `list-group-item ${classNameAdd}`;
        if (typeof detail === 'object') {
            // todo: пока считаем, что тут только ссылки могут быть, потом расширим при необходимости
            return (
                <li className={className} key={detail}>
                    <a className="text-info" href={detail.href} target="_blank" rel="noopener noreferrer">{detail.label}</a>
                </li>

            );
        }
        return (
            <li className={className} key={detail}>
                {detail.split('<br />').map((item, key) => {
                    return <Fragment key={key}>{item}<br/></Fragment>
                })}
            </li>
        );
    }
}