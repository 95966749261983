// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import _ from 'lodash';
import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import * as config from '../../globals/config'
import {filterMonitoringRt, sortMonitoringRt} from '../../globals/functions'

const initialState = Immutable({

    elements: undefined,
    elementsIds: undefined,
    elementFetchError: undefined,
    currentPageNumber: 0,
    currentElementId: undefined,
    elementsLoading: true,
    elementsFilters: undefined,
    elementsSorted: undefined,
});

const dismissAllErrorsState = {
    elementFetchError: false,
    recOffElsFetchError: false,
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.MONITORING_RT_DISMISS_ALL_ERRORS:
            return state.merge(dismissAllErrorsState);
        case types.MONITORING_RT_BEGIN_LOADING:
            return state.merge({
                elementsLoading: true,
                viewMode: config.VIEW_MODES.STREAMS_ALL,
            });
        case types.MONITORING_RT_GET_DONE:
            return state.merge({
                elementsLoading: false,
                elements: action.result.elements,
                elementsIds: action.result.elementsIds,
                elementFetchError: undefined,
                viewMode: config.VIEW_MODES.STREAMS_ALL,
            });
        case types.MONITORING_RT_GET_ERROR:
            return state.merge({
                elementsLoading: false,
                elements: {},
                elementsIds: [],
                elementFetchError: action.error
            });

        case types.MONITORING_RT_SELECTED:
            return state.merge({
                currentElementId: action.currentElementId
            });
        case types.MONITORING_RT_PAGE_CHANGED:
            return state.merge({
                currentPageNumber: action.pageNumber,
                currentElementId: undefined,
            });
        case types.MONITORING_RT_FILTER_CHANGED:
            return state.merge({
                elementsFilters: action.filters ? action.filters : undefined,
                elementsSorted: action.sorted ? action.sorted : undefined,
                currentPageNumber: 0,
                currentElementId: undefined,
            });
        default:
            return state;
    }
}

// selectors

export function getMainElementsForTable(state, limit) {
    let srcElIds = state.monitoringRT.elementsIds;
    let srcEls = state.monitoringRT.elements;
    if (!srcElIds) {
        return [[], 0];
    }
    const from = state.monitoringRT.currentPageNumber * limit;
    const to = from + limit;
    let filteredElementsIds = [];
    let filteredElements = {};
    if (state.monitoringRT.elementsFilters && state.monitoringRT.elementsFilters.length > 0) {
        for (let i = 0; i < srcElIds.length; i++) {
            const id = srcElIds[i];
            if (filterMonitoringRt(state.monitoringRT.elementsFilters, srcEls[id])) {
                filteredElementsIds.push(id);
                filteredElements[id] = srcEls[id];
            }
        }
    } else {
        filteredElementsIds = srcElIds;
        filteredElements = srcEls;
    }
    let outIds, outEl;
    if (state.monitoringRT.elementsSorted && state.monitoringRT.elementsSorted.length > 0) {
        let filterDirections = [];
        for (let i = 0; i < state.monitoringRT.elementsSorted.length; i++) {
            filterDirections.push(state.monitoringRT.elementsSorted[i].desc ? 'desc' : 'asc')
        }
        [outIds, outEl] = sortMonitoringRt(_.keyBy(filteredElements, (element) => element.ID), state.monitoringRT.elementsSorted, filterDirections);
    } else {
        [outIds, outEl] = sortMonitoringRt(_.keyBy(filteredElements, (element) => element.ID));
    }

    const elementsIds = outIds.slice(from, to);
    let res = [];
    for (let i = 0; i < elementsIds.length; i++) {
        res.push(_.get(outEl, elementsIds[i]));
    }
    return [res, filteredElementsIds.length];
}

export function isElementsLoading(state) {
    return state.monitoringRT.elementsLoading;
}

export function getCurrentElementId(state) {
    return state.monitoringRT.currentElementId;
}

export function getCurrentElement(state) {
    return _.get(state.monitoringRT.elements, state.monitoringRT.currentElementId);
}

export function getCurrentPageNumber(state) {
    return state.monitoringRT.currentPageNumber;
}

export function getFetchError(state) {
    return state.monitoringRT.elementFetchError;
}

export function getCurrentFilters(state) {
    return state.monitoringRT.elementsFilters;
}