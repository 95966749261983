// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

import React, {Component} from 'react';
import autoBind from 'react-autobind';
import logo from '../img/logo-ico-white-48x48.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faUsers, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
import BackendSession from "../globals/session";
import * as configs from '../globals/config';
import './NavigationBarView.css'


export default class NavigationBarView extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }


    renderSimpleNavElement(section, href, name, index) {
        if (!BackendSession.authorizeRead(section)) {
            return ''
        }
        return (
            <li className={this.props.activeIndex === index ? "nav-item active" : "nav-item"}>
                <Link
                    className="nav-link hvr-underline-from-center-nav"
                    to={href}
                >{name}</Link>
            </li>
        );
    }

    renderProfileNavElement() {
        const section = 'admin';
        const renderAdminPart = () => {
            if (!BackendSession.authorizeRead(section)) {
                return ''
            }
            return (
                <div>
                    <h6 className="dropdown-header">Администрирование</h6>
                    <Link className="nav-link font-roboto dropdown-item-left-padding-24 dropdown-item-normal"
                          to="/admin/users/">
                        <FontAwesomeIcon icon={faUsers}/> Пользователи
                    </Link>
                    <div className="dropdown-divider"/>
                </div>
            );
        };

        return (
            <li className={this.props.activeIndex === 100500 ? "nav-item active dropdown" : "nav-item dropdown"}>
                <a
                    className="nav-link dropdown-toggle hvr-underline-from-center-nav"
                    href="/"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-offset="10,0"
                ><FontAwesomeIcon icon={faUser}/></a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <h6 className="dropdown-header">Текущий пользователь</h6>
                    {/*<a className="dropdown-item disabled" href="">{BackendSession.userName}</a>*/}
                    <span className="dropdown-item dropdown-item-left-padding-24">{BackendSession.userName}</span>
                    <div className="dropdown-divider"/>
                    {renderAdminPart()}
                    <a className="nav-link dropdown-no-bottom-margin justify-content-center btn font-roboto dropdown-item-normal"
                       href={'/'}
                       onClick={this.onLogout}
                    ><FontAwesomeIcon icon={faSignOutAlt}/>&nbsp;Выход</a>
                </div>
            </li>
        );
    }

    render() {
        return (
            <nav
                className="navbar dropdown-no-bottom-margin navbar-above-table navbar-expand-lg navbar-dark astral-bg-primary">
                <Link className="navbar-brand" to="/">
                    <img src={logo} width="48" height="48" alt=""/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {this.renderSimpleNavElement(configs.SECTIONS_NAMES.monitoringRT, "/", "Мониторинг РТ", 0)}
                        {this.renderSimpleNavElement(configs.SECTIONS_NAMES.streams, "/monitoring", "Мониторинг Астрал", 1)}
                        {this.renderSimpleNavElement(configs.SECTIONS_NAMES.calendar, "/calendar", "Календарь", 2)}
                        {this.renderSimpleNavElement(configs.SECTIONS_NAMES.ppe, "/ppe", "Управление ППЭ", 3)}
                    </ul>

                    <ul className="navbar-nav mr-2 justify-content-end">
                        {this.renderProfileNavElement()}
                    </ul>
                </div>
            </nav>
        );
    }

    onLogout() {
        if (typeof this.props.onLogout === 'function') {
            this.props.onLogout();
        }
    }

    onMailingClick() {
        if (typeof this.props.onMailingClick === 'function') {
            this.props.onMailingClick();
        }
    }
}
