// services are state-less
// they act as utility facades that abstract the details for complex operations
// normally, our interface to any sort of server API will be as a service

import _ from 'lodash';
import BackendSession from '../globals/session';
import {BackendServiceCommon} from "./serverCommon"
import * as configs from '../globals/config';

const SUB_PATH = 'users';
const SINGLE_NAME = 'пользователя';
const LIST_NAME = 'пользователей';

class BackendServiceAdminUsers extends BackendServiceCommon{

    static getBodyFromElement(element) {
        return {
            // ID: element.ID,
            login: element.login,
            password: element.password,
            name: element.name,
            comment: element.comment,
            role: element.role,
        }
    }

    static getObjectFromResponseData(respData) {
        const roleValue = _.get(respData, 'role');
        const role = _.get(configs.ALL_ROLES, roleValue);
        return {
            ID: _.get(respData, 'id'),
            Login: _.get(respData, 'login'),
            Name: _.get(respData, 'name'),
            Comment: _.get(respData, 'comment'),
            Role: roleValue,
            RoleForTable: role.label,
        }
    }

    async getList(searchQuery = undefined) {
        // console.time('getListFromBack');
        const searchParam = searchQuery ? `&search=${searchQuery}` : '';
        const url = `${this.backEndPoint}${SUB_PATH}?token=${BackendSession.token}${searchParam}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json'
            },
            mode: 'cors'
        });
        if (!response.ok) {
            throw new Error(`Ошибка получения списка ${LIST_NAME}, HTTP status ${response.status}`);
        }

        const data = await response.json();
        return _.map(data, (generalData) => {
            // в общем случае мы должны абстрагироваться от специфики структуры даннхы, которых мы получили,
            // здесь формируем объект, с которым нам потом будет удобно работать
            return BackendServiceAdminUsers.getObjectFromResponseData(generalData);
        });
    }

    async create(element) {
        const url = `${this.backEndPoint}${SUB_PATH}?token=${BackendSession.token}`;
        const data = new FormData();
        data.append('body', JSON.stringify(BackendServiceAdminUsers.getBodyFromElement(element)));

        const rawResponse = await fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка создания ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        const content = await rawResponse.json();
        return BackendServiceAdminUsers.getObjectFromResponseData(content);
    }

    async edit(id, element) {
        const url = `${this.backEndPoint}${SUB_PATH}/${id}?token=${BackendSession.token}`;
        const data = new FormData();
        data.append('body', JSON.stringify(BackendServiceAdminUsers.getBodyFromElement(element)));
        const rawResponse = await fetch(url, {
            method: 'PUT',
            body: data,
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка изменения ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        const content = await rawResponse.json();
        //true/false в зависимости от того, что как там сервер свалидировал
        return BackendServiceAdminUsers.getObjectFromResponseData(content);
    }

    async delete(id) {
        const url = `${this.backEndPoint}${SUB_PATH}/${id}?token=${BackendSession.token}`;
        const rawResponse = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode: 'cors'
        });
        if (!rawResponse.ok) {
            throw new Error(`Ошибка удаления ${SINGLE_NAME}, HTTP status ${rawResponse.status}`);
        }
        await rawResponse.json();
    }
}

export default new BackendServiceAdminUsers();
