// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
// there are 3 types of actions:
//  async thunks - when doing asynchronous business logic like accessing a service
//  sync thunks - when you have substantial business logic but it's not async
//  plain object actions - when you just send a plain action to the reducer

import _ from 'lodash';
import * as commonTypes from '../common/actionTypes';
import * as types from './actionTypes';
import * as selectors from './reducer';
import BackendServicesPpe from '../../services/serverPpe';
import BackendServicesSys from '../../services/serverSystem';
import {sortPpe} from '../../globals/functions'

export function Select(id) {
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_SELECTED, currentElementId: id });
    };
}

export function DismissErrorAlert() {
    return (dispatch, getState) => {
        dispatch({ type: commonTypes.COMMON_DISMISS_ALL_CHECKS});
        dispatch({ type: types.PPE_DISMISS_ALL_ERRORS});
    };
}

export function EnterCreateMode() {
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_MODE_CREATE, modes: {edit: false, create: true} });
    };
}

export function EnterEditMode() {
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_MODE_EDIT, modes: {edit: true, create: false} });
    };
}

export function LeaveCreateEditMode() {
    // закрываем модалочку
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_MODE_EDIT, modes: {edit: false, create: false} });
    };
}

export function BeginLoading() {
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_BEGIN_LOADING });
    };
}

export function Get(archive=false) {
    return async(dispatch, getState) => {
        try {
            const rawData = await BackendServicesPpe.getList(archive);
            // console.time('sortListFront');
            const [elementsIds, elements] = sortPpe(_.keyBy(rawData, (element) => element.ID));
            // console.timeEnd('sortListFront');
            dispatch({ type: types.PPE_GET_DONE, result: {elements, elementsIds} });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_GET_ERROR, error});
            console.error('Get ppe error:', error);
        }
    };
}

export function Create(values) {
    return async(dispatch, getState) => {
        try {
            const res = await BackendServicesPpe.create(values);
            dispatch({ type: types.PPE_CREATE_DONE, result: res });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_CREATE_ERROR, error});
            console.error('Create ppe error: ', error);
        }
    };
}

export function Edit(values) {
    return async(dispatch, getState) => {
        try {
            const curId = selectors.getCurrentElementId(getState());
            const res = await BackendServicesPpe.edit(curId, values);
            dispatch({ type: types.PPE_EDIT_DONE, result: { [curId]: res } });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_EDIT_ERROR, error});
            console.error('Edit ppe error: ', error);
        }
    };
}

export function Delete() {
    return async(dispatch, getState) => {
        try {
            const curId = selectors.getCurrentElementId(getState());
            await BackendServicesPpe.delete(curId);
            dispatch({ type: types.PPE_DELETE_DONE, result: curId });
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_DELETE_ERROR, error});
            console.error('Delete ppe error: ', error);
        }
    };
}

export function BeginUpdateFromAvsHub() {
    return (dispatch, getState) => {
        dispatch({ type: types.PPE_BEGIN_UPDATE_FROM_HUB });
    };
}

export function UpdateFromAvsHub(onSuccessCallback) {
    return async(dispatch, getState) => {
        try {
            await BackendServicesSys.updateCams();
            dispatch({ type: types.PPE_UPDATE_FROM_HUB_DONE});
            onSuccessCallback();
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_UPDATE_FROM_HUB_ERROR, error});
            console.error('Update ppe data from avs-hub error: ', error);
        }
    };
}

export function ChangePage(pageNumber) {
  return({ type: types.PPE_PAGE_CHANGED, pageNumber: pageNumber });
}

export function Filter(filters, sorted) {
    return async(dispatch, getState) => {
        // пока делаем здесь локально... но может в будущем переложим это дело на сервер
        try {
            dispatch({type: types.PPE_FILTER_CHANGED, filters, sorted});
        } catch (error) {
            dispatch({ type: commonTypes.COMMON_NEED_TO_CHECK_AUTH});
            dispatch({ type: types.PPE_GET_ERROR, error});
            console.error('Filter gov-trade error: ', error);
        }
    };
}

export function exportUrl(filters, fields=undefined) {
    return BackendServicesPpe.exportUrl(filters, fields)
}

export function certUrl(ppeId) {
    return BackendServicesPpe.certUrl(ppeId)
}
