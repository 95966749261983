// components are "dumb" react components that are not aware of redux
// they receive data from their parents through regular react props
// they are allowed to have local component state and view logic
// use them to avoid having view logic & local component state in "smart" components

// import _ from 'lodash';
import React, {Component} from 'react';
import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import logo from '../img/logo_180х200.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'

class AuthForm extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        // const {handleSubmit, pristine, submitting } = this.props;
        const {handleSubmit} = this.props;
        return (
            <div className="login-container">
                <form className="form-signin" onSubmit={handleSubmit}>
                    <img src={logo} className="login-logo" alt={''} width={180} height={200}/>
                    <h3 className="h3 login-label text-center">AVS-Monitoring</h3>
                    {this.props.loginError ?
                        <div className="mb-3 justify-content-center d-flex text-center text-error">
                            Ошибка авторизации: неправильное имя пользователя или пароль
                        </div>
                        : ''
                    }
                    {/*<input type="text" name="login" className="form-control" placeholder="Имя пользователя" />*/}
                    {/*<input type="password" name="password" className="form-control" placeholder="Пароль" />*/}
                    <Field
                        name="login"
                        component="input"
                        type="text"
                        className="form-control"
                        placeholder="Имя пользователя"
                    />
                    <Field
                        name="password"
                        component="input"
                        type="password"
                        className="form-control"
                        placeholder="Пароль"
                    />
                    <button className="btn btn-lg btn-block login-btn"
                            type="submit"
                    ><FontAwesomeIcon icon={faSignInAlt}/> Войти</button>
                </form>
            </div>
        );
    }
}

AuthForm = reduxForm({
    form: 'auth', // имя формы в state (state.form.auth)
})(AuthForm);

export default AuthForm;

