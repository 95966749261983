// containers are "smart" react components that are aware of redux
// they are connected to the redux store and listen on part of the app state
// they use mapStateToProps to specify which parts and use selectors to read them
// avoid having view logic & local component state in them, use "dumb" components instead

import './PpeScreen.css';

import React from 'react';
import autoBind from 'react-autobind';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import {
    faCertificate,
    faEdit,
    faPlusCircle,
    faTrashAlt,
    faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import * as configs from '../globals/config';
import * as actions from '../store/ppe/actions';
import * as selectors from '../store/ppe/reducer';

import {GeneralContainer, mapGeneralStateToProps} from "./GeneralContainer";
import EditView from '../components/EditView'
import PagingView from '../components/PagingView';
import ToolBarView from '../components/ToolBarView';
import ReactTableView from '../components/ReactTableView'
import {
    calcAvailability,
} from '../globals/functions'
import {FormPpeInitState} from '../globals/initialize';
import {
    formFieldValidateRequire
} from '../globals/validators';


class PpeScreen extends GeneralContainer {

    constructor(props) {
        super(props);
        autoBind(this);

        this.sectionName = configs.SECTIONS_NAMES.ppe;
        this.filterTimer = 0;
        this.tableSettingsTitle = "настроек таблицы ППЭ";
        this.allColumns = [{
            Header: 'Номер ППЭ',
            accessor: 'Ppe',
            width: 100,
            style : {textAlign: 'center'},
        }, {
            Header: 'Район',
            accessor: 'District',
            // width: 160,
            style : {textAlign: 'left'},
        }, {
            Header: 'Название',
            accessor: 'Name',
            // width: 160,
            style : {textAlign: 'left'},
        }, {
            Header: 'Адрес',
            accessor: 'Address',
            // width: 160,
            style : {textAlign: 'left'},
        },];
        this.initLabelsAndNames();
    }

    componentDidMount() {
        super.componentDidMount();
        // проверим, если у нас нет еще списков - подгрузим
        this.dismissErrorAlert();
        this.props.dispatch(actions.BeginLoading());
        this.props.dispatch(actions.Get());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // вызовем метод у супера, а потом уже свои доработочки
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    renderEditView() {
        if (this.props.editMode) {
            return (
                <EditView
                    // class="modal-body-scrollable"
                    sectionName={this.sectionName}
                    titleEntity="ППЭ"
                    initialValues={FormPpeInitState(this.props.createMode, this.props.currentElement)}
                    show={this.props.editMode}
                    fields={this.getEditFields()}
                    createMode={this.props.createMode || !this.props.currentElement}
                    editError={this.props.elementModifyError}
                    dismissErrorAlert={this.dismissErrorAlert}
                    loadingData={false}
                    onCancel={this.onEditCancel}
                    onSubmit={this.onEditSave}
                    disableFieldsInCreateMode={false}
                    enabledFieldsInCreateMode={[]}
                    linkedFields={{}}
                />
            );
        }
    }

    render() {
        const tblStyle = {"height": "calc(100% - 170px)"};
        return (
            <div className="w-100 h-100 font-roboto">
                {this.renderEditView()}
                {this.renderSettings()}
                {this.renderNavigationBarView(3)}
                <ToolBarView
                    groups={this.getToolbarGroups()}
                />
                <ReactTableView
                    style={tblStyle}
                    columns={this.getTableColumns()}
                    hideFilters={()=>{this.hideAllFilters(true)}}
                    fetchError={this.props.elementsFetchError}
                    data={this.props.elementsForTable}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    loading={this.props.elementsLoading}
                    onFetchData={this.onReactTableFetch}
                    onRowClick={this.onTableRowClick}
                    onRowDoubleClick={this.onTableRowDoubleClick}
                    curProdId={this.props.currentElementId}
                    dismissErrorAlert={this.dismissErrorAlert}
                    onContextMenu = {this.onTableRowClick}
                    contextMenuItems={
                        [
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onAddElementClick,
                                disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.CREATE),
                                icon: faPlusCircle,
                                color: 'normal',
                                title: 'Добавить',
                            },
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onEditElementClick,
                                disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EDIT, this.props.currentElementId),
                                icon: faEdit,
                                color: 'normal',
                                title: 'Изменить',
                            },
                            {
                                type: configs.CONTEXT_MENU_EL_TYPES.BUTTON,
                                onClick: this.onDeleteElementClick,
                                disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.DELETE, this.props.currentElementId),
                                icon: faTrashAlt,
                                color: 'danger',
                                title: 'Удалить',
                            },
                        ]
                    }
                />
                {this.props.elementDeleteError ?
                    <Alert color="danger" isOpen={true} toggle={this.dismissErrorAlert}>
                        Ошибка удаления: {this.props.elementDeleteError.message}!
                    </Alert> : ''
                }
                {this.props.avsHubUpdateError ?
                    <Alert color="danger" isOpen={true} toggle={this.dismissErrorAlert}>
                        Ошибка обновления данных из avs hub: {this.props.avsHubUpdateError}!
                    </Alert> : ''
                }
                {this.renderContextInfoView()}
                <div className="mb-2"/>
                <PagingView
                    totalCount={this.props.elementsTotalCount}
                    pageSize={GeneralContainer.getTableRowCountLimit(this.sectionName)}
                    curPageNumber={this.props.currentPageNumber}
                    onPageChange={this.onPageChange}
                    onSettingsClick={this.onSettingsClick}
                />
            </div>
        );
    }

    onAddElementClick() {
        this.props.dispatch(actions.EnterCreateMode());
    }

    onEditElementClick() {
        this.props.dispatch(actions.EnterEditMode());
    }

    onPageChange(pageNumber) {
        this.props.dispatch(actions.ChangePage(pageNumber))
    }

    onEditCancel() {
        this.props.dispatch(actions.LeaveCreateEditMode());
    }

    onDeleteElementClick() {
        this.confirmAction('Вы действительно хотите удалить ППЭ?', this.onDelete)
    }

    onDelete() {
        this.props.dispatch(actions.Delete())
    }

    onUpdateFromAvsHub() {
        this.props.dispatch(actions.BeginUpdateFromAvsHub());
        this.props.dispatch(actions.UpdateFromAvsHub(()=>{
            this.showInfoView("Данные по ППЭ и аудиториям обновлены успешно")
        }));
    }

    onEditSave = (values) => {
        if (this.props.createMode) {
            this.props.dispatch(actions.Create(values));
        } else {
            this.props.dispatch(actions.Edit(values));
        }
    };

    onReactTableFetch(state, instance) {
        clearTimeout(this.filterTimer);
        this.filterTimer = setTimeout(()=>{
            this.props.dispatch(actions.Filter(state.filtered, state.sorted))
        }, configs.FILTER_TIMEOUT);
    }

    onTableRowClick(id) {
        this.props.dispatch(actions.Select(id));
    }

    onTableRowDoubleClick(id) {
        this.props.dispatch(actions.EnterEditMode());
    }

    dismissErrorAlert() {
        this.props.dispatch(actions.DismissErrorAlert())
    }

    getToolbarGroups() {
        const avsHubBtnDisabled = this.props.avsHubUpdateInProgress || !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.CREATE, this.props.currentElementId)
        return [
            {
                key: 1,
                className: 'col-12',
                elements: [
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: this.onAddElementClick,
                        text: 'Добавить',
                        icon: faPlusCircle,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.CREATE, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'normal',
                        onClick: this.onEditElementClick,
                        text: 'Изменить',
                        icon: faEdit,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EDIT, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'danger',
                        onClick: ()=>{
                            this.confirmAction('Вы действительно хотите удалить ППЭ?', this.onDelete);
                        },
                        text: 'Удалить',
                        icon: faTrashAlt,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.DELETE, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.LINK_DOWNLOAD,
                        color: 'normal',
                        href: actions.certUrl(this.props.currentElementId),
                        onClick: (e)=>{
                            e.target.blur();
                        },
                        text: 'Создать VPN-сертификат',
                        icon: faCertificate,
                        disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EDIT, this.props.currentElementId),
                    },
                    {
                        type: configs.TOOLBAR_ELEMENT_TYPES.BUTTON,
                        color: 'danger',
                        onClick: ()=>{
                            this.confirmAction('Вы действительно хотите обновить данные ППЭ и аудиторий из данных AVS-HUB?', this.onUpdateFromAvsHub);
                        },
                        text: 'Обновить данные из AVS-HUB',
                        icon: faCloudDownloadAlt,
                        disabled: avsHubBtnDisabled,
                    },
                    // {
                    //     type: configs.TOOLBAR_ELEMENT_TYPES.LINK_DOWNLOAD,
                    //     color: 'normal',
                    //     href: actions.exportUrl(this.props.curFilters, this.getFields()),
                    //     text: 'Экспорт в Excel',
                    //     icon: faDownload,
                    //     disabled: !calcAvailability(this.sectionName, configs.TOOLBAR_ACTIONS.EXPORT, this.props.currentElementId),
                    //     onClick: (e)=>{
                    //         e.target.blur();
                    //     }
                    // },
                ]
            },
        ];
    }

    getEditFields() {
        return [
            {
                name: 'ppe',
                label: '№ ППЭ',
                placeholder: '',
                type: configs.FORM_ELEMENT_TYPES.INPUT,
                inputType: configs.FORM_INPUT_TYPES.TEXT,
                validate: [formFieldValidateRequire],
                warn: [],
            },
            {
                name: 'name',
                label: 'Название ППЭ',
                placeholder: '',
                type: configs.FORM_ELEMENT_TYPES.INPUT,
                inputType: configs.FORM_INPUT_TYPES.TEXT,
                validate: [formFieldValidateRequire],
                warn: [],
            },
            {
                name: 'district',
                label: 'Район ППЭ',
                placeholder: '',
                type: configs.FORM_ELEMENT_TYPES.INPUT,
                inputType: configs.FORM_INPUT_TYPES.TEXT,
                validate: [formFieldValidateRequire],
                warn: [],
            },
            {
                name: 'address',
                label: 'Адрес ППЭ',
                placeholder: '',
                type: configs.FORM_ELEMENT_TYPES.INPUT,
                inputType: configs.FORM_INPUT_TYPES.TEXT,
                validate: [],
                warn: [],
            },
        ];
    }
}

// which props do we want to inject, given the global store state?
// always use selectors here and avoid accessing the state directly
function mapStateToProps(state) {
    let [elementsForTable, elementsTotalCount] = selectors.getElementsForTable(state, GeneralContainer.getTableRowCountLimit(configs.SECTIONS_NAMES.ppe));
    return {...mapGeneralStateToProps(state), ...{
            editMode: selectors.isEditMode(state),
            createMode: selectors.isCreateMode(state),
            currentElementId: selectors.getCurrentElementId(state),
            currentElement: selectors.getCurrentElement(state),
            avsHubUpdateInProgress: selectors.getAvsHubUpdateInProgress(state),
            avsHubUpdateError: selectors.getAvsHubUpdateError(state),
            avsHubUpdateDone: selectors.getAvsHubUpdateDone(state),
            elementsFetchError: selectors.getFetchError(state),
            elementModifyError: selectors.getEditError(state),
            elementsForTable: elementsForTable,
            elementsTotalCount: elementsTotalCount,
            elementsLoading: selectors.isElementsLoading(state),
            elementDeleteError: selectors.getDeleteError(state),
            currentPageNumber: selectors.getCurrentPageNumber(state),
            curFilters: selectors.getCurrentFilters(state),
        }
    };
}

export default connect(mapStateToProps)(PpeScreen);
